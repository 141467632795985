<template>
  <section class="sub-page iframe">
    <top-section :navs="navs" :title="nav_title" />

    <!-- <div
      v-if="!iframe.loaded"
      class="m-5 p-5 text-center"
    >
      <b-spinner
        class="m-5 p-5"
        variant="success"
        size="12rem"
      />
    </div>

    <iframe
      v-show="iframe.loaded" 
      id="housing__data__dashboard"
      ref="housing__data__dashboard"
      :src="iframe.src"
      scrolling="no"
      title="Housing Data"
      rameborder="0"
      allowFullScreen="true"
      style="width:100%; min-height:1450px; border:0; overflow: hidden;"
      @load="load"
    /> -->

    <div class="mb-5 mx-5">
      <div class="text-center">
        <p class="font-semibold pb-4 text-3xl">
          HMIP | Housing Statistics Dashboard
        </p>
      </div>
      <div
        class="mt-4 justify-between gap-4 md:flex-row flex mx-20 flex-col mx-auto"
      >
        <div class="md:w-1/3 w-full">
          <div
            style="background-color: #e5e5e5"
            class="bg-[#e5e5e5] p-4 mb-4 rounded-lg"
          >
            <p class="pb-2 text-xl">what state do you want to see</p>
            <select
              id="states"
              v-model="form.state"
              name="states"
              class="w-full rounded-sm p-2 md:w-[50%]"
              @change="filter"
            >
              <option disabled value="">select a State</option>
              <option v-for="(state, i) in states" :key="i" :value="state">
                {{ state.stateName }}
              </option>
            </select>
          </div>
          <div
            style="background-color: #e5e5e5"
            class="bg-[#e5e5e5] p-4 mb-4 rounded-lg"
          >
            <p class="pb-2 text-xl">Select Year</p>
            <select
              id="year"
              v-model="form.year"
              name="year"
              class="w-4/6 rounded-lg p-2"
            >
              <option value="2001">2001</option>
              <option value="2002">2002</option>
              <option value="2003">2003</option>
              <option value="2004">2004</option>
            </select>
          </div>
          <div
            style="background-color: #e5e5e5"
            class="bg-[#e5e5e5] p-4 mb-4 rounded-lg"
          >
            <p class="pb-2 text-xl">Select Quarter</p>
            <select
              id="quarter"
              v-model="form.quarter"
              name="quarter"
              class="w-4/6 rounded-lg p-2"
            >
              <option value="q1">q1</option>
              <option value="q2">q2</option>
              <option value="q3">q3</option>
              <option value="q4">q4</option>
            </select>
          </div>
          <div
            style="background-color: #e5e5e5"
            class="bg-[#e5e5e5] p-4 mb-4 rounded-lg"
          >
            <p class="pb-2 text-lg">How many bedrooms are you seeking</p>
            <select
              id="noOfBeds"
              v-model="form.noOfBeds"
              name="noOfBeds"
              class="w-4/6 rounded-lg p-2"
              aria-placeholder="Number of Bedrooms"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
          <div
            style="background-color: #e5e5e5"
            class="bg-[#e5e5e5] p-4 mb-4 rounded-lg"
          >
            <p class="pb-2 text-xl">Which area or town do you want to see?</p>
            <select
              id="LGA"
              v-model="form.LGA"
              name="LGA"
              class="w-4/6 rounded-lg p-2"
            >
              <option v-for="(LGA, i) in LGA" :key="i" :value="LGA.LGA">
                {{ LGA.LGA }}
              </option>
            </select>

            <div class="mt-10">
              <button
                class="bg-[#1cfa78] py-2 text-[#262637] px-4 rounded-sm"
                @click="filter()"
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div class="md:w-2/3 w-full">
          <div
            style="background-color: #276c43"
            class="flex flex-col gap-y-10 text-white p-4 bg-[#276c43] rounded-lg"
          >
            <div v-if="LGA.length == 0" class="w-[90%] md:w-[80%] mx-auto">
              <p
                class="pb-4 text-lg transition-all duration-300 text-center py-20"
              >
                We are still loading properties for
                {{ form?.LGA?.state }} State <br />
                Please check again later.
              </p>
            </div>
            <div v-if="LGA.length > 0" class="flex flex-col gap-y-10">
              <div :key="i" v-for="(LGA, i) in LGA">
                <div>
                  <p class="text-white">
                    What is the Price of the most expensive house in
                    {{ LGA.LGA }} in {{ form?.state?.stateName }} state?
                  </p>
                  <p class="font-bold text-white text-2xl">
                    {{ LGA.price }}
                  </p>
                </div>

                <div>
                  <p class="text-white">
                    What is the average prices of the houses in {{ LGA.LGA }} in
                    {{ form?.state?.stateName }} state?
                  </p>
                  <p class="font-bold text-white text-2xl">
                    {{ LGA.averagePrice }}
                  </p>
                </div>

                <div>
                  <p class="text-white">
                    What is the Price of the most cheapest house in the selected
                    area?
                  </p>
                  <p class="font-bold text-white text-2xl">
                    {{ LGA.cheapestHouse }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";
import TopSection from "../../components/General/TopSection.vue";
import states from "../states.json";
import LGA from "../LGA.json";

export default {
  name: "HousingDashboard",
  components: { TopSection },

  data: () => ({
    nav_title: "Datasets",
    navs: [
      {
        route_name: "home",
        route_title: "Home",
      },
      {
        route_name: "dashboard",
        route_title: "Dashboards",
      },
      {
        route_name: "dashboard.housing",
        route_title: "Housing Statistics Dashboard",
        active: true,
      },
    ],
    states: states,
    originalLGA: LGA,
    LGA: [],
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNmNiNmI1MzMtYjI5ZS00ZmUwLThlNTUtYjdiMTU3YmRjNDhmIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
    form: new Form({
      state: "",
      LGA: "",
      noOfBeds: "",
      quarter: "",
      year: "",
    }),
  }),
  watch: {
    "form.state": function () {
      this.filter();
    },
  },

  methods: {
    load() {
      this.iframe.loaded = true;
    },

    filter() {
      let selectedState = this.form.state.stateName?.toLocaleLowerCase();
      let selectedYear = this.form.year;
      let selectedQuarter = this.form.quarter;
      let selectedLGA = this.form.LGA;
      let selectedNoOfBeds = this.form.noOfBeds;

      if (selectedState) {
        this.LGA = this.originalLGA.filter((x) => {
          return x.state?.toLocaleLowerCase() === selectedState;
        });
      } else {
        this.LGA = [];
      }

      if (selectedYear) {
        this.LGA = this.LGA.filter((x) => x.year == selectedYear);
      }
      if (selectedLGA) {
        this.LGA = this.LGA.filter((x) => x.LGA == selectedLGA);
      }
      if (selectedQuarter) {
        this.LGA = this.LGA.filter((x) => x.quarter == selectedQuarter);
      }
      if (selectedNoOfBeds) {
        this.LGA = this.LGA.filter((x) => x.noOfBeds == selectedNoOfBeds);
      }
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>

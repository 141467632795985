<template>
  <div>
    <div
      v-if="loading"
      class="text-center m-5 p-5 h-100"
    >
      <b-spinner
        class="m-5 p-5"
        variant="primary"
        label="Loading..."
        type="grow"
        size="6rem"
      />
    </div>
    <div
      v-if="error"
      class="text-center m-5 p-5"
    >
      <p class="m-5 p-5">
        OOps! Something went wrong
      </p>
      <b-button
        variant="primary"
        @click="refreshPage"
      >
        Refresh
      </b-button>
    </div>
    <b-form
      v-if="!loading"
      ref="submit_property_form"
    >
      <b-row>
        <b-col>
          <b-card class="mb-3">
            <b-form-group
              id="input-submit_property-title"
              label="Title:"
              label-for="submit_property-title"
              invalid-feedback="Property Title is required"
              class="mb-3"
            >
              <b-form-input
                id="submit_property-title"
                v-model="$v.submit_property.title.$model"
                :state="validateState($v.submit_property.title)"
                type="text"
                placeholder="Example: 3 Bedroom Detached Duplex"
                required
              />
            </b-form-group>

            <b-form-group
              id="input-submit_property-description"
              label="Description:"
              label-for="submit_property-description"
              invalid-feedback="Property Description is required"
              class="mb-3"
            >
              <b-form-textarea
                id="textarea-rows"
                v-model="$v.submit_property.description.$model"
                :state="validateState($v.submit_property.description)"
                placeholder="Input a detailed location of the property"
                rows="6"
              />
            </b-form-group>
          </b-card>

          <b-row class="mb-3">
            <b-col>
              <b-card class="h-100">
                <b-form-group
                  id="input-submit_property-country"
                  label="Country:"
                  label-for="submit_property-country"
                  class="mb-3"
                >
                  <multiselect
                    v-model="$v.submit_property.country.$model"
                    :options="countries"
                    :clear-on-select="false"
                    placeholder="Select Country"
                    label="name"
                    track-by="name"
                  />
                  <b-form-invalid-feedback :state="validateState($v.submit_property.country)">
                    Country is required
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-submit_property-location"
                  label="Location:"
                  label-for="submit_property-location"
                  class="mb-3"
                >
                  <multiselect
                    v-model="$v.submit_property.location.$model"
                    :options="states"
                    :clear-on-select="false"
                    placeholder="Select Type"
                    label="StateName"
                    track-by="StateName"
                  />
                  <b-form-invalid-feedback :state="validateState($v.submit_property.location)">
                    Location is required
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-card>
            </b-col>

            <b-col>
              <b-card>
                <p>Property Image</p>
                <b-form-group>
                  <b-form-file
                    id="image_1"
                    v-model="$v.submit_property.image_1.$model"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="image_2"
                    v-model="$v.submit_property.image_2.$model"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="image_3"
                    v-model="submit_property.image_3"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="image_4"
                    v-model="submit_property.image_4"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="image_5"
                    v-model="submit_property.image_5"
                    size="sm"
                  />
                </b-form-group>  
                <b-form-invalid-feedback :state="validateState($v.submit_property.image_1)">
                  Property requires at least four images
                </b-form-invalid-feedback>            
              </b-card>
            </b-col>

            <b-col>
              <b-card>
                <p>Property Image</p>

                <b-form-group>
                  <b-form-file
                    id="file-small"
                    v-model="submit_property.image_6"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="file-small"
                    v-model="submit_property.image_7"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="file-small"
                    v-model="submit_property.image_8"
                    size="sm"
                  />
                </b-form-group>       

                <b-form-group>
                  <b-form-file
                    id="file-small"
                    v-model="submit_property.image_9"
                    size="sm"
                  />
                </b-form-group>

                <b-form-group>
                  <b-form-file
                    id="file-small"
                    v-model="submit_property.image_10"
                    size="sm"
                  />
                </b-form-group>  
                <b-form-invalid-feedback :state="validateState($v.submit_property.image_1)">
                  Property requires at least four images
                </b-form-invalid-feedback>         
              </b-card>
            </b-col>
          </b-row>

          <b-card class="mb-3">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="d-flex justify-content-between"
            >
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="flavour-2"
              >
                <b-form-checkbox
                  v-for="(option, o) in options"
                  :key="o"
                  v-model="$v.submit_property.general_info.$model"
                  class="col-4 mx-0 my-1"
                  name="group_info"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <b-form-invalid-feedback :state="validateState($v.submit_property.general_info)">
              Select at least 5 information about the property
            </b-form-invalid-feedback>
            </b-form-group>
          </b-card>

          <b-card class="mb-3">
            <b-form-group
              id="input-register-terms"
              class="mb-3"
            >
              <b-form-checkbox
                id="terms"
                v-model="$v.submit_property.terms.$model"
              
                name="terms"
                value="1"
                unchecked-value="0"
              >
                I accept the 
                <a
                  href="http://"
                  target="_blank"
                  rel="noopener noreferrer"
                >Terms &amp; Conditions</a>
              </b-form-checkbox>
              <b-form-invalid-feedback :state="validateState($v.submit_property.terms)">
                Accept Terms &amp; Condition to proceed
              </b-form-invalid-feedback>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>

      <div class="m-2">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-gradient-purple btn-glow"
            @click.prevent="handleSubmitProperty"
          >
            Submit Property
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
  import axios from 'axios';
  import Form from 'vform';
  import { validationMixin } from "vuelidate"
  import { required, minLength } from "vuelidate/lib/validators"
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'SurveySubmitProperty',
    components: { Multiselect  },
    mixins: [validationMixin],

    data: function() {
      return {
        loading: false,
        error: null,
        submit_property: new Form({
            title: '',
            description: '',
            country: '',
            location: '',
            image_1: '',
            image_2: '',
            image_3: '',
            image_4: '',
            image_5: '',
            image_6: '',
            image_7: '',
            image_8: '',
            image_9: '',
            image_10: '',
            general_info: [],
            terms: '',
        }),
        countries: [
          {
            id: 1,
            name: 'Nigeria',
          },
        ],
        states: [],
       
        options: [
          { text: 'Air Conditioning', value: 'air conditioning' },
          { text: 'Cleaning after exit', value: 'cleaning after exit' },
          { text: 'Dishwasher', value: 'dishwasher' },
          { text: 'Grill', value: 'grill' },
          { text: 'Internet', value: 'internet' },
          { text: 'Microwave', value: 'microwave' },
          { text: 'Radio', value: 'radio' },
          { text: 'Toaster', value: 'toaster' },
          { text: 'Balcony', value: 'balcony' },
          { text: 'Cofee pot', value: 'cofee pot' },
          { text: 'DVD', value: 'dvd' },
          { text: 'Hairdryer', value: 'hairdryer' },
          { text: 'Iron', value: 'iron' },
          { text: 'Oven', value: 'oven' },
          { text: 'Roof terrace', value: 'roof terrace' },
          { text: 'Towelwes', value: 'towelwes' },
          { text: 'Bedding', value: 'bedding' },
          { text: 'Computer', value: 'computer' },
          { text: 'Fan', value: 'fan' },
          { text: 'Heating', value: 'heating' },
          { text: 'Juicer', value: 'juicer' },
          { text: 'Parking', value: 'parking' },
          { text: 'Smoking allowed', value: 'smoking allowed' },
          { text: 'Use of pool', value: 'use of poll' },
          { text: 'Cable TV', value: 'cable tv' },
          { text: 'Cot', value: 'cot' },
          { text: 'Fridge', value: 'fridge' },
          { text: 'Hi-Fi', value: 'hi-fi' },
          { text: 'Lift', value: 'lift' },
          { text: 'Parquet', value: 'parquet' },
          { text: 'Terrace', value: 'terrace' },
          { text: 'Video', value: 'video' },
        ],
      }
    },

    watch: {
      // call again the method if the route changes
      '$route': 'fetchStatesData'
    },

    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchStatesData()
    },

    validations: {
      submit_property: {
        title: {
          required
        },
        description: {
          required
        },
        country: {
          required
        },
        location: {
          required
        },
        image_1: {
          required
        },
        image_2: {
          required
        },
        image_3: {
          required
        },
        image_4: {
          required
        },
        general_info: {
          required,
          minLength: minLength(5)
        },
        terms: {
          required,
        },
      },
    },

    methods: {
      validateState(submit_property) {
        const { $dirty, $error } = submit_property
        return $dirty ? !$error : null
      },

      refreshPage() {
        window.location.reload();
      },
      
      handleRefresh() {
        this.fetchStatesData();
      },

      async fetchStatesData() {
        // this.loading = true;
        await axios.get('NmmipStates/fetch')
          .then(
            response => (this.states = response.data.details)
          )
          .catch(
            error => (
              this.error = true
            )
          );
        // this.loading = false;
      },

      handleSubmitProperty() {
        this.$v.submit_property.$touch()
        if (this.$v.submit_property.$anyError) return false;
        
        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
        this.$refs.submit_property_form.reset();
      },
    },
};
</script>

<template>
  <section class="sub-page">
    <top-section
      :navs="navs"
      :title="nav_title"
    />
    <OwnAHome />
  </section>
</template>

<script>
import TopSection from '../../components/General/TopSection.vue'
import OwnAHome from '../../components/Survey/OwnAHome.vue'

export default {
  name: 'SurveyOwnAHome',
  components: { TopSection, OwnAHome },

  data: () => ({
    nav_title: 'survey',
    navs: [
        {
            route_name: 'home',
            route_title: 'Home',
        },
        {
            route_name: 'survey.own.home',
            route_title: 'Own a Home Survey',
            active: true,
        },
    ],
  }),
}
</script>

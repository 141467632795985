<template>
  <b-form
    ref="location-form"
    @submit.prevent="handleUpdateData"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="input-location-latitude"
          label="Latitude:"
          label-for="location-latitude"
          invalid-feedback="Latitude is required"
          class="mb-3"
        >
          <b-form-input
            id="location-latitude"
            v-model="$v.location.Latitude.$model"
            :state="validateState($v.location.Latitude)"
            type="number"
            placeholder="Latitude"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-location-longitude"
          label="Longitude:"
          label-for="location-longitude"
          invalid-feedback="Longitude is required"
          class="mb-3"
        >
          <b-form-input
            id="location-longitude"
            v-model="$v.location.Longitude.$model"
            :state="validateState($v.location.Longitude)"
            type="number"
            placeholder="Longitude"
            required
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          id="input-location-name"
          label="Location:"
          label-for="location-name"
          invalid-feedback="Name of Location is required"
          class="mb-3"
        >
          <b-form-input
            id="location-name"
            v-model="$v.location.LocationName.$model"
            :state="validateState($v.location.LocationName)"
            type="text"
            placeholder="Name"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-location-state"
          label="State:"
          label-for="location-state"
          class="mb-3"
        >
          <multiselect
            v-model="$v.location.state.$model"
            :options="states"
            :clear-on-select="false"
            placeholder="Select State"
            label="StateName"
            track-by="StateName"
          />
          <b-form-invalid-feedback :state="validateState($v.location.state)">
            State is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required, decimal } from "vuelidate/lib/validators"
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'AdminDataEntryLocations',
    components: { Multiselect  },
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         location: {
            Latitude: '',
            Longitude: '',
            LocationName: '',
            state: '',
        },
        states: [],
      }
    },

    validations: {
      location: {
        Latitude: {
          required,
          decimal
        },
        Longitude: {
          required,
          decimal
        },
        LocationName: {
          required
        },
        state: {
          required
        },
      },
    },

    mounted() {
      const { Latitude, Longitude, LocationName, NmmipState } = this.data
      this.location.Latitude = Latitude;
      this.location.Longitude = Longitude;
      this.location.LocationName = LocationName;
      this.location.state = NmmipState;
      this.fetchStatesData();
    },
  
    methods: {
      validateState(location) {
        const { $dirty, $error } = location
        return $dirty ? !$error : null
      },

      async fetchStatesData() {
        await axios.get('NmmipStates/fetch')
          .then(
            response => (this.states = response.data.details)
          )
          .catch(
            error => (
              this.error = true
            )
          );
      },

      handleUpdateData() {
        this.$v.location.$touch()
        if (this.$v.location.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
      },

      async updateSaveNewData() {
        this.submitting = true;

        const createData = {
          Latitude: this.location.Latitude,
          Longitude: this.location.Longitude,
          LocationName: this.location.LocationName,
          StateID: this.location.state.id
        };

        await axios.post('NmmipLocations/create', createData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async updateExistingData() {
        this.submitting = true;

        const updateData = {
          Latitude: this.location.Latitude,
          Longitude: this.location.Longitude,
          LocationName: this.location.LocationName,
          StateID: this.location.state.id,
          LocationID: this.data.id,
        };

        await axios.post('NmmipLocations/update', updateData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

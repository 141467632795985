<template>
  <div>
    <area-chart 
      id="area" 
      :data="areaChart.data" 
      :colors="areaChart.colors"
      :xkey="areaChart.xkey"
      :ykeys="areaChart.ykeys"
      :grid="areaChart.grid"
      :resize="areaChart.resize"
    />
  </div>
</template>
<script>
import { AreaChart } from 'vue-morris'

export default {
    name: 'Area',
    components: { AreaChart },
    data: function() {
        return {
            areaChart: {
                data: [
                    { year: '2015', a: 1504573253.76 },
                    { year: '2016', a: 4513719761.29 },
                    { year: '2017', a: 3009146507.52 },
                    { year: '2018', a: 9335353586.71 },
                    { year: '2019', a: 2243310410.21 },
                    { year: '2020', a: 785046980.89 },
                ],
                xkey: "year",
                ykeys: [ "a" ],
                grid: true,
                resize: true,
                colors: [ "#FF6384", "#36A2EB" ]
            }
        }
    }
}
</script>
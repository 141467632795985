<template>
  <div class="ekiti__survey">
    <survey-form
      :title="title"
      :success-message="success_message"
      :success-action="success_action"
      :type="type"
      :submitted="submitted"
      @submit-form="handleSubmitEkitiSurvey"
    >
      <template #logo>
        <div class="container px-5">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <img
                class="w-10"
                width="100"
                src="~@/assets/theme-assets/images/coat_of_arms_of_nigeria.png"
                alt=""
              >
            </div>
            <div>
              <img
                width="100"
                src="~@/assets/theme-assets/images/ekiti.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </template>
    </survey-form>
  </div>
</template>

<script>
import SurveyForm from './SurveyForm.vue';

export default {
  name: 'EkitiStateSurvey',
  components: { SurveyForm },
 
  data: function() {
    return {
      title: 'Ekiti State Survey Form',
      success_message: '<h5>Thanks for taking the time to complete the <b>Ekiti State Housing Survey form</b>.</h5> <h5>You can proceed to Morgage calculator</h5>',
      success_action: {
        title: 'go back',
        action: 'go_back',
        url: 'root',
      },
      type: 'housing_survey',
      submitted: false,
    }
  },

  methods: {
      async handleSubmitEkitiSurvey(fields) {
        this.submitted = true;
      }
  }
}

</script>
<style lang="scss">
.ekiti__survey {
  .survey__form {
      position: relative;
      .watermark__text {
        font-size: 60px;
      }
      &-content {
          z-index:1;
      }
      .survey__input {
        border: 2px solid gray;
        position: relative;
        height: 50px !important;
        &-label {
          position: absolute;
          top: -4px;
          left: 20px;
          background-color : #FFFFFF;
          padding: 2px 4px;
          text-transform: uppercase;
          z-index: 1;
        }
      }
  }
}
</style>
<template>
  <div>
    <!-- Header: 3D Graphics -->
    <!-- <Banner /> -->
    <!-- / Header: 3D Graphics -->
    <HeroSection />

    <!-- Exchange Listing Area -->
    <Market />
    <!--/ Exchange Listing Area -->

   

    <!-- Housing -->
    <Housing />
    <!--/ Housing -->

    <!-- Problems & Solutions -->
    <Calculator />
    <!--/ Problems & Solutions -->

  

    <!-- Whitepaper -->
    <Roadmap />
    <!--/ Whitepaper -->
  </div>
</template>

<script>
//import Banner from '../components/Homepage/Banner.vue'
import Calculator from '../components/Homepage/Calculator.vue'
import HeroSection from '../components/Homepage/HeroSection.vue'
import Housing from '../components/Homepage/Housing.vue'
import Market from '../components/Homepage/Market.vue'
import Roadmap from '../components/Homepage/Roadmap.vue'


export default {
  name: 'Home',
  components: {
   
    Market,
    Calculator,
    Housing,
    Roadmap,
    HeroSection
},
}
</script>

<template>
  <b-form
    ref="permission-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-permission-name"
      label="Name:"
      label-for="permission-name"
      invalid-feedback="Permission Name is required"
      class="mb-3"
    >
      <b-form-input
        id="permission-name"
        v-model="$v.permission.name.$model"
        :state="validateState($v.permission.name)"
        type="text"
        placeholder="Permission Name"
        required
        @keyup="handlePermissionName"
      />
    </b-form-group>

    <b-form-group
      id="input-permission-slug"
      label="Slug:"
      label-for="permission-slug"
      invalid-feedback="Slug is required"
      class="mb-3"
    >
      <b-form-input
        id="permission-slug"
        v-model="$v.permission.slug.$model"
        :state="validateState($v.permission.slug)"
        type="text"
        placeholder="Slug"
        readonly
        required
      />
    </b-form-group>

    <b-form-group
      id="input-permission-description"
      label="Description:"
      label-for="permission-description"
      invalid-feedback="Description is required"
      class="mb-3"
    >
      <b-form-input
        id="permission-description"
        v-model="$v.permission.description.$model"
        :state="validateState($v.permission.description)"
        type="text"
        placeholder="Description"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-permission-group"
      label="Group:"
      label-for="permission-group"
      class="mb-3"
    >
      <multiselect
        v-model="$v.permission.group.$model"
        :options="groups"
        :clear-on-select="false"
        placeholder="Select Type"
        label="name"
        track-by="name"
      />
      <b-form-invalid-feedback :state="validateState($v.permission.group)">
        Permission Group is required
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import slugify from 'slugify';
    import Form from 'vform';
    import { validationMixin } from "vuelidate"
    import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryDataGroup',
    components: { Multiselect  },
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         permission: new Form({
            name: '',
            slug: '',
            description: '',
            group: '',
        }),
        groups: [
            {
                'id': 1,
                'name': 'Users',
            },
            {
                'id': 2,
                'name': 'Admin',
            },
        ],
      }
    },

    validations: {
      permission: {
        name: {
          required
        },
        slug: {
          required
        },
        description: {
          required
        },
        group: {
          required,
        },
      },
    },

    mounted() {
      this.permission.name = this.data.name;
      this.permission.slug = this.data.slug;
      this.permission.description = this.data.description;
      this.permission.group = this.data.group;
    },
    methods: {
      validateState(permission) {
        const { $dirty, $error } = permission
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.permission.$touch()
        if (this.$v.permission.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
        this.$emit('handle-form-submit')
      },

      updateSaveNewData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
      },

      updateExistingData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Updated Successfully',
        });
      },

        handlePermissionName() {
            if (this.permission.name) this.permission.slug = slugify(this.permission.name).toLowerCase();
        },
    },
};
</script>

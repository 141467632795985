<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />

    <div
      v-if="!iframe.loaded"
      class="m-5 p-5 text-center"
    >
      <b-spinner
        class="m-5 p-5"
        variant="success"
        size="12rem"
      />
    </div>

    <iframe
      v-show="iframe.loaded" 
      id="housing__data__dashboard"
      ref="housing__data__dashboard"
      :src="iframe.src"
      scrolling="no"
      class="p-3"
      title="Housing Price Index Revamp December 2018 - Housing Price Index"
      rameborder="0"
      allowFullScreen="true"
      style="width:100%; min-height:920px; border:0; overflow: hidden;"
      @load="load"
    />
  </section>
</template>

<script>
import TopSection from '../../components/General/TopSection.vue'

export default {
    name: 'PriceIndexDashboard',
    components: { TopSection },

    data: () => ({
        nav_title: 'Datasets',
        navs: [
            {
                route_name: 'home',
                route_title: 'Home',
            },
            {
                route_name: 'dashboard',
                route_title: 'Dashboards',
            },
            {
                route_name: 'dashboard.pricing',
                route_title: 'Housing Price Index Dashboard',
                active: true,
            },
        ],
        visible: true,
        iframe: {
            src: 'https://app.powerbi.com/view?r=eyJrIjoiNWVhNTM4OTItNGMwNS00MjIwLWI5ZWUtYWQ4MzQxNmMwNTAyIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9',
            loaded: false
        }
    }),

    methods: {
      load() {
        this.iframe.loaded = true;
      },
    },
}
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>


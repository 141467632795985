<template>
  <b-form
    ref="contact_form"
  >
    <b-form-group
      id="input-contact-name"
      invalid-feedback="Full Name is required"
      class="mb-3"
    >
      <b-form-input
        id="contact-name"
        v-model="$v.contact.full_name.$model"
        :state="validateState($v.contact.full_name)"
        type="text"
        placeholder="Full Name"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-contact-email"
      invalid-feedback="Email Address is required and must be a valid email"
      class="mb-3"
    >
      <b-form-input
        id="contact-email"
        v-model="$v.contact.email.$model"
        :state="validateState($v.contact.email)"
        type="email"
        placeholder="Email Address"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-contact-phone"
      invalid-feedback="Phone Number is required and must be a valid number"
      class="mb-3"
    >
      <b-form-input
        id="contact-phone"
        v-model="$v.contact.phone.$model"
        :state="validateState($v.contact.phone)"
        type="tel"
        placeholder="Phone Number"
        required
      />
    </b-form-group>
    
    <b-form-group
      id="input-contact-message"
      invalid-feedback="Message is required and must not exceed 1000 characters"
      class="mb-3"
    >
      <b-form-textarea
        id="textarea-rows"
        v-model="$v.contact.message.$model"
        :state="validateState($v.contact.message)"
        placeholder="Your Message"
        rows="6"
      />
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <button
          type="submit"
          class="btn btn-gradient-purple btn-glow"
          :disabled="submitting"
          @click.prevent="handleContactForm"
        >
          Submit
          <b-spinner small v-if="submitting" />
        </button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, email, numeric, maxLength } from "vuelidate/lib/validators"
  import axios from 'axios';

  export default {
    name: 'ContactForm',
    mixins: [validationMixin],

    data: function() {
      return {
        submitting: false,
        contact: {
          full_name: '',
          email: '',
          phone: '',
          message: '',
        },
      }
    },

    validations: {
      contact: {
        full_name: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          numeric
        },
        message: {
          required,
          maxLength: maxLength(1000)
        }
      },
    },

    methods: {
      validateState(contact) {
        const { $dirty, $error } = contact
        return $dirty ? !$error : null
      },

      async handleContactForm() {
        this.$v.contact.$touch()
        if (this.$v.contact.$anyError) return false;

        this.submitting = true;

        await axios.post('/contactUs', this.contact)
            .then(response => {
              if(response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: response.data.message,
                })
                setTimeout(() => {location.reload();}, 2000)
              }
            })
            .catch(error => (
              this.$swal({
                  icon: 'error',
                  title: 'OOPS! Something went wrong.',
                  text: 'HINT: Refresh and try again'
              })
            ));

        this.submitting = false;
      }
    },
};
</script>

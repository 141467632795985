<template>
  <section
    id="roadmap"
    class="roadmap pb-5"
  >
    <div class="container-fluid">
      <div class="container">
        <div class="heading text-center">
          <div>
            <h6 class="sub-title">
              Path To
            </h6>
            <h2 class="title">
              Homeownership
            </h2>
          </div>
                
          <p class="content-desc">
            To enable Nigerians to take advantage of these improvements to own their dream homes, we have put together <br class="d-none d-xl-block">the following tips on the path to homeownership as a guide.
          </p>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="roadmap-container">
              <div class="swiper-container">
                <div class="swiper-wrapper timeline">
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp completed">
                        <span class="date">Evaluate Finances</span>
                      </div>
                      <div class="status completed">
                        <span>Assess your <br>income level</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp completed">
                        <span class="date">Stay Focussed</span>
                      </div>
                      <div class="status completed">
                        <span>Work harder to <br>build up funds</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp completed">
                        <span class="date">Approach Bank</span>
                      </div>
                      <div class="status completed">
                        <span>Engage a primary <br>mortgage lender</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp completed">
                        <span class="date">Search Properties</span>
                      </div>
                      <div class="status completed">
                        <span>Search for your <br>home within budget</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide active">
                    <div class="roadmap-info">
                      <div class="timestamp active">
                        <span class="date">NMRC</span>
                      </div>
                      <div class="status active">
                        <span>Refinancing your mortgage loan</span>
                        <span class="live">Up to 20 years</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp remaining">
                        <span class="date">Mortgage Application</span>
                      </div>
                      <div class="status remaining">
                        <span>Apply from NMRC <br>partner banks.</span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="roadmap-info">
                      <div class="timestamp remaining">
                        <span class="date">Own Your Home</span>
                      </div>
                      <div class="status remaining">
                        <span>Access funds to <br>purchase your home</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-control">
                <span class="prev-slide" />
                <span class="next-slide" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  name: 'Roadmap',
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    // Initialize Swiper
    this.initSwiper();
    // Re-initialize Swiper on window resize
    window.addEventListener('resize', this.initSwiper);
  },
  unmounted() {
    // Destroy Swiper instance and remove resize event listener
    if (this.swiper) {
      this.swiper.destroy();
      window.removeEventListener('resize', this.initSwiper);
    }
  },
  methods: {
    initSwiper() {
      const breakpoints = {
        // When window width is >= 768px
        768: {
          slidesPerView: 1,
        },
        // When window width is >= 992px
        992: {
          slidesPerView: 3,
        },
        // When window width is >= 1200px
        1200: {
          slidesPerView: 5,
        },
      };

      // Initialize Swiper
      this.swiper = new Swiper('.swiper-container', {
        // Optional parameters
        loop: true,
        navigation: {
          nextEl: '.next-slide',
          prevEl: '.prev-slide',
        },
        breakpoints: breakpoints,
        // If you need pagination
        pagination: {
          el: '.swiper-pagination',
        },
      });
    },
  },
};
</script>


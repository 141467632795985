<template>
  <b-form
    ref="state-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-state-name"
      label="State:"
      label-for="state-name"
      invalid-feedback="State name is required"
      class="mb-3"
    >
      <b-form-input
        id="state-name"
        v-model="$v.state.StateName.$model"
        type="text"
        placeholder="State Name"
        :state="validateState($v.state.StateName)"
        required
      />
    </b-form-group>
    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
          <b-spinner
            v-if="submitting"
            class="ml-2"
            label="Loading..."
            small
          />
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryState',
      mixins: [validationMixin],
      props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
        submitting: false,
        state: {
          StateID: '',
          StateName: ''
        },
      }
    },
    validations: {
      state: {
        StateName: {
          required
        },
      }
    },

    mounted() {
      this.state.StateID = this.data.id;
      this.state.StateName = this.data.StateName;
    },
  
    methods: {
      validateState(state) {
        const { $dirty, $error } = state
        return $dirty ? !$error : null
      },
      handleUpdateData() {
        this.$v.state.$touch()
        if (this.$v.state.$anyError) return false;

          switch (this.type) {
            case 'edit':
              this.updateExistingData();
              break;
          
            default:
              this.updateSaveNewData();
              break;
          }
      },

      async updateSaveNewData() {
        this.submitting = true;

        await axios.post('NmmipStates/create', {StateName: this.state.StateName})
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async updateExistingData() {
        this.submitting = true;

        await axios.post('NmmipStates/update', this.state)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

<template>
  <b-form
    ref="login-form"
    autocomplete="off"
  >
    <b-button
      variant="link"
      :to="{ path: '/' }"
    >
      <i class="fa fa-home" />
    </b-button>
      
    <b-row class="p-3">
      <b-col
        class="animated d-flex justify-content-center align-items-center"
        data-animation="fadeInLeftShorter"
        data-animation-delay="0.5s"
      >
        <router-link
          :to="{ path: '/' }"
        >
          <b-img-lazy
            fluid
            :src="hmip"
            alt="Logo"
          />
        </router-link>
      </b-col>

      <b-col
        class="animated"
        data-animation="fadeInRightShorter"
        data-animation-delay="0.5s"
      >
        <div class="text-center mb-4">
          <h3 class="m-0">
            Reset Password
          </h3>
          <small>Check your mail for token</small>
        </div>
     
        <b-form-group
          id="input-reset-token"
          label="OTP:"
          invalid-feedback="OTP is required"
          class="mb-3"
        >
          <b-form-input
            id="reset-token"
            v-model="$v.reset.Token.$model"
            :state="validateState($v.reset.Token)"
            type="text"
            placeholder="OTP"
            required
            autocomplete="new-password"
          />
        </b-form-group>

        <b-form-group
          id="input-reset-password"
          label="New Password:"
          invalid-feedback="Password is required and must be at least 8 characters"
          class="mb-3 position-relative"
        >
          <b-form-input
            id="reset-password"
            v-model="$v.reset.Password.$model"
            :state="validateState($v.reset.Password)"
            :type="showPassword ? 'text': 'password'"
            placeholder="Password"
            required
            autocomplete="new-password"
          />
          <div class="input-prepend" v-show="validateState($v.reset.Password)" @click="showPassword = !showPassword">
            <BIconEyeSlash v-if="showPassword"/>
            <BIconEye v-else />
          </div>
        </b-form-group>

        <b-form-group
          id="input-reset-password_confirmation"
          invalid-feedback="Password Confirmation is required and must be same as password"
          label="Confirm Password:"
          class="mb-3"
        >
          <b-form-input
            id="reset-password_confirmation"
            v-model="$v.reset.confirm_Password.$model"
            :state="validateState($v.reset.confirm_Password)"
            :type="showPassword ? 'text': 'password'"
            placeholder="Password Confirmation"
            required
          />
        </b-form-group>
        
        <div class="text-right">
          <b-button
            variant="link"
            class="text-sm p-0"
            @click="handleChangeAuthContainer('login')"
          >
            <small>Login?</small>
          </b-button>
        </div>
          
        <div class="d-flex justify-content-between align-items-center mt-4">
          <button
            type="submit"
            :disabled="submitting"
            class="btn btn-gradient-purple btn-glow"
            @click.prevent="handleUpdateData"
          >
            Submit
            <b-spinner small v-if="submitting" />
          </button>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, minLength, sameAs } from "vuelidate/lib/validators"
  import logo from '../../assets/theme-assets/images/loader-logo.png'
  import axios from 'axios';
  import { BIconEye, BIconEyeSlash } from 'bootstrap-vue'

  export default {
    name: 'AuthResetPassword',
    mixins: [validationMixin],
    emits: [ 'change_auth_view' ],
    components: { BIconEye, BIconEyeSlash },
    
    data: function() {
      return {
        submitting: false,
        hmip: logo,
        showPassword: false,
        reset: {
            Token: '',
            Password: '',
            confirm_Password: '',
        }
      }
    },

    validations: {
      reset: {
        Token: {
          required
        },
        Password: {
          required,
          minLength: minLength(8)
        },
        confirm_Password: {
            required,
            sameAsPassword: sameAs('Password')
        },
      },
    },

    methods: {
      handleChangeAuthContainer(view) {
        this.$emit('change_auth_view', view);
      },

      validateState(reset) {
        const { $dirty, $error } = reset
        return $dirty ? !$error : null
      },

      async handleUpdateData() {
        this.$v.reset.$touch()
        if (this.$v.reset.$anyError) return false;

        this.submitting = true;

        await axios.post('User/resetpassword', this.reset)
            .then(response => {
              if(response.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Successfully! Proceed to Login',
                })
              this.handleChangeAuthContainer('login');
              }
            })
            .catch(error => (
              this.$swal({
                  icon: 'error',  
                  title: 'OOPS! Something went wrong.',
                  text: error.data.message ?? 'HINT: Refresh and try again'
              })
            ));

        this.submitting = false;
      }
    },
};
</script>
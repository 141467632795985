<template>
  <b-form
    ref="subscription-form"
    @submit.prevent="handleUpdateData"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="input-subscription-first_name"
          label="First Name:"
          label-for="subscription-first_name"
          invalid-feedback="First Name is required"
          class="mb-3"
        >
          <b-form-input
            id="subscription-first_name"
            v-model="$v.subscription.first_name.$model"
            :state="validateState($v.subscription.first_name)"
            type="text"
            placeholder="Doe"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-subscription-last_name"
          label="Last Name:"
          label-for="subscription-last_name"
          invalid-feedback="Last Name is required"
          class="mb-3"
        >
          <b-form-input
            id="subscription-last_name"
            v-model="$v.subscription.last_name.$model"
            :state="validateState($v.subscription.last_name)"
            type="text"
            placeholder="Iro"
            required
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          id="input-subscription-email"
          label="Email Address:"
          label-for="subscription-email"
          invalid-feedback="Email Address is required"
          class="mb-3"
        >
          <b-form-input
            id="subscription-last_name"
            v-model="$v.subscription.email.$model"
            :state="validateState($v.subscription.email)"
            type="email"
            placeholder="alpha@hmip.com"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-subscription-phone_number"
          label="Phone Number:"
          label-for="subscription-phone_number"
          invalid-feedback="Phone Number is required and must be a valid number"
          class="mb-3"
        >
          <b-form-input
            id="subscription-last_name"
            v-model="$v.subscription.phone_number.$model"
            :state="validateState($v.subscription.phone_number)"
            type="tel"
            placeholder="+234812345678"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import Form from 'vform';
  import { validationMixin } from "vuelidate"
  import { required, email, numeric } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryLocations',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         subscription: new Form({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
        }),
      }
    },

    validations: {
      subscription: {
        first_name: {
          required
        },
        last_name: {
          required
        },
        email: {
          required,
          email
        },
        phone_number: {
          required,
          numeric
        },
      },
    },

    mounted() {
      const { first_name, last_name, email, phone_number } = this.data;
      this.subscription.first_name = first_name;
      this.subscription.last_name = last_name;
      this.subscription.email = email;
      this.subscription.phone_number = phone_number;
    },
  
    methods: {
      validateState(subscription) {
        const { $dirty, $error } = subscription
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.subscription.$touch()
        if (this.$v.subscription.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
        this.$emit('handle-form-submit')
      },

      updateSaveNewData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
      },

      updateExistingData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Updated Successfully',
        });
      }
    },
};
</script>

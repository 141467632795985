<template>
  <b-modal
    id="UpdateDataModal"
    :visible="visible"
    modal-class="social-post-tutorial-modal"
    centered
    hide-header
    hide-footer
    size="lg"
    @hide="handleOnHide"
  >
    <div class="p-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="text-capitalize font-weight-bold h4">
          {{ type }} {{ title }}
        </div>
        <div class="">
          <button
            class="btn btn-outline-danger rounded-circle"
            @click="close"
          >
            <i class="fa fa-times" />
          </button>
        </div>
      </div>
      <div class="m-2">
        <state 
          v-if="titleSlug == 'states'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <LGA 
          v-if="titleSlug == 'lga'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <locations 
          v-if="titleSlug == 'locations'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <indicators 
          v-if="titleSlug == 'indicators'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <indicators-view
          v-if="titleSlug == 'indicators_view'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <data-group 
          v-if="titleSlug == 'data_group'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <ownership-type 
          v-if="titleSlug == 'ownership_type'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <subscription 
          v-if="titleSlug == 'newsletter_subscriptions'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <permission-group 
          v-if="titleSlug == 'permissions_group'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <permissions 
          v-if="titleSlug == 'permissions'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <roles 
          v-if="titleSlug == 'roles'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <enumerators 
          v-if="titleSlug == 'enumerators'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <employment-sector 
          v-if="titleSlug == 'employment-sector'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <employment-status 
          v-if="titleSlug == 'employment-status'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <typeof-buyer 
          v-if="titleSlug == 'buyer-type'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />

        <property-type 
          v-if="titleSlug == 'property-type'"
          :data="data"
          :type="type"
          @handle-form-submit="handleUpdateData"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
  import State from '../DataEntry/State.vue';
  import LGA from '../DataEntry/LGA.vue';
  import Locations from '../DataEntry/Locations.vue';
  import Indicators from '../DataEntry/Indicators.vue';
  import IndicatorsView from '../DataEntry/IndicatorsView.vue';
  import DataGroup from '../DataEntry/DataGroup.vue';
  import OwnershipType from '../DataEntry/OwnershipType.vue';
  import Subscription from '../DataEntry/Subscriptions.vue';
  import Permissions from '../DataEntry/Permissions.vue';
  import PermissionGroup from '../DataEntry/PermissionGroup.vue';
  import Roles from '../DataEntry/Roles.vue';
  import Enumerators from '../DataEntry/Enumerators.vue';
  import EmploymentSector from '../DataEntry/EmploymentSector.vue';
  import EmploymentStatus from '../DataEntry/EmploymentStatus.vue';
  import TypeofBuyer from '../DataEntry/TypeofBuyer.vue';
  import PropertyType from '../DataEntry/PropertyType.vue';

  export default {
    name: 'AdminModalUpdateData',
    components: {
      State,
      LGA,
      Locations,
      Indicators,
      IndicatorsView,
      DataGroup,
      Subscription,
      Permissions,
      PermissionGroup,
      Roles,
      OwnershipType,
      Enumerators,
      EmploymentSector,
      EmploymentStatus,
      TypeofBuyer,
      PropertyType,
    },
    props: {
      visible: {
          type: Boolean,
          default: false,
      },
      title: {
          type: String,
          default: 'Update Data',
      },
      titleSlug: {
          type: String,
          required: true,
          default: '',
      },
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'Edit',
      },
    },
    emits: ['close-update-modal'],

    data: function() {
      return {
      }
    },

    methods: {
      close() {
          this.$bvModal.hide('UpdateDataModal');
      },

      handleOnHide() {
          // this.$emit('close-update-modal', this.type)
      },

      handleUpdateData() {
          this.close();
          this.$emit('close-update-modal', this.type)
      },

    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

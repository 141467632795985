<template>
  <section class="sub-page">
    <top-section
      :navs="navs"
      :title="nav_title"
    />
    <nredc-survey />
  </section>
</template>

<script>
import TopSection from '../../components/General/TopSection.vue'
import NredcSurvey from '../../components/Survey/NREDCSurvey.vue'
export default {
  name: 'SurveyNredc',
  components: { TopSection, NredcSurvey },

  data: () => ({
    nav_title: 'survey',
    navs: [
        {
            route_name: 'home',
            route_title: 'Home',
        },
        {
            route_name: 'survey.nredc',
            route_title: 'NREDC Survey',
            active: true,
        },
    ],
  }),
}
</script>

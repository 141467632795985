<template>
  <section class="admin-page">
    <b-breadcrumb :items="items" />
    <b-row class="mt-3">
      <dashboard-stats />
    </b-row>
  </section>
</template>

<script>
import DashboardStats from '../../components/Admin/DashboardStats.vue'
export default {
  name: 'AdminDashboard',
  components: { DashboardStats },

  data: () => ({
    items: [
      {
        text: 'Dashboard',
        active: true
      },
    ],
  }),

  methods: {

  },
}

</script>

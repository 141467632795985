<template>
  <b-form
    ref="role-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-role-name"
      label="Name:"
      label-for="role-name"
      invalid-feedback="Role Name is required"
      class="mb-3"
    >
      <b-form-input
        id="role-name"
        v-model="$v.role.name.$model"
        :state="validateState($v.role.name)"
        type="text"
        placeholder="Role Name"
        required
        @keyup="handleRoleName"
      />
    </b-form-group>

    <b-form-group
      id="input-role-slug"
      label="Slug:"
      label-for="role-slug"
      invalid-feedback="Slug is required"
      class="mb-3"
    >
      <b-form-input
        id="role-slug"
        v-model="$v.role.slug.$model"
        :state="validateState($v.role.slug)"
        type="text"
        placeholder="Slug"
        readonly
        required
      />
    </b-form-group>

    <b-form-group
      id="input-role-description"
      label="Description:"
      label-for="role-description"
      invalid-feedback="Description is required"
      class="mb-3"
    >
      <b-form-input
        id="role-description"
        v-model="$v.role.description.$model"
        :state="validateState($v.role.description)"
        type="text"
        placeholder="Description"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-role-permissions"
      label="Permissions:"
      label-for="role-permissions"
      class="mb-3"
    >
      <multiselect
        v-model="$v.role.permissions.$model"
        :options="permissions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        placeholder="Select Permissions"
        label="name"
        track-by="name"
      />
      <b-form-invalid-feedback :state="validateState($v.role.permissions)">
        Permissions is required
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="input-role-type"
      label="Type:"
      label-for="role-type"
      class="mb-3"
    >
      <multiselect
        v-model="$v.role.type.$model"
        :options="types"
        :clear-on-select="false"
        placeholder="Select Type"
        label="name"
        track-by="name"
      />
      <b-form-invalid-feedback :state="validateState($v.role.type)">
        Type is required
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import slugify from 'slugify';
    import Form from 'vform';
    import { validationMixin } from "vuelidate"
    import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryDataGroup',
    components: { Multiselect  },
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
        role: new Form({
            name: '',
            slug: '',
            description: '',
            permissions: [],
            type: '',
        }),
        permissions: [
            {
                'id': 1,
                'name': 'Users',
                'slug': 'role_users',
                'description': 'Application Administrator',
                'permissions_group': 'default',
            },
            {
                'id': 2,
                'name': 'Admin',
                'slug': 'role_users',
                'description': 'Application Administrator',
                'permissions_group': 'default',
            },
        ],
        types: [
            {
                'id': 1,
                'name': 'default',
            },
            {
                'id': 2,
                'name': 'main',
            },
        ],
      }
    },

    validations: {
      role: {
        name: {
          required
        },
        slug: {
          required
        },
        description: {
          required
        },
        permissions: {
          required,
        },
        type: {
            required
        }
      },
    },

    mounted() {
        this.role.name = this.data.name;
        this.role.slug = this.data.slug;
        this.role.description = this.data.description;
        this.role.permissions = this.data.permissions;
        this.role.type = this.data.type;
    },

    methods: {
      validateState(role) {
        const { $dirty, $error } = role
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.role.$touch()
        if (this.$v.role.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
        this.$emit('handle-form-submit')
      },

      updateSaveNewData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
      },

      updateExistingData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Updated Successfully',
        });
      },

        handleRoleName() {
            if (this.permission.name) this.permission.slug = slugify(this.permission.name).toLowerCase();
        },
    },
};
</script>

<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />
    <b-row
      cols="3"
      class="p-3 mx-auto col-md-10 my-3 text-center align-items-stretch"
    >
      <b-col
        v-for="(dataset, index) in datasets_files"
        :key="index"
        class="card-group col-4 d-flex justify-content-center"
      >
        <div
          class="card m-2 mb-4 p-4 cursor-pointer dataset__card animated text-center d-flex justify-content-center"
          data-animation="fadeInDown"
          :data-animation-delay="`1.` + index+1 + `s`"
          @click="handleRouting(dataset, 'download')"
        >
          <div class="p-3 content">
            <h6
              class="m-0 text-wrap"
              v-html="getTitle(dataset.title)"
            />
            <p
              v-if="dataset.description"
              class="pt-2"
            >
              {{ dataset.description }}
            </p>
          </div>
        </div>        
      </b-col>
    </b-row>
  </section>
</template>
  
  <script>
  import TopSection from '../../components/General/TopSection.vue'
  import axios from 'axios'
  
  export default {
      name: 'DatasetHome',
      components: { TopSection },
  
      data: () => ({
          nav_title: 'Datasets',
          navs: [
              {
                  route_name: 'home',
                  route_title: 'Home',
              },
              {
                  route_name: 'datasets',
                  route_title: 'Datasets',
                  active: true,
              },
          ],
          dataset_dir: '../../assets/datasets/',
          datasets_links: [],
          datasets_files: [
              {
                title: 'building_materials_price_update',
                slug: 'building_materials_price_update_april_2022_june_2022',
                description: '(APR 2022 - JUNE 2022)',
              },
              {
                title: 'building_materials_price_update',
                slug: 'building_materials_price_update_june_2022_october_2022',
                description: '(JUN 2022 - OCT 2022)',
              },
              {
                title: 'housing_condition_in_nigeria',
                description: '(2022 Survey)',
              },
              {
                title: 'finance_data',
                description: null,
              },
              {
                title: 'household_data',
                description: null,
              },
              {
                title: 'nre_data',
                description: null,
              },
              {
                title: 'price_index',
                description: null,
              },
              {
                title: 'public_sector_data',
                description: null,
              },

          ],
      }),

      methods: {

          handleRouting(dataset, type) {
              switch (type) {
                  case 'link':
                      this.$router.push({ name: dataset.route_name })
                      break;
                  case 'download':
                      // this.downloadItem(dataset);
                      var file = dataset.slug ?? dataset.title;
                      var file_name = file + '.zip';
                      var file_path = this.dataset_dir + file_name;
                      window.open(file_path);

                      // var link = '';
                      // link = document.createElement("a");
                      // link.href = file_path;
                      // link.download = dataset;
                      // link.download = file;
                      // link.click();
                      break;
              
                  default:
                      break;
              }
          },
  
          async downloadItem(file) {
            const response = await axios.get(file.path, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/zip" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.file_name;
            link.click();
            URL.revokeObjectURL(link.href);
          },

          getTitle(slug)  {
            const title = slug.split('_');
            for(var i = 0; i < title.length; i++) {
              var word = title[i];
              title[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return title.join(' ');
          }
      }
  
  }
</script>
<style scoped>
.iframe {
    overflow-y: hidden;
}
.dataset__card:hover {
    border-color: rgba(92, 164, 122, 0.7);
    box-shadow: 0px 0px 10px 2px rgba(92, 164, 122, 0.6) !important;
    transition: border-color 1s, box-shadow 0.5s;
    background-color: rgb(254, 254, 254);
}
.dataset__card:hover .content {
    border: 1px solid rgba(92, 164, 122, 0.7);
    border-radius: 8px;
}
</style>
  
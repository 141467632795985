/* eslint-disable vue/no-lone-template */
<template>
  <section class="admin-page">
    <b-breadcrumb :items="items" />
    <Datatable 
      :title="title"
      :title-slug="title_slug"
      :ths="ths"
      :tds="tds"
      @refresh="handleEdit"
    />
  </section>
</template>

<script>
import Datatable from '../../../components/Admin/Datatable.vue'
export default {
  name: 'AdminDataPermissions',
  components: { Datatable },

  data: () => ({
    items: [
      {
        text: 'Dashboard',
        to: { name: 'admin.dashboard' }
      },
      {
        text: 'Permissions',
        active: true
      }
    ],

    title: 'Permissions',
    title_slug: 'permissions',
    ths: ['#', 'name', 'permission group', 'description'], // table head
    tds: [ // table data
      {
        'id': 1,
        'name': 'Users',
        'slug': 'role_users',
        'description': 'Application Administrator',
        'group':
        {
          'id': 1,
          'name': 'Users',
        },
      },
      {
        'id': 2,
        'name': 'Admin',
        'slug': 'role_users',
        'description': 'Application Administrator',
        'group':
        {
          'id': 2,
          'name': 'Admin',
        },
      },
    ],
  }),

  methods: {
    handleEdit() {
      // fetch data
    }
  },
}

</script>

<template>
  <section class="sub-page">
    <top-section
      :navs="navs"
      :title="nav_title"
    />
    <ekiti-state />
  </section>
</template>

<script>
import TopSection from '../../components/General/TopSection.vue'
import EkitiState from '../../components/Survey/EkitiState.vue'
export default {
  name: 'SurveyEkiti',
  components: { EkitiState, TopSection },

  data: () => ({
    nav_title: 'survey',
    navs: [
        {
            route_name: 'home',
            route_title: 'Home',
        },
        {
            route_name: 'survey.ekiti',
            route_title: 'Ekiti State Housing Survey',
            active: true,
        },
    ],
  }),
}
</script>

/* eslint-disable vue/html-self-closing */
<template>
  <div
    class="survey__stepper"
  >
    <div
      v-for="(caption, index) in captions"
      :key="index"
      class="survey__stepper-step"
      :class="{'done' : index <= currentStep || submitted, 'active' : index == currentStep }"
    >
      <span class="survey__stepper-counter" />
      <small class="text-capitalize">{{ caption.title }}</small>
    </div>
  </div>
</template>
<script>
export default {
    name: "StepIndicator",
    props: {
        captions: {
            type: Array,
            default: () => [],
        },
        stepsCount: {
            type: Number,
            default: 0,
        },
        currentStep: {
            type: Number,
            default: 0,
        },
        submitted: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        stepsCountWithSuccessPage() {
            return this.stepsCount + 1;
        },
    }
}
</script>
<style lang="scss">
.survey__stepper {
    display: flex;
	box-shadow: 0 2px 4px 0px #aaa;
    background-color: #FEFEFE;
    counter-reset: section;
    &-step {
        display: flex;
		flex: 1;
		padding: 15px;
		justify-content: center;
		align-items: center;
		flex-direction: column;
        &:first-child {
            cursor: pointer;
			color: #FEFEFE;
            position: relative;
            &:before{
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 0;
                content: '';
                border-top: none;
                border-bottom: none;
            }
        }
        &.active, &.done {
            position: relative;
            background: linear-gradient(85deg, #373435, #5CA47A);
            // background-color: #5CA47A;
			color: #FEFEFE;
        }
        &.active {
            &:last-child {
                &:after {
                    border: none;
                }
            }
            &:after {
                position: absolute;
                right: 0;
				top: 0;
				margin-right: -50px;
				width: 0;
				height: 0;
				content: '';
				border-top: 50px solid transparent;
				border-bottom: 50px solid transparent;
				border-left: 50px solid #5CA47A;
				transition: all 900ms ease-in-out;
            }
            .survey__stepper-counter {
                &:after {
                    content: '\f044';
                    font-family: FontAwesome;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 45px;
                    height: 45px;
                    border-radius: 9999px;
                    border: 1px solid #FEFEFE;
                    color: #FEFEFE;
                }
            }
        }
        &.done {
            .survey__stepper-counter {
                &:after {
                    content: '\f00c';
					font-family: FontAwesome;
					border-color: #FEFEFE;
					color: #FEFEFE;
                }
            }
        }
    }
    &-counter {
        &::after {
            counter-increment: section;
            content: counter(section);
            display : flex;
            font-size: 18px;
            border: 1px solid #373435;
            width: 45px;
            height: 45px;
            justify-content: center;
            border-radius: 999px;
            align-items: center;
            margin-bottom: 5px;
        }
    }
}
</style>
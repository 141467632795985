<template>
  <section
    id="contact"
    class="contact section-padding"
  >
    <div class="container-fluid">
      <div class="container">
        <div class="heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">
              FHF Calculator
            </h6>
            <h2 class="title">
              FHF Mortgage Calculator
            </h2>
          </div>
          <p
            class="content-desc animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            Calculate Your Monthly Mortgage Payment
          </p>
        </div>
        <div>
          <div
            v-if="!iframe.loaded"
            class="m-5 p-5 text-center"
          >
            <b-spinner
              class="m-5 p-5"
              variant="success"
              size="12rem"
            />
          </div>
        </div>
      </div>
      <iframe
        v-show="iframe.loaded" 
        id="fhf__mortgage__calculator"
        ref="fhf__mortgage__calculator"
        :src="iframe.src"
        scrolling="no"
        title="Housing Data"
        rameborder="0"
        class="px-3"
        allowFullScreen="true"
        style="width:100%; min-height:600px; border:0; overflow: hidden;"
        @load="load"
      />
    </div>
  </section>
</template>

<script>

export default {
  name: 'FHFCalculator',

  data: () => ({
    visible: true,
    iframe: {
        src: 'https://nmrc.com.ng/mortgage/mortgage/nmrc-fhf/',
        loaded: false
    }
  }),

  methods: {
    load() {
      this.iframe.loaded = true;
    },
  },
}
</script>

<template>
  <b-form
    ref="permission_group-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-permission_group-name"
      label="Name:"
      label-for="permission_group-name"
      invalid-feedback="Permission Group Name is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.permission_group.name.$model"
        :state="validateState($v.permission_group.name)"
        type="text"
        placeholder="Name"
        required
      />
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import Form from 'vform';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryDataGroup',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         permission_group: new Form({
            name: '',
        }),
      }
    },

    validations: {
      permission_group: {
        name: {
          required,
        },
      },
    },

    mounted() {
      this.permission_group.name = this.data.name;
    },
    methods: {
      validateState(permission_group) {
        const { $dirty, $error } = permission_group
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.permission_group.$touch()
        if (this.$v.permission_group.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
        this.$emit('handle-form-submit')
      },

      updateSaveNewData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
      },

      updateExistingData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Updated Successfully',
        });
      }
    },
};
</script>

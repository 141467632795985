/* eslint-disable vue/no-lone-template */
<template>
  <section class="admin-page">
    <b-breadcrumb :items="items" />
    <div
      v-if="loading"
      class="text-center m-5 p-5"
    >
      <b-spinner
        variant="success"
        label="Loading..."
        type="grow"
        size="6rem"
      />
    </div>
    <div
      v-else-if="error"
      class="text-center m-5 p-5"
    >
      <p>
        OOPS! Something went wrong
      </p>
      <b-button
        variant="success"
        @click="refreshPage"
      >
        Refresh
      </b-button>
    </div>
    <template
      v-else
    >
      <Datatable 
        :title="title"
        :title-slug="title_slug"
        :ths="ths"
        :tds="list"
        @refresh="handleRefresh"
      />
    </template>
  </section>
</template>

<script>
import axios from 'axios';
import Datatable from '../../../components/Admin/Datatable.vue'
export default {
  name: 'AdminEmploymentSector',
  components: { Datatable },

  data: () => ({
    loading: false,
    error: null,
    items: [
      {
        text: 'Dashboard',
        to: { name: 'admin.dashboard' }
      },
      {
        text: 'Employment Sector',
        active: true
      }
    ],

    title: 'Employment Sector',
    title_slug: 'employment-sector',
    ths: ['#', 'name', 'created at'], // table head
    list: []
  }),

  watch: {
    // call again the method if the route changes
    // '$route': 'fetchStatesData'
  },

  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },

  methods: {
    refreshPage() {
      window.location.reload();
    },

    handleRefresh() {
      this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      await axios.get('EmploymentSector/fetch')
        .then(
          response => (this.list = response.data.details)
        )
        .catch(
          error => (
            this.error = true
          )
        );
      this.loading = false;
    }
  },
}

</script>

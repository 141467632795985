<template>
  <section class="faq section-padding bg-gradient" data-midnight="white">
    <div class="container-fluid">
      <!-- buyers Guide -->
      <div class="my-16">
        <div
          class="w-[90%] mx-auto py-12 flex justify-between flex-col md:flex-row items-start gap-4"
        >
          <div class="md:w-[30%] md:text-left text-center w-full text-white">
            <p class="text-4xl text-white pb-4">Buyer's Guide</p>
            <p class="text-base text-white">
              Be fully informed on the legal & financial processes of buying a
              property outright or through mortgage.
            </p>

            <div
              class="w-[60%] my-4 flex items-center md:mx-0 mx-auto rounded-lg bg-[#fff]"
            >
              <button class="w-full text-black p-4">
                <a href="#financialProcess" @click.prevent="scrollToTarget">
                  Financial Process
                </a>
              </button>
            </div>
            <a
              href="#scroll-target"
              class="text-[#fff] pt-4"
              @click.prevent="scrollToTarget"
              >View All</a
            >
          </div>
          <div
            class="md:w-[50%] w-full bg-white text-black rounded-lg flex-col pr-24 flex gap-2 px-8 py-4"
          >
            <div class="flex gap-4 items-start mt-8 flex-row">
              <div class="mt-2">
                <span class="px-2 py-1 rounded-[100%] text-white bg-[#398559]"
                  >1</span
                >
              </div>
              <div class="flex flex-col text-black gap-2">
                <p class="text-lg text-black">Be above 18years of Age</p>
                <p class="text-md text-black">
                  Make sure you are above the age of 18 and well informed of the
                  things attached attributed to owning a properrties
                </p>
              </div>
            </div>
            <div class="flex gap-4 items-start text-black mt-8 flex-row">
              <div class="mt-2">
                <span class="px-2 py-1 rounded-[100%] bg-[#398559] text-white"
                  >2</span
                >
              </div>
              <div class="flex flex-col gap-2">
                <p class="text-lg text-black">Get a Guarantor</p>
                <p class="text-md text-black">
                  Make sure you are above the age of 18 and well informed of the
                  things attached attributed to owning a properrties
                </p>
              </div>
            </div>
            <div class="flex gap-4 items-start mt-8 flex-row">
              <div class="mt-2">
                <span class="px-2 py-1 rounded-[100%] bg-[#398559] text-white"
                  >3</span
                >
              </div>
              <div class="flex flex-col gap-2">
                <p class="text-lg text-black">Have a means of ID</p>
                <p class="text-md text-black">
                  Make sure you are above the age of 18 and well informed of the
                  things attached attributed to owning a properrties
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="scroll-target" class="container">
        <div class="dark-bg-heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">own a home</h6>
            <h2 class="title">Steps to Own a Home</h2>
          </div>

          <p
            class="content-desc animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            Here is a collection of frequently asked question about owning a
            home
          </p>
        </div>

        <div class="row">
          <div class="col">
            <div id="myTabContent" class="tab-content">
              <div
                id="general"
                class="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="general-tab"
              >
                <div
                  id="general-accordion"
                  class="collapse-icon accordion-icon-rotate"
                >
                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.1s"
                  >
                    <div id="headingOne" class="card-header">
                      <h5 class="mb-0">
                        <a
                          class="btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span class="icon gradient-crypto" />
                          What kind of home ownership is right for you?
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <p class="text-dark">
                          Owning a home is probably the biggest investment
                          decision or purchase one can make in one’s lifetime so
                          it is important that one exercises absolute due
                          diligence when embarking on the home ownership
                          process. Key factors to consider before buying a home
                          include:
                        </p>
                        <ul class="mb-2">
                          <li>Knowing the type of home you wish to buy;</li>
                          <li>Location (where do I want to live?);</li>
                          <li>
                            Affordability vis a vis your income level (what can
                            I afford?);
                          </li>
                          <li>
                            Financing (how do I finance this major purchase?)
                          </li>
                        </ul>
                        <p class="text-dark">
                          You might need to procure the services of - an
                          experienced realtor - to put you through the maze of
                          offerings available in the market – one, two or three
                          bedrooms; apartments; semi-detached, terrace duplexes,
                          bungalows, duplexes or detached homes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <div id="headingTwo" class="card-header">
                      <h5 id="financialProcess" class="mb-0">
                        <a
                          class="btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span class="icon gradient-crypto" />
                          Are you financially ready? (Determine what you can
                          afford)
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <p class="text-dark">
                          Probably the most important decision is to determine
                          what you can afford. Knowing what house type you can
                          pay for or receive funding towards will also help you
                          narrow the field so you don’t waste precious time
                          touring homes that are out of your reach.
                        </p>
                        <p class="text-dark">
                          Check out the prices of the homes under your
                          consideration. Negotiate hard to arrive at a
                          comfortable sale compromise with the seller. Make sure
                          the home has all the necessary title documentation.
                          Once you have settled on a price you contact one of
                          NMRC’s primary mortgage lenders for a mortgage. For a
                          full list of these institutions and to access the
                          Uniform Underwriting Standards (UUS) which set out the
                          conditions for borrowing visit the NMRC website at
                          <a href="http://www.nmrc.com.ng">www.nmrc.com.ng</a>
                        </p>
                        <p class="text-dark">
                          Research and read up all you can about how to get a
                          mortgage so that you are well equipped to speak with
                          the lender. You can even use a mortgage calculator to
                          figure out what you can afford. The lender is to help
                          you determine just how much you can comfortably afford
                          or pay with or without a mortgage loan.
                        </p>
                        <p class="text-dark">
                          The key factor in figuring how much home you can
                          afford is your debt-to-income ratio. This is the
                          figure lenders use to determine just how much mortgage
                          debt you can handle and the maximum loan amount you
                          will be offered. The law stipulates that your monthly
                          mortgage outlay must not be more than 33 and one-third
                          percent of your monthly income.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.3s"
                  >
                    <div id="headingThree" class="card-header">
                      <h5 class="mb-0">
                        <a
                          class="btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span class="icon gradient-crypto" />
                          What’s the best option to home acquisition? (Buying
                          fully developed or buying off-plan?)
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <p class="text-dark">
                          There are pros and cons to each of the two options.
                          Buying a completed property already listed on the
                          market allows for quicker purchase decision and indeed
                          mortgage decision. It also allows for your immediate
                          occupation of the home once the mortgage negotiations
                          have been finalised. The downside is that you are
                          often forced to pay the full price completion and you
                          may not be able to upgrade with the finishes you
                          prefer.
                        </p>
                        <p class="text-dark my">
                          Buying off-plan on the other hand, or simply buying a
                          home before the construction starts presents an
                          opportunity to buy cheaper with options as regards
                          payment and even home finishes. This option as
                          attractive as it appears also comes with its drawbacks
                          as off-plan purchase schemes have been abused by
                          unqualified developers.
                        </p>
                        <p class="text-dark">
                          The key to engaging in such schemes is the review the
                          background of the developers/promoters for track
                          record and qualitative experience before subscribing
                          to an off-plan scheme 9as the saying goes “if an offer
                          is too good to be true it probably is”.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.1s"
                  >
                    <div id="headingFour" class="card-header">
                      <h5 class="mb-0">
                        <a
                          class="btn-link"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <span class="icon gradient-crypto" />
                          The steps to buying a home through mortgage
                        </a>
                      </h5>
                    </div>

                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Start your research early
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Determine your preferred location
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Determine the price you can afford for a house
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Get pre-qualified (reviewed by a mortgage broker or
                            lender)
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Find the Right Real Estate agent or Developer
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Shop for your home, negotiate and put in an offer
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Once the seller accepts your offer, get the home
                            inspected
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Make sure you have the legal documents to the home –
                            title.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Identify and NMRC mortgage bank and apply for a
                            mortgage loan
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Work with the mortgage banker to formalise your loan
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Agree interest rates, tenor, amortisation schedule,
                            etc.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            Coordinate the paperwork (execute purchase
                            agreements)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.2s"
                  >
                    <div id="headingFive" class="card-header">
                      <h5 class="mb-0">
                        <a
                          class="btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <span class="icon gradient-crypto" />
                          Now that you have a mortgage what next?
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <p class="text-dark">
                          You make the required equity down payment, normally
                          set at 20% of the cost of the property. If you cannot
                          do so there are emerging options available to enable
                          you make the down payment, such as Collateral
                          Replacement Indemnity Insurance or CRI, or you can
                          also access a percentage of your Retirement Savings
                          Account (RSA) with PENCOM (subject to meeting
                          eligibility requirements) to enable you make the down
                          payment.
                        </p>
                        <p class="text-dark">
                          Once your application for the mortgage loan is
                          approved and your equity payment is made you will have
                          to begin making repayments on the loan. This will be
                          via monthly deductions at source from your source of
                          regular income.
                        </p>
                        <p class="text-dark">
                          In the event of partial or default payment, the
                          mortgage lender can foreclosure the property. This
                          means that if you are unable to make the payments for
                          3 or 4 consecutive months the lender has the right to
                          take back the property and sell it to cover the debt
                          (this is the process known as foreclosure).
                        </p>
                        <p class="text-dark">
                          With foreclosure you lose your home and likely damage
                          your credit worthiness or affect your ability to buy a
                          new home in the future.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.3s"
                  >
                    <div id="headingSix" class="card-header">
                      <h5 class="mb-0">
                        <a
                          class="btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          <span class="icon gradient-crypto" />
                          Terms to be familiar with when buying a home
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#general-accordion"
                    >
                      <div class="card-body mx-5 my-3">
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Mortgage:</b> is a loan secured on a specified
                            property that the borrower is obliged to pay back
                            with a predetermined set of monthly payments.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Title:</b> The right to the ownership and
                            possession of a property that may be legally
                            recognized as belonging to someone.
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Down Payment:</b> The minimum cash contribution
                            that must be made by a borrower toward the purchase
                            of a home to qualify for a mortgage
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Credit Check or Rating:</b> A detailed report of
                            an individual's <b>credit</b> history prepared by a
                            <b>credit</b> bureau and used by a lender in
                            determining a loan applicant's creditworthiness
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Interest Rate:</b> The amount charged, expressed
                            as a percentage of principal, by a lender to a
                            borrower for a loan facility.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Foreclosure:</b> The legal proceedings initiated
                            by a lender in the case of mortgage default to
                            realize or claim ownership to a collateral or
                            property
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Appreciation:</b> the rise or increase in the
                            value of an asset based on a rise in market price
                            over a period.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Depreciation:</b> A decrease in property or an
                            asset's <b>value</b> caused by unfavorable market
                            conditions.
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Default:</b> The failure to promptly pay interest
                            or principal when due. This occurs when a debtor is
                            unable to meet the legal obligation of debt
                            repayment.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Deed:</b> A legal document that grants or conveys
                            ownership of property from a grantor (seller) to a
                            grantee (buyer).
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Home Equity:</b> the value of ownership built up
                            in a home or property that represents the current
                            market value of the house less any remaining
                            mortgage or liability.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Appraisal:</b> A valuation of property by the
                            estimate of an authorized person.
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Amortization:</b> is the paying off debt with a
                            fixed repayment schedule in regular installments
                            over a period, for example as with a mortgage loan
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Disclosure:</b> Disclosure is the act of
                            releasing all relevant information pertaining to a
                            company that may influence an investment decision.
                          </p>
                        </div>

                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Adjustable Rate Mortgage (ARM):</b> A type of
                            home loan in which the interest <b>rate</b> is not
                            fixed but adjust in line with market rates.
                          </p>
                        </div>
                        <div class="d-flex">
                          <i class="fa fa-check text-primary" />
                          <p class="text-dark ml-2">
                            <b>Fixed Rate Mortgage (FRM):</b> A mortgage that
                            has a fixed interest rate for the entire term of the
                            loan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PropetyOwnAHome",

  data: () => ({
    url: process.env.VUE_APP_NAME,
    faqs: [
      {
        id: 1,
        title: "What kind of home ownership is right for you?",
        slug: "kind_of_home",
        content:
          '<p class="text-dark">Owning a home is probably the biggest investment decision or purchase one can make in one’s lifetime so it is important that one exercises absolute due diligence when embarking on the home ownership process. Key factors to consider before buying a home include:</p><ul class="mb-2"><li>Knowing the type of home you wish to buy;</li><li>Location (where do I want to live?);</li><li>Affordability vis a vis your income level (what can I afford?);</li><li>Financing (how do I finance this major purchase?)</li></ul><p class="text-dark">You might need to procure the services of - an experienced realtor - to put you through the maze of offerings available in the market – one, two or three bedrooms; apartments; semi-detached, terrace duplexes, bungalows, duplexes or detached homes.</p>',
      },
      {
        id: 2,
        title: "What kind of home ownership is right for you?",
        slug: "kind_of",
        content:
          '<p class="text-dark">Owning a home is probably the biggest investment decision or purchase one can make in one’s lifetime so it is important that one exercises absolute due diligence when embarking on the home ownership process. Key factors to consider before buying a home include:</p><ul class="mb-2"><li>Knowing the type of home you wish to buy;</li><li>Location (where do I want to live?);</li><li>Affordability vis a vis your income level (what can I afford?);</li><li>Financing (how do I finance this major purchase?)</li></ul><p class="text-dark">You might need to procure the services of - an experienced realtor - to put you through the maze of offerings available in the market – one, two or three bedrooms; apartments; semi-detached, terrace duplexes, bungalows, duplexes or detached homes.</p>',
      },
    ],
  }),

  methods: {
    scrollToTarget() {
      const targetElement = document.getElementById("scroll-target");
      const targetPosition = targetElement.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<template>
  <div class="m3">
    <div class="position-relative text-center">
      <b-avatar
        variant="info"
        :text="getInitials(user)"
        :src="url"
        size="10rem"
      />
      <div class="select-avatar_icon d-none">
        <label
          class="align-items-center"
          for="select-avatar"
        >
          <i class="fa fa-camera fa-2x mr-2" />
          Choose Image    
        </label>
        <b-form-file
          id="select-avatar"
          v-model="new_image"
          class="mt-3 d-none"
          plain
          accept="image/*"
          @change="onFileChange"
          disabled
        />
      </div>

      <div
        v-show="new_image"
        class="m-2"
      >
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'AdminUpdateAvatar',

    props: {
      user: {
          type: Object,
          default: () => {},
      },
    },

    data: function() {
      return {
        url: null,
        new_image: null,
      }
    },

    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
            this.new_image = URL.createObjectURL(file);
        },

        getInitials(user) {
          let initials = user.Firstname.charAt(0) + user.Lastname.charAt(0);
          return initials.toUpperCase();
        },

        handleUpdateData() {
            // call endpoint and save data
            this.new_image = null;

            // return alert
            this.$swal({
                icon: 'success',
                title: 'Operation Successfully',
            });

        },
    },
};
</script>

<template>
  <div
    class="error404"
    data-midnight="white"
  >
    <div id="particles-js" />
    <div class="error-img text-center mx-auto">
      <img
        src="~@/assets/theme-assets/images/error.png"
        alt=""
        class="img-fluid"
      >
    </div>
    <div class="error-content text-center">
      <h4 class="error-info">
        Oops! You’ve got lost in space
      </h4>
      <a
        href="/"
        class="btn btn-glow p-3 btn-gradient-blue btn-round"
      >Back to Home</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

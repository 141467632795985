<template>
  <b-form
    ref="data-group-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-data_group-name"
      label="Name:"
      label-for="data_group-name"
      invalid-feedback="Data Group Name is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.data_group.DataGroupName.$model"
        :state="validateState($v.data_group.DataGroupName)"
        type="text"
        placeholder="Data Group Name"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-data_group-name"
      label="Name:"
      label-for="data_group-name"
      invalid-feedback="Data Group Parent is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.data_group.DataGroupParentID.$model"
        :state="validateState($v.data_group.DataGroupParentID)"
        type="text"
        placeholder="Data Group Parent ID"
        required
        readonly
      />
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryDataGroup',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         data_group: {
            DataGroupName: '',
            DataGroupParentID: '17',
        },
      }
    },

    validations: {
      data_group: {
        DataGroupName: {
          required,
        },
        DataGroupParentID: {
          required,
        },
      },
    },

    mounted() {
      this.data_group.DataGroupName = this.data.DataGroupName;
    },
    methods: {
      validateState(data_group) {
        const { $dirty, $error } = data_group
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.data_group.$touch()
        if (this.$v.data_group.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
      },

      async updateSaveNewData() {
        this.submitting = true;

        const createData = {
          DataGroupName: this.data_group.DataGroupName,
          DataGroupParentID: this.data_group.DataGroupParentID,
        };

        await axios.post('NmmipDataGroups/create', createData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async updateExistingData() {
        this.submitting = true;

        const updateData = {
          DataGroupID: this.data.id,
          DataGroupParentID: this.data_group.DataGroupParentID,
          DataGroupName: this.data_group.DataGroupName,
        };

        await axios.post('NmmipDataGroups/update', updateData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

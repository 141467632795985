<template>
  <div class="own_a_home__survey">
    <own-a-home-form
      :title="title"
      :success-message="success_message"
      :success-action="success_action"
      :type="type"
      :submitted="submitted"
      :submitting="submitting"
      @submit-form="handleSubmitOwnaHomeForm"
    >
      <template #meta>
        <div class="m-3">
          <h1>Home Ownership Form</h1>
          <p>
            To own a home, kindly take a few minutes to complete the form below
          </p>
        </div>
      </template>
    </own-a-home-form>
  </div>
</template>
<script>
import OwnAHomeForm from './OwnAHomeForm.vue';
import moment from 'moment'
import axios from 'axios'
import { mapActions } from "vuex";

export default {
  name: 'OwnAHomeSurvey',
  components: { OwnAHomeForm },
 
  data: function() {
    return {
      title: 'Own A Home Survey Form',
      success_message: '<h5>Thanks for taking the time to complete the <b>Own A Home Survey form</b>.</h5> <h5>You can proceed to Morgage calculator</h5>',
      success_action: {
        title: 'go back',
        action: 'go_back',
        url: 'root',
      },
      type: 'own_a_home',
      submitted: false,
      submitting: false,
    }
  },

  methods: {
      ...mapActions(['NotifiyNewSubmission', 'RegisterUser']),

      generateUniquDealId() {
        return Math.random().toString(36).substr(2, 8);
      },
      getUtcTime() {
        let now = new Date();
        now = new Date(now.setMinutes(now.getMinutes() + 5));
        let utcTime = now.toISOString().split('.')[0] + 'Z';
        return utcTime;
      },

      getFormatedDate(value) {
        if(!value) return null;
        return moment(String(value)).format('YYYY-MMM-DD').toUpperCase();
      },

      async handleSubmitOwnaHomeForm(fields) {
        this.submitting = true;

        let applicants = [
          {
            'applicantIndex': '1',
            'salutation': fields.Salutation.value,
            'firstName': fields.FirstName.value,
            'middleName': fields.MiddleName.value,
            'lastName': fields.LastName.value,
            'email': fields.Email.value,
            'dob': this.getFormatedDate(fields.DateOfBirth.value),
            'sex': fields.SexID.value,
            'cell': fields.PhoneNumber.value,
            'bvn': fields.BVN.value,
            'pencomPin': fields.PencomPin.value,

            'currentAddress': {
              'unit': fields.Unit.value,
              'state': fields.ProvinceOrState.value,
              'city': fields.LgaID.value,
              'houseplot': fields.HouseNumber.value,
              'streetNumber': fields.StreetNumber.value,
              'streetName': fields.StreetName.value,
              'estate': fields.Estate.value,
              'location': fields.Location.value,
              'countryCode': 'ng',
            },

            'employers': [
              {
                'employerName': fields.NameOfEmployer.value,
                'employeeId': fields.EmployeeID.value,
                'email': fields.EmployeeEmail.value,
                'title': fields.Designation.value,
                'type': fields.EmploymentType.value,
                'status': fields.EmploymentStatusID.value,
                'startDate': this.getFormatedDate(fields.EmploymentDate.value),
                'incomeAmount': fields.IncomeAmount.value,
                'incomeType': fields.IncomeType.value,
                'incomePeriod': fields.IncomePeriod.value,
                'occupation': fields.WhatIsYourPrimaryOccupation.value,
                'unit': fields.Unit.value,
                'houseplot': fields.OfficePlot.value,
                'streetNumber': fields.EmployerStreetName.value,
                'streetName': fields.EmployerStreetNumber.value,
                'estate': fields.Estate.value,
                'location': fields.Location.value,
                'city': fields.EmployerLga.value,
                'state': fields.EmployerState.value,
                'countryCode': 'ng',
              },
            ],

            'otherIncome': [
              {
                'description': fields.OtherIncomeDescription.value,
                'otherIncomeAmount': fields.OtherIncomeAmount.value,
                'otherIncomePeriod': fields.OtherIncomePeriod.value,
                'otherIncomeType': fields.OtherIncomeType.value,
              },
            ],
            'assets': [
              {
                'assetType': fields.AssetType.value,
                'description': fields.AssetDescription.value,
                'value': fields.AssetValue.value,
              },
            ],
            'liabilities': [
              {
                'accountNumber': fields.LiabilityAccountNumber.value,
                'balance': fields.LiabilityBalance.value,
                'description': fields.LiabilityDescription.value,
                'liabilityType': fields.LiabilityType.value,
                'monthlyPayment': fields.LiabilityMonthlyPayment.value,
              },
            ],
          }
        ];

        let subjectProperty = {
          'unit': fields.Unit.value,
          'houseplot': fields.PropertyPlot.value,
          'streetNumber': fields.PropertyStreetNumber.value,
          'streetName': fields.PropertyStreetName.value,
          'estate': fields.PropertyEstate.value,
          'location': fields.PropertyLocationOrArea.value,
          'city': fields.PropertyLGAID.value,
          'state': fields.PropertyProvinceOrState.value,
          'countryCode': 'ng',
          'type': fields.PropertyType.value,
          'tenure': fields.PropertyTenure.value,
          'age': fields.PropertyAge.value,
          'livingSpace': fields.PropertySpace.value,
          'lotSize': fields.PropertyLotSize.value,
          'numbersofrooms': fields.PropertyNoOfRooms.value,
          'plan': fields.PropertyPlan.value,
          'plot': fields.PropertyPlot.value,
          'annualTaxes': fields.PropertyAnnualTax.value,
          'estateServiceCharges': fields.PropertyEstateServiceCharges.value,
          'insurance': fields.PropertyInsurance.value,
          'valuation': fields.PropertyValuation.value,
          'valuationAsIs': fields.PropertyValuationAsIs.value,
          'valuationComplete': fields.PropertyValuationComplete.value,
          'valuationDate': fields.PropertyValuationDate.value,
          'valuationForceSaleAsIs': fields.PropertyValuationForSaleAsIs.value,
          'valuationForceSaleComplete': fields.PropertyValuationForSaleComplete.value,
        };

        let deal = {
          'dealId': this.generateUniquDealId(),
          'purchase': fields.Purchase.value,
          'deposit': fields.Deposit.value,
          'termMonths': fields.TermMonths.value,
          'applicants': applicants,
          'subjectProperty': subjectProperty
        };

        let data = {
          'utcTime': this.getUtcTime(),
          'deal': deal
        };

        var username = 'HMIP';
        var password = '0zThegreat$$';
        var authorization = 'Basic ' + btoa(username + ':' + password);

        const headers = {
          'Allow-Control-Allow-Origin': process.env.VUE_APP_COOKIE_TOKEN_DOMAIN,
          'Accept': "application/json",
          'Content-Type': 'application/json',
          'Authorization': authorization,
        };

        const requestOptions = {
          method: 'POST',
          withCredentials: true,
          headers: headers,
          body: JSON.stringify(data),
        };

        axios.post(process.env.VUE_APP_MMS_API_URL, requestOptions)
          .then(response => {
            console.log('mms', response)
            // save locally, notify user and admin
            if(response.status == 200) {
              let res = this.handleNotifiyNewSubmission(fields, deal.dealId);
              this.$swal({
                  icon: res.status === 200 ? "success" : "error",
                  title: res.status === 200 ? "Successful!" : "OOPS Something Went Wrong",
                  text: response.message ?? res.data.message
                });
                this.submitted = true;
                this.submitting = false;
            } else {
              this.$swal({
                  icon: "error",
                  title: "OOPS Something Went Wrong",
                  text: response.data.message
                });
                this.submitted = false;
                this.submitting = false;
            }
          })
          .catch(err => {
            this.$swal({
                icon: 'error',
                title: 'OOPS! Something went wrong',
                text: err
              });
              this.submitted = false;
              this.submitting = false;
          });
      },

      async handleNotifiyNewSubmission(fields, dealId) {
        let mms_notify_data = {
            // applicant
            applicantIndex: 1,
            salutation: fields.Salutation.value,
            firstName: fields.FirstName.value,
            middleName: fields.MiddleName.value,
            lastName: fields.LastName.value,
            email: fields.Email.value,
            dob: this.getFormatedDate(fields.DateOfBirth.value),
            sex: fields.SexID.value,
            cell: fields.PhoneNumber.value,
            bvn: fields.BVN.value,
            pencomPin: fields.PencomPin.value,

            // current addresss
            address_unit: fields.Unit.value,
            address_state: fields.ProvinceOrState.value,
            address_city: fields.LgaID.value,
            address_houseplot: fields.HouseNumber.value,
            address_streetNumber: fields.StreetNumber.value,
            address_streetName: fields.StreetName.value,
            address_estate: fields.Estate.value,
            address_location: fields.Location.value,
            address_countryCode: 'ng',

            // employer
            employer_employerName: fields.NameOfEmployer.value,
            employer_employeeId: fields.EmployeeID.value,
            employer_employeeEmail: fields.EmployeeEmail.value,
            employer_title: fields.Designation.value,
            employer_type: fields.EmploymentType.value,
            employer_status: fields.EmploymentStatusID.value,
            employer_startDate: this.getFormatedDate(fields.EmploymentDate.value),
            employer_incomeAmount: fields.IncomeAmount.value,
            employer_incomeType: fields.IncomeType.value,
            employer_incomePeriod: fields.IncomePeriod.value,
            employer_occupation: fields.WhatIsYourPrimaryOccupation.value,
            employer_unit: fields.Unit.value,
            employer_houseplot: fields.OfficePlot.value,
            employer_streetNumber: fields.EmployerStreetName.value,
            employer_streetName: fields.EmployerStreetNumber.value,
            employer_estate: fields.Estate.value,
            employer_location: fields.Location.value,
            employer_city: fields.EmployerLga.value,
            employer_state: fields.EmployerState.value,
            employer_CountryCode: 'ng',

            // otherIncome
            otherIncomeDescription: fields.OtherIncomeDescription.value,
            otherIncomeAmount: fields.OtherIncomeAmount.value,
            otherIncomePeriod: fields.OtherIncomePeriod.value,
            otherIncomeType: fields.OtherIncomeType.value,

            // assets
            assetType: fields.AssetType.value,
            assetDescription: fields.AssetDescription.value,
            assetValue: fields.AssetValue.value,

            // liabilities
            liabilities_accountNumber: fields.LiabilityAccountNumber.value,
            liabilities_balance: fields.LiabilityBalance.value,
            liabilities_description: fields.LiabilityDescription.value,
            liabilities_liabilityType: fields.LiabilityType.value,
            liabilities_monthlyPayment: fields.LiabilityMonthlyPayment.value,

            // subject property
            property_unit: fields.Unit.value,
            property_houseplot: fields.PropertyPlot.value,
            property_streetNumber: fields.PropertyStreetNumber.value,
            property_streetName: fields.PropertyStreetName.value,
            property_estate: fields.PropertyEstate.value,
            property_location: fields.PropertyLocationOrArea.value,
            property_city: fields.PropertyLGAID.value,
            property_state: fields.PropertyProvinceOrState.value,
            property_countryCode: 'ng',
            property_type: fields.PropertyType.value,
            property_tenure: fields.PropertyTenure.value,
            property_age: fields.PropertyAge.value,
            property_livingSpace: fields.PropertySpace.value,
            property_lotSize: fields.PropertyLotSize.value,
            property_numbersofrooms: fields.PropertyNoOfRooms.value,
            property_plan: fields.PropertyPlan.value,
            property_plot: fields.PropertyPlot.value,
            property_annualTaxes: fields.PropertyAnnualTax.value,
            property_estateServiceCharges: fields.PropertyEstateServiceCharges.value,
            property_insurance: fields.PropertyInsurance.value,
            property_valuation: fields.PropertyValuation.value,
            property_valuationAsIs: fields.PropertyValuationAsIs.value,
            property_valuationComplete: fields.PropertyValuationComplete.value,
            property_valuationDate: fields.PropertyValuationDate.value,
            property_valuationForceSaleAsIs: fields.PropertyValuationForSaleAsIs.value,
            property_valuationForceSaleComplete: fields.PropertyValuationForSaleComplete.value,

            // deal
            deal_dealId: dealId,
            deal_purchase: fields.Purchase.value,
            deal_deposit: fields.Deposit.value,
            deal_termMonths: fields.TermMonths.value,
        }

        const res = await axios.post('mms_submissions/create', mms_notify_data);
        return res;
      }
  }
}

</script>
<style lang="scss">
.own_a_home__survey {
  .survey__form {
      position: relative;
      .watermark__text {
        font-size: 60px;
      }
      &-content {
          z-index:1;
      }
      .survey__input {
        border: 2px solid gray;
        position: relative;
        height: 50px !important;
        &-label {
          position: absolute;
          top: -4px;
          left: 20px;
          background-color : #FFFFFF;
          padding: 2px 4px;
          text-transform: uppercase;
          z-index: 1;
        }
      }
  }
}
</style>
<template>
  <section
    id="head-area"
    class="head-area"
    data-midnight="white"
  >
    <div class="my-10 mx-10 md:mx-20 mt-40">
      <div
        id="heroBg"
        class="rounded-md p-6"
      >
        <carousel
          :per-page="1"
          :navigate-to="someLocalProperty"
          :mouse-drag="false"
          :autoplay="true"
          :loop="true"
        >
          <slide>
            <div class="flex gap-8 flex-col md:flex-row items-center">
              <div class="rounded-md w-full md:w-1/2">
                <img
                  src="https://www.villaafrika.com/wp-content/uploads/2021/04/galadimawa-terraced-house-7722.jpg"
                  class="rounded-md h-full w-full"
                  width="100%"
                >
              </div>

              <div class="md:w-1/2 w-full text-white flex flex-col item">
                <h2 class="mb-6 font-bold text-2xl">
                  Abuja Housing Survey
                </h2>
                <p style="color:white ;">
                  This survey aims to collect feedback and data related to home ownership, which will help us gather data that will create engagement and better meet the needs of our audience.
                </p>
                <button
                  class="rounded-sm md:w-2/6 w-1/2 mt-12 py-2 px-6 bg-green-800 text-white"
                  @click="$router.push('/survey/AbujaHousing')"
                >
                  Participate Now
                </button>
              </div>
            </div>
          </slide>
          <slide>
            <div class="flex gap-8 flex-col md:flex-row items-center">
              <div class="rounded-md w-full md:w-1/2">
                <img
                  src="https://images.unsplash.com/photo-1626178793926-22b28830aa30?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bW9ydGdhZ2V8ZW58MHx8MHx8fDA%3D"
                  class="rounded-md h-full w-full"
                  width="100%"
                >
              </div>

              <div class="md:w-1/2 w-full text-white flex flex-col item">
                <h2 class="mb-6 font-bold text-2xl">
                  Housing Research
                </h2>
                <p>
                  Navigate the Housing Landscape with Our <br>
                  Research Platform. <br>
                </p>
                <button
                  class="rounded-sm md:w-2/6 w-1/2 mt-12 py-2 px-6 bg-green-800 text-white"
                  @click="$router.push('/housing-price')"
                >
                  Read More
                </button>
              </div>
            </div>
          </slide>
          <slide>
            <div class="flex gap-8 flex-col md:flex-row items-center">
              <div class="rounded-md w-full md:w-1/2">
                <img
                  src="https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZXN0YXRlfGVufDB8fDB8fHww"
                  alt=""
                  width="100%"
                  class="rounded-md object-cover"
                >
              </div>

              <div class="md:w-1/2 w-full text-white flex flex-col item">
                <h2 class="mb-6 font-bold text-2xl">
                  Own a home
                </h2>
                <p>Find available properties to buy via a mortgage</p>
                <button
                  class="rounded-sm md:w-2/6 w-1/2 mt-12 py-2 px-6 bg-green-800 text-white"
                  @click="$router.push('/property-own-a-home')"
                >
                  Read More
                </button>
              </div>
            </div>
          </slide>
          <slide>
            <div class="flex gap-8 flex-col md:flex-row items-center">
              <div class="rounded-md w-full md:w-1/2">
                <img
                  src="https://media.istockphoto.com/id/1434464076/photo/real-estate-market-and-mortgage-documents.jpg?s=612x612&w=0&k=20&c=evSQHEyD8JZ3kx_LKHmvYLti1VMVvyAMbChdm2DQqxo="
                  alt=""
                  width="100%"
                  class="rounded-md"
                >
              </div>

              <div class="md:w-1/2 w-full text-white flex flex-col item">
                <h2 class="mb-6 font-bold text-2xl">
                  Housing Market Directory
                </h2>
                <p>
                  Get easy access to housing market professionals <br>
                  & institutions based on their functions. <br>
                </p>
                <button
                  class="rounded-sm md:w-2/6 w-1/2 mt-12 py-2 px-6 bg-green-800 text-white"
                  @click="$router.push('/housing-market')"
                >
                  Read More
                </button>
              </div>
            </div>
          </slide>
          <slide>
            <div class="flex gap-8 flex-col md:flex-row items-center">
              <div class="rounded-md w-full md:w-1/2">
                <img
                  src="https://149701725.v2.pressablecdn.com/wp-content/uploads/2019/06/mortgage-calculator.jpg"
                  alt=""
                  class="rounded-md"
                >
              </div>

              <div class="md:w-1/2 w-full text-white flex flex-col item">
                <h2 class="mb-6 font-bold text-2xl">
                  Detailed Mortage Calculator
                </h2>
                <p>
                  Determine your eligibility for a mortgage via our detailed
                  mortgage calculator
                </p>
                <button
                  class="rounded-sm md:w-2/6 w-1/2 mt-12 py-2 px-6 bg-green-800 text-white"
                  @click="$router.push('/housing-market')"
                >
                  <router-link
                    class="text-white"
                    :to="{ name: 'mortgage.calculator' }"
                  >
                    View More
                  </router-link>
                </button>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "HeroSection",
  components: { Carousel, Slide },

  data() {
    return {};
  },

  computed: {},
};
</script>

<template>
  <section class="sub-page iframe">
    <top-section
      :navs="navs"
      :title="nav_title"
    />

    <div
      v-if="!iframe.loaded"
      class="m-5 p-5 text-center"
    >
      <b-spinner
        class="m-5 p-5"
        variant="success"
        size="12rem"
      />
    </div>

    <iframe
      v-show="iframe.loaded"
      id="housing__data__dashboard"
      ref="housing__data__dashboard"
      :src="iframe.src"
      scrolling="no"
      class="p-3"
      title="Housing Survey Dashboard Data"
      rameborder="0"
      allowFullScreen="true"
      style="width: 100%; min-height: 920px; border: 0; overflow: hidden"
      @load="load"
    />
  </section>
</template>

<script>
import TopSection from "../../components/General/TopSection.vue";

export default {
  name: "SurveyDashboard",
  components: { TopSection },

  data: () => ({
    nav_title: "Datasets",
    navs: [
      {
        route_name: "home",
        route_title: "Home",
      },
      {
        route_name: "dashboard",
        route_title: "Dashboards",
      },
      {
        route_name: "dashboard.survey",
        route_title: "Housing Survey Dashboard",
        active: true,
      },
    ],
    visible: true,
    iframe: {
      src: "https://app.powerbi.com/view?r=eyJrIjoiMTIzZGRkNmQtOWI0Ni00MDllLTkwYzUtZWJmOTlmNWYwNTBiIiwidCI6ImE4MTNjYTk4LWU0MTMtNDJkMS1iNzNmLTlmZGM3YTJhYjhkNCIsImMiOjh9",
      loaded: false,
    },
  }),

  methods: {
    load() {
      this.iframe.loaded = true;
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}
</style>

import InfoIcon from '@mui/icons-material/Info';

<template>
  <section class="sub-page iframe">
    <top-section :navs="navs" :title="nav_title" />

    <!-- <div class="relative mt-10 mb-10">
      <div class="rounded-md mx-auto">
        <img
          src="/theme-assets/images/housingMarket.png"
          alt=""
          class="rounded-md h-[700px] md:h-[349px]"
        >
      </div>
      <div class="absolute top-28 left-1/2 transform -translate-x-1/2">
        <div class="text-center">
          <p class="text-white text-xl font-medium">
            Welcome to our Housing Market Information Portal! Here, you'll
            find comprehensive data and insights to empower your housing
            decisions. Explore real-time market trends, property prices, and
            more. Whether you're buying, selling, or renting, make informed
            choices with our reliable housing market information.
          </p>
        </div>
      </div>
    </div>
     -->
    <!-- <b-row
      cols="3"
      class="p-3 mx-auto col-md-10 my-3"
    >
      <b-col
        v-for="(dashboard, index) in dashboards"
        :key="index"
      >
        <div
          class="card m-1 mb-3 p-3 dashboard__card animated"
          data-animation="fadeInDown"
          :data-animation-delay="`1.` + index+1 + `s`"
        >
          <b-button
            variant="clear"
            @click="handleRouting(dashboard)"
          >
            <h5
              class="text-center p-3"
              v-html="dashboard.title"
            />
          </b-button>
        </div>        
      </b-col>
    </b-row> -->
    <div class="bg-gray-200 my-10 m-10 p-10">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10">
        <router-link :to="{ name: 'dashboard.housing' }">
          <span
            style="background-color: #c02020"
            class="bg-[#C02020] p-4 pr-2 rounded-lg flex flex-col"
          >
            <span
              class="bg-[#C02020] relative p-4 pr-2 rounded-lg flex gap-4 justify-between"
            >
              <span class="flex gap-4 items-center w-[98%]">
                <span class="p-2 rounded-lg bg-white">
                  <img
                    src="/theme-assets/images/house.png"
                    alt=""
                    class="w-8 h-8"
                  />
                </span>
                <span class="text-white"
                  >Housing Statistics <br />
                  Dashboard
                </span>
              </span>

              <span
                class="h-10 bg-none flex absolute top-0 right-0 justify-end w-[1%]"
              >
                <b-button
                  v-b-tooltip.hover
                  title="The housing statistics dashboard uses data obtained by the National Bureau of Statistics (NBS) and Central Bank of Nigeria (CBN) on behalf of NMRC. The data was captured in 2016/17 and the underlying datasets can be downloaded on the datasets page”:	Finance Data, Household data, and Public sector data
"
                >
                  <i class="fa fa-info-circle" />
                </b-button>
              </span>
            </span>
          </span>
        </router-link>

        <router-link :to="{ name: 'dashboard.pricing' }">
          <span
            style="background-color: #276c43"
            class="bg-[#276c43] p-4 pr-2 rounded-lg flex flex-col"
          >
            <span
              class="bg-[#276c43] relative p-4 pr-2 rounded-lg flex gap-4 justify-between"
            >
              <span class="flex gap-4 items-center w-[98%]">
                <span class="p-2 rounded-lg bg-white">
                  <img
                    src="/theme-assets/images/price-tag.png"
                    alt=""
                    class="w-8 h-8"
                  />
                </span>
                <span class="text-white"
                  >Housing Price Index
                  <br />
                  Dashboard</span
                >
              </span>

              <span
                class="h-10 bg-none flex absolute top-0 right-0 justify-end w-[1%]"
              >
                <b-button
                  v-b-tooltip.hover
                  title="The housing price index dashboard was derived from data obtained by Pison Housing Company (a housing finance advisory firm). Underlying datasets can be downloaded on the datasets page”:
•	Price Index
"
                >
                  <i class="fa fa-info-circle" />
                </b-button>
              </span>
            </span>
          </span>
        </router-link>

        <router-link :to="{ name: 'dashboard.nre' }">
          <span
            style="background-color: #2577d3"
            class="bg-[#2577d3] p-4 pr-2 rounded-lg flex flex-col"
          >
            <span
              class="bg-[#2577d3] relative p-4 pr-2 rounded-lg flex gap-4 justify-between"
            >
              <span class="flex gap-4 items-center w-[98%]">
                <span class="p-2 rounded-lg bg-white">
                  <img
                    src="/theme-assets/images/dashboard.png"
                    alt=""
                    class="w-8 h-8"
                  />
                </span>
                <span class="text-white"
                  >NRE - DCMP
                  <br />
                  Statistics
                </span>

                <span
                  class="h-10 bg-none flex absolute top-0 right-0 justify-end w-[1%]"
                >
                  <b-button
                    v-b-tooltip.hover
                    title="The National Real Estate Data Collation and Management Programme (NRE-DCMP) was a CBN-initiated programme to tackle challenges with real estate & housing finance in Nigeria. This dashboard was derived from data obtained in 2018 by the various entities involved in the NRE-DCMP. The underlying dataset can be obtained from the datasets page” (include link): 
•	Nre Data
"
                  >
                    <i class="fa fa-info-circle" />
                  </b-button>
                </span>
              </span>
            </span>
          </span>
        </router-link>
        <router-link :to="{ name: 'dashboard.survey' }">
          <span
            style="background-color: #fffe71"
            class="bg-[#FFFE71] p-4 pr-2 rounded-lg flex flex-col"
          >
            <span
              class="bg-[#2577d3] relative p-4 pr-2 rounded-lg flex gap-4 justify-between"
            >
              <span class="flex gap-4 items-center w-[98%]">
                <span class="p-2 rounded-lg bg-white">
                  <img
                    src="/theme-assets/images/dashboard.png"
                    alt=""
                    class="w-8 h-8"
                  />
                </span>
                <span class="text-black"
                  >Housing Survey
                  <br />
                  Dashboard
                </span>

                <span
                  class="h-10 bg-none flex absolute top-0 right-0 justify-end w-[1%]"
                >
                  <b-button
                    v-b-tooltip.hover
                    title="Housing Survey Dashboard	None	“The housing survey dashboard presents findings from a survey of 241 respondents conducted by the NMRC Research team at the Africa International Housing Show (AIHS) in 2023, aimed at understanding the preferences and challenges of homebuyers in Nigeria."
                  >
                    <i class="fa fa-info-circle" />
                  </b-button>
                </span>
              </span>
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import TopSection from "../../components/General/TopSection.vue";

import axios from "axios";

export default {
  name: "DashboardHome",
  components: { TopSection },

  data: () => ({
    nav_title: "Dashboards",
    navs: [
      {
        route_name: "home",
        route_title: "Home",
      },
      {
        route_name: "dashboard.home",
        route_title: "Dashboards",
        active: true,
      },
    ],
    dashboards: [
      {
        id: 1,
        title: "Housing Statistics <br> Dashboard",
        type: "link",
        route_name: "dashboard.housing",
      },
      {
        id: 2,
        title: "Housing Price Index <br> Dashboard",
        type: "link",
        route_name: "dashboard.pricing",
      },
      {
        id: 3,
        title: "NRE-DCMP <br> Dashboard",
        type: "link",
        route_name: "dashboard.nre",
      },
      {
        id: 4,
        title: "Housing Survey <br> Dashboard",
        type: "link",
        route_name: "dashboard.survey",
      },
    ],
  }),

  methods: {
    handleRouting(dashboard) {
      var link = "";
      switch (dashboard.type) {
        case "link":
          this.$router.push({ name: dashboard.route_name });
          break;
        case "download":
          // this.downloadItem(dashboard);
          link = document.createElement("a");
          link.href = dashboard.path;
          link.download = dashboard.file_name;
          link.click();
          break;

        default:
          break;
      }
    },

    async downloadItem(file) {
      const response = await axios.get(file.path, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file.file_name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
<style scoped>
.iframe {
  overflow-y: hidden;
}

.dashboard__card:hover {
  border-color: rgba(92, 164, 122, 0.7);
  box-shadow: 0px 0px 10px 2px rgba(92, 164, 122, 0.6);
  transition: border-color 1s, box-shadow 0.5s;
  background-color: rgb(254, 254, 254);
}

.dashboard__card:hover button {
  border-color: rgba(92, 164, 122, 0.7);
}
</style>

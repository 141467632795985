<template>
  <b-form
    ref="data-group-form"
    @submit.prevent="submitForm"
  >
    <b-form-group
      id="input-data_group-name"
      label="Enumerator Name:"
      label-for="data_group-name"
      invalid-feedback="Enumerator Full Name is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.enumerator.EnumeratorName.$model"
        :state="validateState($v.enumerator.EnumeratorName)"
        type="text"
        placeholder="Lorem Iro"
        required
      />
    </b-form-group>

    <div class="text-right m-2">
      <b-button variant="success" type="submit" >Save</b-button>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminEnumerators',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
        enumerator: {
          EnumeratorName: '',
          EnumeratorID: ''
        },
      }
    },

    validations: {
      enumerator: {
        EnumeratorName: {
          required,
        },
      },
    },

    mounted() {
      const { EnumeratorName, id } = this.data
      this.enumerator.EnumeratorName = EnumeratorName;
      this.enumerator.EnumeratorID = id;
    },
    methods: {
      validateState(enumerator) {
        const { $dirty, $error } = enumerator
        return $dirty ? !$error : null
      },

      submitForm() {
        this.$v.enumerator.$touch()
        if (this.$v.enumerator.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.handleUpdateData();
            break;
        
          default:
            this.handleSaveNewData();
            break;
        }
      },

      async handleSaveNewData() {
        this.submitting = true;
        await axios.post('NmmipEnumerators/create', this.enumerator)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async handleUpdateData() {
        this.submitting = true;

        await axios.post('NmmipEnumerators/update', this.enumerator)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

<template>
  <section
    id="contact"
    class="contact section-padding"
  >
    <div class="container-fluid">
      <div class="container">
        <div class="heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">
              Mortgage Calculator
            </h6>
            <h2 class="title">
              Detailed Mortgage Calculator
            </h2>
          </div>
          <p
            class="content-desc animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            Calculate Your Monthly Mortgage Payment
          </p>
        </div>
        <div>
          <!-- <div
            v-if="!iframe.loaded"
            class="m-5 p-5 text-center"
          >
            <b-spinner
              class="m-5 p-5"
              variant="success"
              size="12rem"
            />
          </div> -->
        </div>
      </div>
      <div class="my-10">
        <!-- <p class="my-24 text-2xl font-bold text-center">
          Detailed Amortization Mortgage <br>
          Calculator
        </p> -->

        <div class="flex flex-row w-[80%] gap-8 items-start mx-auto justify-between">
          <div class="flex flex-col border-2 rounded-lg w-full md:w-[40%] mx-auto">
            <div class="py-4 px-4 bg-white">
              <div class="pb-4">
                <div class="pb-6 mt-10">
                  <h2 class="pb-2 font-bold text-2xl md:text-3xl">
                    Mortgage Amortization Calculator
                  </h2>
                  <p class="text-sm">
                    Use this calculator to input the details of your loan and
                    see how those payments break down over your loan term.
                  </p>
                </div>

                <div class="px-1 md:px-2 md:pl-4">
                  <form
                    class=""
                    @submit.prevent="calculateMortgage"
                  >
                    <div class="mb-6 md:mb-4 flex flex-col">
                      <label class="pb-2 font-bold">Loan Amount</label>
                      <input
                        id="amount"
                        v-model="form.principal"
                        placeholder="5000000"
                        type="number"
                        class="p-3  border border-black"
                      >
                    </div>
                    <div class="mb-6 md:mb-4 flex flex-col">
                      <label class="pb-2 font-bold">Loan Term in Years</label>

                      <input
                        v-model="form.term"
                        type="number"
                        class="p-3 border-black bg-grey py-1 border "
                      >
                    </div>
                    <div class="mb-6 md:mb-4 flex flex-col">
                      <label class="pb-2 font-bold">Interest Rate</label>
                      <input
                        v-model="form.interest"
                        type="number"
                        class="border-black p-3 border"
                      >
                    </div>
                    <div class="mb-6 md:mb-4 flex flex-col">
                      <label class="pb-2 font-bold">Your Monthly Income</label>
                      <input
                        v-model="form.income"
                        type="number"
                        class="border-black p-3 border"
                      >
                    </div>
                    <div class="mb-6 md:mb-4 flex flex-col">
                      <label class="pb-2 font-bold">Loan Start Date</label>
                      <input
                        v-model="form.startDate"
                        type="date"
                        class="border-black bg-grey p-3 border"
                      >
                    </div>
                    <button class="text-center w-full py-3 bg-[#1b483e] text-white">
                      calculate
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="bg-[#1b483e] w-full z-4 text-white text-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              <div class="md:pt-10 pt-10 p-6 text-white">
                <div class="pb-4">
                  <p class="text-base text-left text-white">
                    Summary
                  </p>
                </div>

                <hr class="bg-white">

                <div class="pt-4 flex flex-col gap-6 text-center text-white">
                  <p class="flex justify-between text-white">
                    Monthly Payment <span>NGN {{ formatAsCurrency(requiredIncome) }}</span>
                  </p>
                  <p class="flex justify-between text-white">
                    Annual Loan Payment <span>NGN{{ formatAsCurrency(annualPayment) }}</span>
                  </p>
                  <p class="flex justify-between text-white">
                    Total Principal Paid <span>{{ formatAsCurrency(principal) }}</span>
                  </p>
                  <p class="flex justify-between text-white">
                    Interest Rate <span>{{ form?.interest }}%</span>
                  </p>
                  <p class="flex justify-between text-white">
                    Pay off Date <span>{{ startDate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-[60%] text-white animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            <div class="flex gap-8 w-full justify-between text-white">
              <div class="p-4 bg-[#275148] w-full md:w-[50%] rounded-md">
                <p class="pb-8 leading-10 text-white">
                  Amortization For <br>
                  Monthly Payment:
                </p>
               
                <h2
                  id="requiredIncome"
                  class="font-bold text-3xl"
                >
                  NGN {{ formatAsCurrency(requiredIncome) }}
                </h2>
                over
                <h2
                  id="requiredIncome"
                  class="font-bold text-3xl"
                >
                  {{ form.term }} {{ form.term>1? 'years' : 'year' }}
                </h2>
              </div>
              <div class="p-4 bg-[#398559] w-full md:w-[50%] rounded-md">
                <p class="pb-8 leading-8 text-white">
                  Affordability Test Monthly Income:<br>
                </p><div class="pb-16 text-white">
                  <div
                    v-if="positiveResult"
                    class="alert alert-success "
                  >
                    <span>
                      <i
                        class="fa fa-check-circle"
                        aria-hidden="true"
                      />
                      You are qualified for the specified principal

                    </span>
                  </div>
                  <div
                    v-if="negativeResult"
                    class="alert alert-danger"
                  >
                    <span>
                      <i
                        class="fa fa-times-circle"
                        aria-hidden="true"
                      />
                      You are not qualified for the specified principal

                    </span>
                  </div>
                  <p class="text-white text-lg">
                    Required monthly repayment is
                  </p>
                  <h2
                    id="requiredIncome"
                    class="font-bold text-3xl"
                  >
                    NGN {{ formatAsCurrency(requiredIncome) }}
                  </h2>

                  <p class="text-white text-lg">
                    Your monthly income is 
                  </p>
                  <h2
                    id="requiredIncome"
                    class="font-bold text-3xl"
                  >
                    NGN {{ formatAsCurrency(actualIncome) }}
                  </h2>
                  <p class="text-sm text-white">
                    Only 33% of monthly income can be contributed: NGN {{ formatAsCurrency(qualifiedAmount) }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="mt-10 animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.5s"
            >
              <export-excel
                :data="detailResult"
              >
                <button class="text-center w-full py-3 bg-[#1b483e] text-white">
                  Export to Excel
                </button>
              </export-excel>
              <div class="dt-responsive table-responsive">
                <table
                  id="simpletable"
                  style="width: 700px;"
                  class="table table-striped table-bordered nowrap"
                >
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Monthly Payment Due</th>
                      <th>Interest Due</th>
                      <th>Principal Due</th>
                      <th>Principal Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- {
       month: i,
       paymentDue: result,
       interest:interest,
       principalDue:principalDue,
       principalBalance:principalBalance
      } -->
                    <tr
                      v-for="(detail,i) in detailResult"
                      :key="i"
                    >
                      <td>{{ detail.month }}</td>
                      <td>{{ formatAsCurrency(detail.paymentDue) }}</td>
                      <td>{{ formatAsCurrency(detail.interest) }}</td>
                      <td>{{ formatAsCurrency(detail.principalDue) }}</td>
                      <td>{{ formatAsCurrency(detail.principalBalance) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Month</th>
                      <th>Monthly Payment Due</th>
                      <th>Interest Due</th>
                      <th>Principal Due</th>
                      <th>Principal Balance</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import Form from 'vform';
import { debounce } from 'lodash';

export default {
  name: 'DetailedMortgageCalculator',

  data() {
    return {
      visible: true,
      iframe: {
        src: 'https://nmrc.com.ng/mortgage/mortgage/',
        loaded: false
      },
      form: new Form({
        principal: '',
        term: 0,
        interest: '',
        income: '',
      }),
      years: ["5", "10", "15", "20", "25", "30"],
      test: {
        success: false,
        result: '',
      },
       detailResult:new Array(),
      requiredIncome: 0,
      actualIncome: 0,
      principal: 0,
      interest: 0,
      annualPayment: 0,
      qualifiedAmount: 0,
      positiveResult: false,
      negativeResult: false,
      startDate: new Date()
    };
  },

  methods: {
    formatAsCurrency(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    load() {
      this.iframe.loaded = true;
    },
    changeTerm(e) {
      this.form.term = e.target.value;
    },
    formatPrincipal: debounce(function () {
      if (this.form.principal.length > 3) {
        const fm = this.form.principal.replace(/[^\d,]/g, '');
        this.form.principal = parseFloat(fm).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
    }, 500),
    formatIncome: debounce(function () {
      if (this.form.income.length > 3) {
        const fm = this.form.income.replace(/[^\d,]/g, '');
        this.form.income = parseFloat(fm).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
    }, 500),
    setPercentage: debounce(function () {
      if (this.form.interest.length > 1) {
        this.form.interest = this.form.interest.replace(/[^\d,]/g, '') + '%';
      }
    }, 500),
    calculateMortgage() {
      this.detailResult = new Array();
     
      var principal = parseFloat(this.form.principal);
      this.principal = principal;
      var period = this.form.term;
      var interest = parseFloat(this.form.interest);
      this.actualIncome = this.form.income;

      var percentageRage = interest / 1200;
      var lengthOfMortgage = 12 * period;
      var monthlyPayment = (principal * percentageRage) / (1 - (Math.pow((1 + percentageRage), lengthOfMortgage * -1)));

      var result = monthlyPayment.toFixed(2);
      this.requiredIncome = result;
      this.interest = ((monthlyPayment * period * 12) - this.principal).toFixed(2)
      this.qualifiedAmount = +this.actualIncome * 0.33;
      this.annualPayment = monthlyPayment * 12;

      for (let i=1; i < lengthOfMortgage; i++){
      let interestDue = (principal * interest) /1200
      let principalDue = result - interestDue;
    
      let principalBalance = +principal - principalDue;
      this.detailResult.push({
       month: i,
       paymentDue: result,
       interest:interestDue,
       principalDue:principalDue,
       principalBalance:principalBalance
      })
      principal = principalBalance.toFixed(2);
      }
    
      if (+this.requiredIncome > (+this.qualifiedAmount)) {
        this.negativeResult = true
        this.positiveResult = false;
      } else {
        this.positiveResult = true
        this.negativeResult = false;
      }


      if (result > 0.5) {
        this.test.success = true;
        this.test.result = result;
      } else {
        this.test.success = false;
        this.test.result = result;
      }
    }
  }

}


</script>

<template>
  <div>
    <template v-if="currentView === 'login'">
      <login-form @change_auth_view="handleCurrentView" />
    </template>
    <template v-if="currentView === 'register'">
      <register-form @change_auth_view="handleCurrentView" />
    </template>
    <template v-if="currentView === 'initiate-reset'">
      <initiate-reset @change_auth_view="handleCurrentView" />
    </template>
    <template v-if="currentView === 'reset'">
      <reset-password @change_auth_view="handleCurrentView" />
    </template>
  </div>
</template>
<script>
import LoginForm from './LoginForm.vue';
import RegisterForm from './RegisterForm.vue';
import ResetPassword from './ResetPassword.vue';
import InitiateReset from './InitiateReset.vue';

  export default {
    name: 'AuthContainer',
    components: { LoginForm, RegisterForm, ResetPassword, InitiateReset },
    
    data: function() {
      return {
          currentView: 'login',
      }
    },

    methods: {
        handleCurrentView(view) {
          this.currentView = view;
        },
    },
};
</script>
<style>
.input-prepend {
  position: absolute;
  right: 30px;
  bottom: 8px;
  cursor: pointer;
}
</style>

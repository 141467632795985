<template>
  <section
    id="login"
    data-midnight="white"
    class="login section-padding bg-gradient"
    style="min-height: 100vh; height: 100%;"
  >
    <b-row class="mx-auto">
      <b-col
        cols="8"
        class="mx-auto"
      >
        <b-card>
          <container />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Container from '../../components/Auth/Container.vue';

export default {
    name: 'Login',
    components: { Container },

  methods: {

    async handleLogin() {

    }
  }
}
</script>


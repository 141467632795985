<template>
  <div class="nredc_survey">
    <survey-form
      :title="title"
      :success-message="success_message"
      :success-action="success_action"
      :type="type"
      :submitted="submitted"
      @submit-form="handleSubmitMarketSurvey"
    >
      <template #logo>
        <div class="container px-5">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <img
                class="w-10"
                width="100"
                src="~@/assets/theme-assets/images/coat_of_arms_of_nigeria.png"
                alt=""
              >
            </div>
            <div>
              <img
                width="100"
                src="~@/assets/theme-assets/images/analytics.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </template>
    </survey-form>
  </div>
</template>

<script>
import SurveyForm from './SurveyForm.vue';
import axios from 'axios';

export default {
  name: 'MarketSurvey',
  components: { SurveyForm },
 
  data: function() {
    return {
      title: 'NREDC Survey Form',
      success_message: '<h5>Thanks for taking the time to complete the <b>NREDC Survey form</b>.</h5> <h5>You can proceed to Morgage calculator</h5>',
      success_action: {
        title: 'go back',
        action: 'go_back',
        url: 'root',
      },
      type: 'survey',
      submitted: false,
    }
  },

  methods: {
      // TODO: submit to backend
      async handleSubmitMarketSurvey(fields) {
        this.submitting = true;

        const survey_form = {
          CurrentAddress: fields.CurrentAddress.value,
          DateOfBirth: fields.DateOfBirth.value,
          Dependents: fields.Dependents.value,
          Designation: fields.Designation.value,
          Email: fields.Email.value,
          EmployerLocationOrArea: fields.EmployerLocationOrArea.value,
          EmployerStreetName: fields.EmployerStreetName.value,
          EmploymentSectorID: fields.EmploymentSectorID.value,
          EmploymentStatusID: fields.EmploymentStatusID.value,
          Enumerator: fields.Enumerator.value,
          FirstName: fields.FirstName.value,
          HouseNumber: fields.HouseNumber.value,
          LastName: fields.LastName.value,
          LengthOfTimeSpentIndwelling: fields.LengthOfTimeSpentIndwelling.value,
          LgaID: fields.LgaID.value,
          MiddleName: fields.MiddleName.value,
          MonthlyIncome: fields.MonthlyIncome.value,
          MortagePropertyAmount: fields.MortagePropertyAmount.value,
          NameOfEmployer: fields.NameOfEmployer.value,
          OfficeAddressNumber: fields.OfficeAddressNumber.value,
          OwnershipTypeID: fields.OwnershipTypeID.value,
          PhoneNumber: fields.PhoneNumber.value,
          PropertyLGAID: fields.PropertyLGAID.value,
          PropertyLocationOrArea: fields.PropertyLocationOrArea.value,
          PropertyProvinceOrState: fields.PropertyProvinceOrState.value,
          PropertyTypeID: fields.PropertyTypeID.value,
          PropertyValue: fields.PropertyValue.value,
          ProvinceOrState: fields.ProvinceOrState.value,
          RentAmountPerYear: fields.RentAmountPerYear.value,
          Salutation: fields.Salutation.value,
          SexID: fields.SexID.value,
          StreetName: fields.StreetName.value,
          TypeOfBuyerID: fields.TypeOfBuyerID.value,
          WhatIsYourPrimaryOccupation: fields.WhatIsYourPrimaryOccupation.value,
        }

        // TODO: At the time of implementing this, a few things were uncertain from the backend / the client thus it's incomplete
        // A few items needs to be referenced with existing columns on the backend
        await axios.post('/NmmipNredcSurveyForm/create', survey_form)
            .then(response => {
              const { status, data } = response;
                this.$swal({
                    icon: status === 200 ? 'success' : 'error',
                    title: status === 200 ? 'Operation Successful' : 'OOPS! Something went wrong',
                    text: data.message
                })

              if(status === 200) {
                this.submitted = true;
                setTimeout(() => {
                  this.$router.push('/');
                }, 3000)
              }
            })
            .catch(error => {
              console.log(error);
              this.$swal({
                  icon: 'error',
                  title: 'OOPS! Something went wrong.',
                  text: error.data.message ?? 'HINT: Refresh and try again'
              })
            });
        this.submitting = false;
      }
  }
}

</script>
<style lang="scss">
.nredc_survey {
  .survey__form {
      position: relative;
      .watermark__text {
        font-size: 60px;
      }
      &-content {
          z-index:1;
      }
      .survey__input {
        height: 50px !important;
        &-label {
          text-transform: uppercase;
        }
      }
  }
}
</style>
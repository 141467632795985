<template>
  <section
    id="faq"
    class="faq section-padding bg-gradient"
    data-midnight="white"
  >
    <div class="container-fluid">
      <div class="container">
        <div class="dark-bg-heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">
              submit a property 
            </h6>
            <h2 class="title">
              Property
            </h2>
          </div>
        </div>
        
        <survey-submit-property />
      </div>
    </div>
  </section>
</template>

<script>
import SurveySubmitProperty from '../../components/Survey/SubmitProperty.vue'

export default {
  name: 'PropetySubmitProperty',
  components: { SurveySubmitProperty },

  data: () => ({
  
  }),
}
</script>

<template>
  <b-form
    ref="change-password-form"
    @submit.prevent="handleUpdateData"
  >
    <h4>Update Password</h4>
    <b-form-group
      id="input-CurrentPassword"
      label="Current Password:"
      label-for="CurrentPassword"
      invalid-feedback="Current Password is required"
      class="mb-3"
    >
      <b-form-input
        id="CurrentPassword"
        v-model="$v.password.CurrentPassword.$model"
        :state="validateState($v.password.CurrentPassword)"
        type="password"
        placeholder="**********"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-Password"
      label="New Password:"
      label-for="Password"
      invalid-feedback="New Password is required and must be at lease 8 digits"
      class="mb-3"
    >
      <b-form-input
        id="Password"
        v-model="$v.password.Password.$model"
        :state="validateState($v.password.Password)"
        type="password"
        placeholder="**********"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-confirm_Password"
      label="Confirm New Password:"
      label-for="confirm_Password"
      invalid-feedback="Password Confirmation is required"
      class="mb-3"
    >
      <b-form-input
        id="confirm_Password"
        v-model="$v.password.confirm_Password.$model"
        :state="validateState($v.password.confirm_Password)"
        type="password"
        placeholder="**********"
        required
      />
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          :disabled="submitting"
          @click="handleUpdateData"
        >
          Save
          <b-spinner small v-if="submitting" />
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, minLength, sameAs } from "vuelidate/lib/validators"
  import axios from 'axios';

  export default {
    name: 'AdminChangePassword',
    mixins: [validationMixin],

    props: {
      user: {
          type: Object,
          default: () => {},
          required: true
      },
    },
    
    data: function() {
      return {
        submitting: false,
        password: {
            CurrentPassword: '',
            Password: '',
            confirm_Password: '',
            UserID: '',
        }
      }
    },

    mounted() {
      if(this.user) {
        const { UserID } = this.user;
        this.password.UserID = UserID;
      }
    },

    validations: {
      password: {
        CurrentPassword: {
          required
        },
        Password: {
          required,
          minLength: minLength(8)
        },
        confirm_Password: {
            required,
            sameAsPassword: sameAs('Password')
        },
      },
    },

    methods: {
      validateState(password) {
        const { $dirty, $error } = password
        return $dirty ? !$error : null
      },

      async handleUpdateData() {
        this.$v.password.$touch()
        if (this.$v.password.$anyError) return false;

        this.submitting = true;

        await axios.post('User/updatePassword', this.password)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Password Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
      },
    },
};
</script>

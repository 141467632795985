<template>
  <b-form
    ref="data-group-form"
    @submit.prevent="submitForm"
  >
    <b-form-group
      id="input-data_group-name"
      label="Property Type:"
      label-for="data_group-name"
      invalid-feedback="Property Type is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.property.PropertyTypeName.$model"
        :state="validateState($v.property.PropertyTypeName)"
        type="text"
        placeholder="2 Bedroom"
        required
      />
    </b-form-group>

    <div class="text-right m-2">
      <b-button variant="success" type="submit" >Save</b-button>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"

  export default {
    name: 'AdminPropertyType',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         property: {
            PropertyTypeName: '',
            PropertyTypeID: ''
        },
      }
    },

    validations: {
      property: {
        PropertyTypeName: {
          required,
        },
      },
    },

    mounted() {
      const { PropertyTypeName, PropertyTypeID } = this.data
      this.property.PropertyTypeName = PropertyTypeName;
      this.property.PropertyTypeID = PropertyTypeID;
    },
    methods: {
      validateState(property) {
        const { $dirty, $error } = property
        return $dirty ? !$error : null
      },

      submitForm() {
        this.$v.property.$touch()
        if (this.$v.property.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.handleUpdateData();
            break;
        
          default:
            this.handleSaveNewData();
            break;
        }
      },

      async handleSaveNewData() {
        this.submitting = true;
        await axios.post('PropertyType/create', this.property)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async handleUpdateData() {
        this.submitting = true;

        await axios.post('PropertyType/update', this.property)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

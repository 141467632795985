<template>
  <b-form
    ref="lga-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      id="input-lga-name"
      label="Name:"
      label-for="lga-name"
      invalid-feedback="LGA Name is required"
      class="mb-3"
    >
      <b-form-input
        id="data_group-name"
        v-model="$v.lga.LgaName.$model"
        :state="validateState($v.lga.LgaName)"
        type="text"
        placeholder="LGA Name"
        required
      />
    </b-form-group>

    <b-form-group
      id="input-lga-state"
      label="State:"
      label-for="lga-state"
      class="mb-3"
    >
      <multiselect
        v-model="$v.lga.state.$model"
        :options="states"
        :clear-on-select="false"
        placeholder="Select State"
        label="StateName"
        track-by="StateName"
      />
      <b-form-invalid-feedback :state="validateState($v.lga.state)">
        LGA State is required
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save
          <b-spinner
            v-if="submitting"
            class="ml-2"
            label="Loading..."
            small
          />
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'AdminDataEntryLGA',
    components: { Multiselect  },
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
        submitting: false,
        lga: {
          LgaName: '',
          state: '',
        },
        states: [],
      }
    },

    validations: {
      lga: {
        LgaName: {
          required,
        },
        state: {
          required,
        },
      },
    },

    mounted() {
      this.lga.LgaName = this.data.LgaName;
      this.lga.state = this.data.NmmipStates;
      this.fetchStatesData();
    },

    methods: {
      validateState(lga) {
        const { $dirty, $error } = lga
        return $dirty ? !$error : null
      },

      async fetchStatesData() {
        await axios.get('NmmipStates/fetch')
          .then(
            response => (this.states = response.data.details)
          )
          .catch(
            error => (
              this.error = true
            )
          );
      },

      handleUpdateData() {
        this.$v.lga.$touch()
        if (this.$v.lga.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
      },

      async updateSaveNewData() {
        this.submitting = true;

        const createData = {
          LgaName: this.lga.LgaName,
          StateID: this.lga.state.id
        };

        await axios.post('Lga/create', createData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async updateExistingData() {
        this.submitting = true;

        const updateData = {
          LgaID: this.data.id,
          LgaName: this.lga.LgaName,
          StateID: this.lga.state.id
        };

        await axios.post('Lga/update', updateData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>

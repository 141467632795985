<template>
  <b-form
    ref="initate-password-recovery-form"
  >
    <b-button
      variant="link"
      :to="{ path: '/' }"
    >
      <i class="fa fa-home" />
    </b-button>
      
    <b-row class="p-3">
      <b-col
        class="animated d-flex justify-content-center align-items-center"
        data-animation="fadeInLeftShorter"
        data-animation-delay="0.5s"
      >
        <router-link
          :to="{ path: '/' }"
        >
          <b-img-lazy
            fluid
            :src="hmip"
            alt="Logo"
          />
        </router-link>
      </b-col>
      <b-col
        class="animated"
        data-animation="fadeInRightShorter"
        data-animation-delay="0.5s"
      >
        <div class="text-center mb-4">
          <h3 class="m-0">
            Password Recovery
          </h3>
          <small>Provide the email address associated with your account</small>
        </div>
     
        <b-form-group
          id="input-login-email"
          label="Email Address:"
          invalid-feedback="Email Address is required and must be a valid email"
          class="mb-3"
        >
          <b-form-input
            id="login-email"
            v-model="$v.recovery.Email.$model"
            :state="validateState($v.recovery.Email)"
            type="email"
            placeholder="Email Address"
            required
          />
        </b-form-group>

        <div class="d-flex justify-content-between align-items-center mt-4">
          <button
            type="submit"
            class="btn btn-gradient-purple btn-glow"
            @click.prevent="handleUpdateData"
          >
            Submit
          </button>
          <br>
          <div>
            <small class="text-dark mt-2">
              Need an Account?
              <b-button
                variant="link"
                :disabled="submitting"
                @click="handleChangeAuthContainer('register')"
              >
                <small>Register</small>
                <b-spinner small v-if="submitting" />
              </b-button>
            </small>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, email } from "vuelidate/lib/validators"
  import logo from '../../assets/theme-assets/images/loader-logo.png'
  import axios from 'axios';

  export default {
    name: 'AuthInitatePasswordRecovery',
    mixins: [validationMixin],
    
    data: function() {
      return {
        submitting: false,
        hmip: logo,
        recovery: {
          Email: '',
        }
      }
    },

    validations: {
      recovery: {
        Email: {
          required,
          email
        },
      },
    },

    methods: {
      handleChangeAuthContainer(view) {
        this.$emit('change_auth_view', view);
      },

      validateState(recovery) {
        const { $dirty, $error } = recovery
        return $dirty ? !$error : null
      },

      async handleUpdateData() {
        this.$v.recovery.$touch()
        if (this.$v.recovery.$anyError) return false;

        this.submitting = true;

        await axios.post('User/initiatepasswordreset', this.recovery)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Successfully! Check you mail for OTP',
                  })
                this.handleChangeAuthContainer('reset');
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: error.data.message ?? 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
      }
    },
};
</script>

<template>
  <section class="survey__form my-5 section-padding col-md-10 mx-auto shadow-lg position-relative">
    <div
      v-if="!submitted"
    >
      <watermark :text="title" />
      <div
        class="survey__form-content"
      >
        <div class="section-pro">
          <slot name="logo" />
          <slot name="meta" />

          <div class="m-3  d-none d-lg-block">
            <step-indicator
              :steps-count="totalSteps"
              :current-step="currentStep"
              :submitted="submitted"
              :captions="captions"
            />
          </div>

          <!-- {{ states }} -->

          <form
            action=""
            class="p-5"
            v-if="fields"
          >
            <div
              v-for="(fieldKeys, step) in steps"
              :key="step"
            >
              <div
                v-if="currentStep === step"
                class="row d-flex flex-wrap justify-content-between align-items-center"
              >
                <form-fields
                  v-for="(field, index) in fieldKeys"
                  :key="index" 
                  class="my-1 col-md-6 w-100 px-lg-3"
                  :field="fields[field]"
                  :is-invalid="invalids[field]"
                  :invalid-message="invalids[field]"
                  :check-mate="validateField[field]"
                  :type="type"
                  :current-step="currentStep"
                  @validate-input="validate"
                />
              </div>
            </div>
            <div class="col-md-3 ml-auto text-right">
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  v-if="!isFirstStep"
                  type="button"
                  class="btn btn-gradient-purple btn-glow btn-sm"
                  @click="previousStep"
                >
                  Previous
                </button>
                <button
                  v-if="!isLastStep"
                  type="button"
                  class="btn btn-gradient-purple btn-glow btn-sm"
                  @click.prevent="nextStep"
                >
                  Next
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-gradient-purple btn-glow btn-sm"
                  @click.prevent="submitSurvey"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-5 col-md-6 mx-auto "
    >
      <success-message
        :message="successMessage"
        :action="successAction"
      />
      <!-- @close_survey_modal="handleCloseSurveyModal" -->
    </div>
  </section>
</template>

<script>
import FormFields from './partials/FormFields.vue';
import StepIndicator from './partials/StepIndicator.vue';
import form_fields from './partials/form_fields';
import Watermark from './partials/Watermark.vue';
import SuccessMessage from './partials/SuccessMessage.vue';
import axios from 'axios';
import { forEach } from 'lodash';

export default {
    name: 'SurveyForm',
    components: {
        FormFields,
        StepIndicator,
        Watermark,
        SuccessMessage
    },
    props: {
        title: {
            type: String,
            default: 'Survey Form',
        },
        successMessage: {
            type: String,
            default: '',
        },
        successAction: {
            type: Object,
            default: () => {},
        },
        submitted: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'survey',
        },
    },

    emits: ['submit-form'],
    data: function() {
      return {
        currentStep: 0,
        submitting: false,
        invalids: {},
        fields: '',
        captions: [
        {
            title: 'personal information',
        },
        {
            title: 'current address',
        },
        {
            title: 'employement information',
            sub_title: 'employers address'
        },
        {
            title: 'subject property',
            description: 'property of interest',
        },
        ],
        steps: [
            [
                'Salutation',
                'LastName',
                'FirstName',
                'MiddleName',
                'Email',
                'PhoneNumber',
                'DateOfBirth',
                'SexID',
                'Dependents',
                'Enumerator',
            ],
            [
                'ProvinceOrState',
                'LgaID',
                'Location',
                'StreetName',
                'LengthOfTimeSpentIndwelling',
                'HouseNumber',
                'OwnershipTypeID',
                'PropertyValue',
                'CurrentAddress',
                'RentAmountPerYear',
            ],
            [
                'WhatIsYourPrimaryOccupation',
                'NameOfEmployer',
                'EmploymentSectorID',
                'Designation',
                'EmploymentStatusID',
                'MonthlyIncome',
                'SecondaryIncome',
                // 'SecondaryIncomeAmount',
                'EmployerStreetName',
                'EmployerLocationOrArea',
                'OfficeAddressNumber',
            ],
            [
                'PropertyProvinceOrState',
                'PropertyLGAID',
                'PropertyLocationOrArea',
                'TypeOfBuyerID',
                'PropertyTypeID',
                'MortagePropertyAmount',
            ]
        ],
        state_list: [],
        gender_list: [],
        enumerators_list: [],
        employment_status_list: [],
        employment_sector_list: [],
        ownership_type_list: [],
        buyer_type_list: [],
        property_type_list: [],
        secondary_income_list: [],
      }
    },

  computed: {
      totalSteps() {
          return this.steps.length -1;
      },
      currentFields() {
          return this.steps[this.currentStep];
      },
      isFirstStep() {
          return this.currentStep === 0;
      },
      isLastStep() {
          return this.currentStep === this.totalSteps;
      },
  },

  async mounted() {
    await this.fetchMetaData();
    await this.updateOptions();
  },


  methods: {
    isInvalid() {
        return Object.values(this.invalids).filter((key) => key).length;
    },

    async fetchMetaData() {
      await axios.get('/NmmipNredcSurveyForm/fetch_meta')
          .then(response => {
            const { status, data} = response;
            if(status === 200) {
              const { states, genders, enumerators, employment_status, employment_sector, ownership_type, buyer_type, property_type, secondary_income } = data.details;
              this.state_list = states;
              this.gender_list = genders;
              this.enumerators_list = enumerators;
              this.employment_status_list = employment_status;
              this.employment_sector_list = employment_sector;
              this.ownership_type_list = ownership_type;
              this.buyer_type_list = buyer_type;
              this.property_type_list = property_type;
              this.secondary_income_list = secondary_income;
            }
          })
          .catch(error => (
            this.$swal({
                icon: 'error',
                title: 'OOPS! Something went wrong.',
                text: error.message ?? 'HINT: Refresh and try again'
            })
          ));
    },

    async updateOptions() {
      forEach(form_fields, field => {
        if(field.slug) {
          switch (field.slug) {
            case 'gender':
              if(this.gender_list.length) {
                field.options.length = [];
                this.gender_list.map(gender => {
                  field.options[field.options.length] = new Option(gender.SexName, gender.id);
                })
              }
              break;

            case 'state':
              if(this.state_list.length) {
                field.options.length = [];
                this.state_list.map(list => {
                  field.options[field.options.length] = new Option(list.StateName, list.id);
                })
              }
              break;

            case 'ownership_type':
              if(this.ownership_type_list.length) {
                field.options.length = [];
                this.ownership_type_list.map(list => {
                  field.options[field.options.length] = new Option(list.OwnershipTypeName, list.OwnershipTypeID);
                })
              }
              break;

            case 'employment_sector':
              if(this.employment_sector_list.length) {
                field.options.length = [];
                this.employment_sector_list.map(list => {
                  field.options[field.options.length] = new Option(list.EmploymentSectorName, list.EmploymentSectorID);
                })
              }
              break;

            case 'employment_status':
              if(this.employment_status_list.length) {
                field.options.length = [];
                this.employment_status_list.map(list => {
                  field.options[field.options.length] = new Option(list.EmploymentStatusName, list.EmploymentStatusID);
                })
              }
              break;

            case 'type_of_buyer':
              if(this.buyer_type_list.length) {
                field.options.length = [];
                this.buyer_type_list.map(list => {
                  field.options[field.options.length] = new Option(list.TypeOfBuyerName, list.TypeOfBuyerID);
                })
              }
              break;

            case 'property_of_interest':
              if(this.property_type_list.length) {
                field.options.length = [];
                this.property_type_list.map(list => {
                  field.options[field.options.length] = new Option(list.PropertyTypeName, list.PropertyTypeID);
                })
              }
              break;

            case 'enumerators':
              if(this.enumerators_list) {
                field.options.length = [];
                this.enumerators_list.map(list => {
                  field.options[field.options.length] = new Option(list.EnumeratorName, list.id);
                })
              }
              break;

            case 'property_state':
              if(this.state_list.length) {
                field.options.length = [];
                this.state_list.map(list => {
                  field.options[field.options.length] = new Option(list.StateName, list.id);
                })
              }
              break;

            case 'secondary_income':
              if(this.secondary_income_list.length) {
                field.options.length = [];
                this.secondary_income_list.map(list => {
                  field.options[field.options.length] = new Option(list.SecondaryIncomeName, list.SecondaryIncomeID);
                })
              }
              break;

              // captured slugs [gender, state, ownership_type, employement_sector, employment_status, type_of_buyer, property_type, enumerators, secondary_income]
              // other select slugs [occupation, employment_type, income_type, income_period, , other_income_period, other_income_type, asset_type, property_of_interest, liability_type, mortgage_constraints]
          
            default:
              break;
          }
        }
      });
      this.fields = form_fields;
    },

    nextStep() {
        if(this.isLastStep) return;
        this.validate();
        if(this.isInvalid()) return;
        this.currentStep++;
    },
    previousStep() {
        if (this.isFirstStep) return;
        this.invalids = {};
        this.currentStep--;
    },
    submitSurvey() {
      // if form not valid don't submit
      this.validate();
      if(this.isInvalid()) return;
      this.$emit('submit-form', this.fields);
    },
    validate() {
        this.invalids = {};
        // validates all the fields on the current page
        this.currentFields.forEach((key) => {
            this.validateField(key);
        });
    },
    validateField(fieldKey) {
        this.invalids[fieldKey] = false;
        const field = this.fields[fieldKey];
        // run through each of the fields validation tests
        if(field.validations) {
          field.validations.forEach((validation) => {
            if(!validation.test(field.value)) {
                this.invalids[fieldKey] = validation.message;
            }
          });
        }
    },
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
  }
}

</script>


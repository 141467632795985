<template>
  <b-form
    ref="login-form"
    @submit.prevent="handleSubmitLoginForm"
  >
    <b-button
      variant="link"
      :to="{ path: '/' }"
    >
      <i class="fa fa-home text-dark" />
    </b-button>
      
    <b-row class="p-3">
      <b-col
        class="animated d-flex justify-content-center align-items-center"
        data-animation="fadeInLeftShorter"
        data-animation-delay="0.5s"
      >
        <router-link
          :to="{ path: '/' }"
        >
          <b-img-lazy
            fluid
            :src="hmip"
            alt="Logo"
          />
        </router-link>
      </b-col>
      <b-col
        class="animated"
        data-animation="fadeInRightShorter"
        data-animation-delay="0.5s"
      >
        <div class="text-center mb-4">
          <h3 class="m-0">
            Welcome to HMIP
          </h3>
          <small>Please enter your login details</small>
        </div>
     
        <b-form-group
          id="input-login-email"
          label="Email address:"
          invalid-feedback="Email Address is required and must be a valid email"
          class="mb-3"
        >
          <b-form-input
            id="login-email"
            v-model="$v.login.Email.$model"
            :state="validateState($v.login.Email)"
            type="email"
            placeholder="Email Address"
            required
          />
        </b-form-group>

        <b-form-group 
          label="Password:"
          id="input-login-password"
          class="mb-3 position-relative"
          invalid-feedback="Password is required and must be at least 8 characters"
        >
          <b-form-input
            id="login-password"
            v-model="$v.login.Password.$model"
            :state="validateState($v.login.Password)"
            :type="showPassword ? 'text': 'password'"
            placeholder="Password"
            required
          />
          <div class="input-prepend" v-show="validateState($v.login.Password)" @click="showPassword = !showPassword">
            <BIconEyeSlash v-if="showPassword"/>
            <BIconEye v-else />
          </div>
        </b-form-group>

        <div class="d-flex justify-content-between align-items-center">
          <div class="mb-3 d-flex align-items-center">
            <b-form-checkbox
              id="remember"
              v-model="remember"
              name="remember"
              value="true"
              unchecked-value="false"
              class="p-0"
            >
            </b-form-checkbox>
            <span class="pl-4">
            Keep me signed in</span>
          </div>
          <div>
            <small>
              <!-- <router-link
                :to="{ name: 'auth.recovery' }"
                class="link "
                data-animation="fadeInDown"
                data-animation-delay="1.8s"
              >
                Forgot Password?
              </router-link> -->
              <b-button
                variant="link"
                class="text-sm"
                @click="handleChangeAuthContainer('initiate-reset')"
              >
                <small>Forgot Password?</small>
              </b-button>
            </small>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-4">
          <button
            type="submit"
            class="btn btn-gradient-purple btn-glow"
            :disabled="submitting"
          >
            <small>Login</small> 
            <b-spinner
              v-if="submitting"
              class="ml-2"
              label="Loading..."
              small
            />
          </button>
          <br>
          <div>
              <small>Need an Account?</small>
              <a
                href="#"
                class="text-sm pl-2"
                :disabled="submitting"
                @click="handleChangeAuthContainer('register')"
              >
                <small>Register</small>
              </a>
            </div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, email, minLength } from "vuelidate/lib/validators"
  import logo from '../../assets/theme-assets/images/loader-logo.png'
  import axios from 'axios';
  import { BIconEye, BIconEyeSlash } from 'bootstrap-vue'

  export default {
    name: 'AuthLoginForm',
    mixins: [validationMixin],
    emits: ['submit-login-form', 'change_auth_view' ],
    components: { BIconEye, BIconEyeSlash },
    
    data: function() {
      return {
        hmip: logo,
        submitting: false,
        remember: false,
        showPassword: false,
        login: {
            Email: '',
            Password: '',
        },
      }
    },

    validations: {
      login: {
        Email: {
          required,
          email
        },
        Password: {
          required,
          minLength: minLength(8)
        },
      },
    },

    methods: {
      handleChangeAuthContainer(view) {
        this.$emit('change_auth_view', view);
      },

      validateState(login) {
        const { $dirty, $error } = login
        return $dirty ? !$error : null
      },

      async handleSubmitLoginForm() {
        this.$v.login.$touch()
        if (this.$v.login.$anyError) return false;

        this.submitting = true;

        try {
          const {data, status} = await axios.post('User/login', this.login)

          if(status && status === 200) {
            // Save the token.
            this.$store.dispatch('auth/saveToken', {
              token: data.details.token.token,
              remember: this.remember,
            });

            // update user data 
            this.$store.dispatch('auth/updateUser', {
              user: data.details
            })

            // redirect
            this.$router.push(this.$route.query.redirect || '/');
            this.submitting = false;
            
          } else {
            this.$swal({
              icon: 'error',
              title: 'Oops! something went wrong',
              text: data.data.message ?? 'An error occured'
            });
          }

        } catch (error) {
          this.submitting = false;
          this.$swal({
            icon: 'error',
            title: 'Oops! something went wrong',
            text: error.message ?? 'Invalid Login Credentials'
          });
        }
        this.submitting = false;
        this.$emit('submit-login-form');
      },
    },
};
</script>

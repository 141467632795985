<template>
  <b-form
    ref="indicator-view-form"
    @submit.prevent="handleUpdateData"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="input-indicators_view-table"
          label="Table:"
          label-for="indicators_view-table"
          invalid-feedback="Table is required"
          class="mb-3"
        >
          <b-form-select
            v-model="$v.indicators_view.table.$model"
            :state="validateState($v.indicators_view.table)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select table --
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(td, t) in tables"
              :key="t"
              :value="td.id"
            >
              {{ td.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-indicators_view-indicator"
          label="Indicator:"
          label-for="indicators_view-indicator"
          invalid-feedback="Indicator is required"
          class="mb-3"
        >
          <b-form-select
            v-model="$v.indicators_view.indicator.$model"
            :state="validateState($v.indicators_view.indicator)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select indicator --
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(indicator, i) in indicators"
              :key="i"
              :value="indicator.id"
            >
              {{ indicator.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-indicators_view-view"
          label="View Name:"
          label-for="indicators_view-view"
          invalid-feedback="View Name is required"
          class="mb-3"
        >
          <b-form-select
            v-model="$v.indicators_view.view.$model"
            :state="validateState($v.indicators_view.view)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select view name --
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(view, v) in views"
              :key="v"
              :value="view.id"
            >
              {{ view.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="input-indicators_view-type"
          label="Type:"
          label-for="indicators_view-type"
          invalid-feedback="Type is required"
          class="mb-3"
        >
          <b-form-select
            v-model="$v.indicators_view.type.$model"
            :state="validateState($v.indicators_view.type)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select type --
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(view_type, t) in types"
              :key="t"
              :value="view_type.id"
            >
              {{ view_type.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-indicators_view-summary"
          label="Summary:"
          label-for="indicators_view-summary"
          invalid-feedback="Summary View is required"
          class="mb-3"
        >
          <b-form-select
            v-model="$v.indicators_view.summary.$model"
            :state="validateState($v.indicators_view.summary)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Select summary name --
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(summary, s) in summaries"
              :key="s"
              :value="summary.id"
            >
              {{ summary.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="input-location-respondents"
          label="No of Respondents:"
          label-for="indicators_view-respondents"
          invalid-feedback="Number of Respondents is required"
          class="mb-3"
        >
          <b-form-input
            id="location-respondents"
            v-model="$v.indicators_view.respondents.$model"
            :state="validateState($v.indicators_view.respondents)"
            type="number"
            placeholder="0"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    
    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          @click="handleUpdateData"
        >
          Save 
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import Form from 'vform';
  import { validationMixin } from "vuelidate"
  import { required, numeric } from "vuelidate/lib/validators"

  export default {
    name: 'AdminDataEntryIndicatorsView',
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
         indicators_view: new Form({
            table: '',
            indicator: '',
            view: '',
            type: '',
            summary: '',
            respondents: '',
        }),
        tables: [
          {
            id: 1,
            name: 'Distribution',
          },
          {
            id: 2,
            name: 'Occupation',
          },
          {
            id: 3,
            name: 'Institution',
          },
        ],
        indicators: [
          {
            id: 1,
            name: 'Household Data',
          },
          {
            id: 2,
            name: 'Public Sector Data',
          },
          {
            id: 3,
            name: 'Financial Data',
          },
          {
            id: 4,
            name: 'Price Index',
          },
          {
            id: 5,
            name: 'NRE-Data',
          },
        ],
        views: [
          {
            id: 1,
            name: 'Household Data',
          },
          {
            id: 2,
            name: 'Public Sector Data',
          },
        ],
        types: [
          {
            id: 1,
            name: 'Table',
          },
          {
            id: 2,
            name: 'View',
          },
        ],
        summaries: [
          {
            id: 1,
            name: 'Bedroom',
          },
          {
            id: 2,
            name: 'Sheet',
          },
        ],
      }
    },

    validations: {
      indicators_view: {
        table: {
          required
        },
        indicator: {
          required
        },
        view: {
          required
        },
        type: {
          required
        },
        summary: {
          required
        },
        respondents: {
          required,
          numeric
        },
      },
    },

    mounted() {
      this.indicators_view.table = this.data.table;
      this.indicators_view.indicator = this.data.indicator;
      this.indicators_view.view = this.data.view;
      this.indicators_view.type = this.data.type;
      this.indicators_view.summary = this.data.summary;
      this.indicators_view.respondents = this.data.respondents;
    },

    methods: {
      validateState(indicators_view) {
        const { $dirty, $error } = indicators_view
        return $dirty ? !$error : null
      },

      handleUpdateData() {
        this.$v.indicators_view.$touch()
        if (this.$v.indicators_view.$anyError) return false;

        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
        this.$emit('handle-form-submit')
      },

      updateSaveNewData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Created Successfully',
        });
      },

      updateExistingData() {
        // call endpoint and save data

        // return alert
        this.$swal({
            icon: 'success',
            title: 'Data Updated Successfully',
        });
      }
    },
};
</script>

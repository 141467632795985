/* eslint-disable vue/no-lone-template */
<template>
  <section class="admin-page">
    <b-breadcrumb :items="items" />
    <Datatable 
      :title="title"
      :title-slug="title_slug"
      :ths="ths"
      :tds="tds"
      @refresh="handleEdit"
    />
  </section>
</template>

<script>
import Datatable from '../../components/Admin/Datatable.vue'
export default {
  name: 'AdminSubscriptions',
  components: { Datatable },

  data: () => ({
    items: [
      {
        text: 'Dashboard',
        to: { name: 'admin.dashboard' }
      },
      {
        text: 'Newsletter Subscriptions',
        active: true
      }
    ],

    title: 'Newsletter Subscriptions',
    title_slug: 'newsletter_subscriptions',
    ths: ['#', 'first name', 'last name', 'email', 'phone number'], // table head
    tds: [ // table data
      {
        'id': 1,
        'first_name': 'Jeremiah',
        'last_name': 'Iro',
        'email': 'jermeiahiro@gmail.com',
        'phone_number': '+23481456789',
      }
    ],
  }),

  methods: {
    handleEdit() {
      // fetch data
    }
  },
}

</script>

<template>
  <section
    id="register"
    data-midnight="white"
    class="register section-padding bg-gradient"
    style="min-height: 100vh"
  >
    <b-row class="mx-auto">
      <b-col
        cols="8"
        class="mx-auto"
      >
        <b-card>
          <register-form />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import RegisterForm from '../../components/Auth/RegisterForm.vue';

export default {
  name: 'Register',
  components: { RegisterForm },

  data: () => ({

  }),

}
</script>


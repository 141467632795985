<template>
  <section
    id="recovery"
    data-midnight="white"
    class="recovery section-padding bg-gradient"
    style="min-height: 100vh"
  >
    <b-row class="mx-auto">
      <b-col
        cols="8"
        class="mx-auto"
      >
        <b-card>
          <reset-password />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ResetPassword from '../../components/Auth/ResetPassword.vue';

export default {
    name: 'Reset',
    components: { ResetPassword },

    data: () => ({
       
    }),

  methods: {
    handleRecovery() {
      //
    }
  }
}
</script>


<template>
  <div>
    <section id="exchange-listing" class="exchange-listing">
      <div class="my-10 md:mx-20 mx-10">
        <div class="text-center mb-5">
          <h2 class="md:text-3xl text-2xl">Mortgage Rates in Nigeria</h2>
        </div>
        <div
          class="flex items-center gap-4 flex-col md:flex-row justify-between"
        >
          <div
            class="p-4 items-center flex justify-between bg-red-700 transition-all duration-300 rounded-md border-2 w-full md:w-1/4 relative"
          >
            <div class="">
              <p class="text-white">NMRC Financing Rate</p>
              <h2 class="text-white font-bold text-3xl">9.5%</h2>
            </div>
            <div class="w-16 h-16 text-red-800 text-[300px]">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxO9N3bkZBjF_zWYKVO59Z3hMqmopoJiuZIg&s"
                class=""
              />
            </div>
          </div>
          <div
            class="p-4 items-center flex justify-between rounded-md bg-blue-700 border-2 bg-red w-full md:w-1/4 relative"
          >
            <div>
              <p class="text-white">NHF Rate</p>
              <h2 class="text-white font-bold text-3xl">6% - 9%</h2>
            </div>
            <div class="w-16 h-16 text-red-800 text-[300px]">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmq-wQ-WOxhGvvQSCboIRrkcp-mG7DPUVwYA&s"
                class=""
              />
            </div>
          </div>
          <div
            class="p-4 items-center flex justify-between rounded-md bg-green-800 border-2 w-full md:w-1/4 relative"
          >
            <div>
              <p class="text-white">Mortgage Rate</p>
              <h2 class="text-white font-bold text-3xl">20% - 27%</h2>
            </div>
            <div class="w-16 h-16">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxO9N3bkZBjF_zWYKVO59Z3hMqmopoJiuZIg&s"
                class=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Exchange Listing Area Starts -->
      <!-- <div class="container-fluid bg-color">
        <div class="container">
          <div class="row listing list-unstyled">
            <div
              class="col d-none d-lg-block text-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.2s"
            >
              <img
                src="theme-assets/images/icon-arrow.png"
                alt="icon-arrow"
              >
              <p class="grey-accent2 mt-1">
                Mortgage Rate in Nigeria
              </p>
            </div>
            <div
              class="col text-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h2>9.5%</h2>
              <span style="font-size:10px">NMRC Re-Financing Rate</span>
            </div>
            <div
              class="col text-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              <h2>20% - 27%</h2>
              <span style="font-size:10px">Mortgage Rate</span>
            </div>
            <div
              class="col text-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.5s"
            >
              <h2>6% - 9%</h2>
              <span style="font-size:10px">NHF Rate</span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Exchange Listing Area Ends -->
    </section>

    <!-- <section
      id="market"
      class="about section-padding"
    >
      <div class="container-fluid">
        <div class="container">
          <div class="heading text-center">
            <div
              class="animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <h6 class="sub-title">
                Capitalization
              </h6>
              <h2 class="title">
                Mortgage Market
              </h2>
            </div>
            <p
              class="content-desc animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.4s"
            >
              View the primary mortgage martket volume and its statistics
            </p>
          </div>
            
          <div class="content-area">
            <div class="row">
              <div
                class="col-md-12 col-lg-7 animated"
                data-animation="fadeInLeftShorter"
                data-animation-delay="0.5s"
              >
                <div
                  class="position-relative what-is-crypto-img float-xl-left"
                  style="width:100%"
                >
                  <div class="col-12">
                    <Area />
                  </div>
                </div>
              </div>     
              <div
                class="col-md-12 col-lg-5 animated"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.2s"
              >
                <div
                  v-for="(data, i) in mortgage.data"
                  :key="i"
                  data-animation-delay="0.3s"
                  data-animation="fadeInUpShorter"
                >
                  <button
                    class="accordion pb-3"
                    data-animation-delay="0.4s"
                    data-animation="fadeInUpShorter"
                    @click="openPanel(data.slug)"
                  >
                    {{ data.title }}
                  </button>
                  <div
                    v-show="isOpen == data.slug"
                    class="panel animated"
                    data-animation-delay="0.1s"
                    data-animation="fadeInUpShorter"
                  >
                    <div
                      v-for="(content, j) in data.content"
                      :key="j"
                      class="py-3 d-flex justify-content-between align-items-center"
                    >
                      <div class="">
                        <span class="mr-2">{{ j+1 }}</span> {{ content.name }}
                      </div>
                      <div
                        class="font-weight-bold text-right"
                      >
                        {{ content.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import Area from "../Chart/Area.vue";
export default {
  name: "Market",
  components: { Area },
  data: function () {
    return {
      isOpen: "",
      mortgage: {
        data: [
          {
            id: 1,
            title: "Market Snapshot",
            slug: "snapshot",
            content: [
              {
                name: "Primary Lenders",
                value: "20",
              },
              {
                name: "Total Volume",
                value: "NGN 21 Billion",
              },
              {
                name: "Primary Lenders",
                value: "748",
              },
            ],
          },
          {
            id: 2,
            title: "Top Lenders by Volume",
            slug: "volumne",
            content: [
              {
                name: "Access Bank plc",
                value: "NGN 6.00 Billion",
              },
              {
                name: "Stanbic IBTC Plc",
                value: "NGN 4.75 Billion",
              },
              {
                name: "Primary Lenders",
                value: "NGN 62.81 Billion",
              },
            ],
          },
          {
            id: 3,
            title: "Top Lenders by Value",
            slug: "value",
            content: [
              {
                name: "Stanbic IBTC Plc",
                value: "206",
              },
              {
                name: "Sterling Bank",
                value: "151",
              },
              {
                name: "Homebase Mortgage Bank",
                value: "114",
              },
            ],
          },
        ],
      },
    };
  },

  mounted() {
    this.isOpen = this.mortgage.data[0].slug;
  },

  methods: {
    openPanel(panel) {
      this.isOpen = panel;
    },
  },
};
</script>
<style scoped>
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #373435;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 18px;
  width: 100%;
  text-align: left;
  border: none;
  border-top: solid 1px #5ca47a;
  outline: none;
  transition: 0.4s;
}

.accordion:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #373435;
  float: right;
  margin-left: 5px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>

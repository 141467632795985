<template>
  <b-modal
    id="deleteDataModal"
    :visible="visible"
    modal-class="social-post-tutorial-modal"
    centered
    hide-header
    hide-footer
    @hide="handleOnHide"
  >
    <div class="text-center">
      <div class="">
        <div class="my-3 text-center">
          <button
            class="btn btn-outline-danger rounded-circle mb-3"
          >
            <i class="fa fa-times fa-4x p-2" />
          </button>
          <h3>
            Are you sure?
          </h3>
          <h5>
            Do you really want to delete this record? <br> This action cannot be reversed
          </h5>
        </div>
        <div class="m-2">
          <div class="text-center">
            <b-button
              variant="outline-warning"
              class="m-2"
              @click="close"
            >
              Cancel
            </b-button>
            <b-button
              variant="danger"
              class="m-2"
              @click="handleDeleteData"
            >
              Delete
              <b-spinner
                v-if="submitting"
                class="ml-2"
                label="Loading..."
                small
              />
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
  import axios from 'axios';
  export default {
    name: 'AdminModalDeleteData',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Delete Data',
        },
        titleSlug: {
            type: String,
            default: 'delete_data',
        },
        data: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: 'Delete',
        },
    },
    emits: ['close-delete-modal'],
    data: function() {
      return {
        submitting: false,
      }
    },
    methods: {
        close() {
            this.$bvModal.hide('deleteDataModal');
        },
        handleOnHide() {
            this.$emit('close-delete-modal', this.type)
        },
        async handleDeleteData() {
          this.submitting = true;

          try {
              let response;
              switch (this.titleSlug) {
                case 'states':
                  response = await axios.delete('NmmipStates/delete', { data: { StateID: this.data.id } });
                  break;

                case 'lga':
                    response = await axios.delete('Lga/delete', { data: { LgaID: this.data.id } });
                  break;  

                case 'locations':
                    response = await axios.delete('NmmipLocations/delete', { data: { LocationID: this.data.id } });
                  break;  

                case 'indicators':
                    response = await axios.delete('NmmipIndicators/delete', { data: { IndicatorID: this.data.id } });
                  break;  

                case 'data_group':
                    response = await axios.delete('NmmipDataGroups/delete', { data: { DataGroupID: this.data.id } });
                  break;  

                case 'ownership_type':
                    response = await axios.delete('OwnershipType/delete', { data: {OwnershipTypeID: this.data.OwnershipTypeID} });
                  break; 

                case 'enumerators':
                    response = await axios.delete('NmmipEnumerators/delete', { data: {EnumeratorID: this.data.id} });
                  break; 

                case 'employment-sector':
                    response = await axios.delete('EmploymentSector/delete', { data: {EmploymentSectorID: this.data.EmploymentSectorID} });
                  break; 

                case 'employment-status':
                    response = await axios.delete('EmploymentStatus/delete', { data: {EmploymentStatusID: this.data.EmploymentStatusID} });
                  break;
                
                case 'buyer-type':
                    response = await axios.delete('TypeOfBuyer/delete', { data: {TypeOfBuyerID: this.data.TypeOfBuyerID} });
                  break;

                case 'property-type':
                    response = await axios.delete('PropertyType/delete', { data: {PropertyTypeID: this.data.PropertyTypeID} });
                  break;
                  
                default:
                  break;
              }
              

              if(response) {
                  this.$swal({
                      icon: response.data.status === 200 ? 'success' : 'error',
                      title: response.data.message ?? 'Data Deleted Successfully',
                  })
              }
          } catch (error) {
            this.$swal({
                icon: 'error',
                title: 'OOPS! Something went wrong.',
                text: error.data.message ?? 'HINT: Refresh and try again'
            })
          }

        this.submitting = false;

        this.close();
        }
    },
  };
</script>

<template>
  <section class="admin-page">
    <b-breadcrumb :items="items" />
    <b-row
      align-v="stretch"
      class="my-3 h-100 mx-auto"
    >
      <b-col
        cols="8"
        class="mx-auto"
      >
        <div class="card shadow mb-4 d-none">
          <a
            href="#collapseProfilePicture"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="collapseProfilePicture"
          >
            <h6 class="m-0 font-weight-bold text-primary">Update Profile Picture</h6>
          </a>
          <div
            id="collapseProfilePicture"
            class="collapse"
          >
            <div class="card-body">
              <update-avatar
                :user="user"
              />
            </div>
          </div>
        </div>

        <div class="card shadow mb-4">
          <a
            href="#collapseProfileInfo"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="collapseProfileInfo"
          >
            <h6 class="m-0 font-weight-bold text-primary">Update Profile Information</h6>
          </a>
          <div
            id="collapseProfileInfo"
            class="collapse show"
          >
            <div class="card-body">
              <update-data
                :user="user"
              />
            </div>
          </div>
        </div>

        <div class="card shadow mb-4">
          <a
            href="#collapsePassword"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            aria-controls="collapsePassword"
          >
            <h6 class="m-0 font-weight-bold text-primary">Update Password</h6>
          </a>
          <div
            id="collapsePassword"
            class="collapse"
          >
            <div class="card-body">
              <change-password
                :user="user"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ChangePassword from '../../components/Admin/ChangePassword.vue'
import UpdateAvatar from '../../components/Admin/UpdateAvatar.vue'
import UpdateData from '../../components/Admin/UpdateData.vue'
import { mapState } from 'vuex';

export default {
  name: 'AdminSettings',
  components: { ChangePassword, UpdateData, UpdateAvatar },

  data: () => ({
    items: [
      {
        text: 'Dashboard',
        to: { name: 'admin.dashboard' }
      },
      {
        text: 'Settings',
        active: true
      }
    ],
  }),

  computed : {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  methods: {

  },
}

</script>

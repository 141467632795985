<template>
  <section
    id="about"
    class="about pt-5 "
  >
    <!-- mortgage calculator -->
    <div class="heading text-center">
      <div
        class="animated"
        data-animation="fadeInUpShorter"
        data-animation-delay="0.3s"
      >
        <h6 class="sub-title">
          Affordability
        </h6>
        <h2 class="title">
          Mortgage Calculator
        </h2>
      </div>
      <p
        class="content-desc animated"
        data-animation="fadeInUpShorter"
        data-animation-delay="0.4s"
      >
        Obtain a monthly payment amortization report and quick affordability check in just a few clicks
      </p>
    </div>
    <div
      id="calculatorBg"
      class="my-20 md:mx-20 mx-10"
    >
      <!-- <div class="text-center mx-auto text-3xl text-black w-11/12 md:w-10/12">
        <p>
          A house is the largest purchase most of us will ever make, so its
          important to calculate what your mortgage payment will be and <br>
          how much you can afford. <br>
          Estimate your monthly payments and see the effect of adding extra
          payments
        </p>
      </div> -->

      <div class="flex flex-col md:flex-row w-full md:w-3/4 mx-auto z-4 mt-12">
        <div class="bg-green-800 w-full md:w-1/2 text-white text-center">
          <div class="pt-20 md:pt-40 px-8 pb-8">
            <div class="pb-16 text-white">
              <div
                v-if="positiveResult"
                class="alert alert-success "
              >
                <span>
                  <i
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  />
                  You are qualified for the specified principal

                </span>
              </div>
              <div
                v-if="negativeResult"
                class="alert alert-danger"
              >
                <span>
                  <i
                    class="fa fa-times-circle"
                    aria-hidden="true"
                  />
                  You are not qualified for the specified principal

                </span>
              </div>
              <p class="text-white text-lg">
                Required monthly repayment is
              </p>
              <h2
                id="requiredIncome"
                class="font-bold text-4xl"
              >
                NGN {{ formatAsCurrency(requiredIncome) }}
              </h2>

              <p class="text-white text-lg">
                Your monthly income is 
              </p>
              <h2
                id="requiredIncome"
                class="font-bold text-4xl"
              >
                NGN {{ formatAsCurrency(actualIncome) }}
              </h2>
              <p class="text-sm text-white">
                Only 33% of monthly income can be contributed: NGN {{ formatAsCurrency(qualifiedAmount) }}
              </p>
            </div>
            <hr class="w-10/12 mx-auto bg-white">
            <div class="pt-4 flex flex-col gap-4 text-center">
              <p class="flex justify-between text-white text-lg">
                Total Principal Amount <span id="mortgageResult">NGN {{ formatAsCurrency(principal) }}</span>
              </p>
              <p class="flex justify-between text-white text-lg">
                Annual Loan Payment <span> NGN {{ formatAsCurrency(annualPayment) }}</span>
              </p>
              <p class="flex justify-between text-white text-lg">
                Interest Rate  <span> {{ form?.interest }}%</span>
              </p>
            </div>
          </div>
        </div>
        <div class="border-2 py-8 px-4 bg-white">
          <div class="pt-4 md:pt-8 px-4">
            <div class="pb-6">
              <h2 class="pb-4 font-bold text-2xl text-bold text-black md:text-3xl">
                Mortgage Calculator
              </h2>
              <p class="text-sm pl-4 text-black">
                Calculate your monthly payment using the free calculator
                <br>
                below
              </p>
            </div>

            <div class="px-1 md:px-2 md:pl-4">
              <form
                class=""
                @submit.prevent="calculateMortgage"
              >
                <div class="mb-6 md:mb-10 flex flex-col">
                  <label class="pb-2 font-bold">Mortgage Amount</label>
                  <input
                    id="amount"
                    v-model="form.principal"
                    placeholder="5000000"
                    type="number"
                    class="p-3  border border-black"
                  >
                </div>
                <div class="mb-6 md:mb-10  flex flex-col">
                  <label class="pb-2 font-bold ">Mortgage Term in Years</label>
                  <input
                    v-model="form.term"
                    type="number"
                    class="p-3 border-black py-1 border "
                  >
                  <!-- <input
                      type="text"
                      class="px-4 border-black py-1 border w-1/3"
                    > -->
                </div>
                <div class="mb-6 md:mb-10  flex flex-col">
                  <label class="pb-2 font-bold ">Monthly Income</label>
                  <input
                    v-model="form.income"
                    type="number"
                    class="border-black p-3 border"
                  >
                </div>
                <div class="mb-6 md:mb-10  flex flex-col">
                  <label class="pb-2 font-bold ">Interest Rate</label>
                  <input
                    v-model="form.interest"
                    type="number"
                    class="border-black p-3 border"
                  >
                </div>
                <button
                  onclick="calculateMortgage()"
                  class="text-center w-full p-3 bg-green-800 text-white"
                >
                  calculate
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container">
        <!-- <div class="heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">
              Affordability
            </h6>
            <h2 class="title">
              Mortgage Calculator
            </h2>
          </div>
          <p
            class="content-desc animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            Obtain a monthly payment amortization report and quick affordability check in just a few clicks
          </p>
        </div> -->
        <!-- <div class="content-area">
          <div class="row">
            <div
              class="col-md-12 col-lg-6 animated"
              data-animation="fadeInLeftShorter"
              data-animation-delay="0.5s"
            >
              <div
                class="position-relative what-is-crypto-img float-xl-left"
                style="width:100%; border:solid 1px #EBEBEB; padding:30px 20px; border-radius: 20px"
              >
                <div class="col-12">
                  <form
                    @submit.prevent="calculateMortgage"
                  >
                    <div>
                      <label
                        for="amount"
                        class="font-weight-bold"
                      >
                        Mortgage Amount
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span
                            id="amount-addon"
                            class="input-group-text"
                          >NGN</span>
                        </div>
                        <input
                          id="amount"
                          v-model="form.principal"
                          type="text"
                          min="1"
                          step="any"
                          class="form-control"
                          placeholder="10,000,000.00"
                          aria-label="amount"
                          aria-describedby="amount-addon"
                          required
                          @keydown="formatPrincipal"
                        >
                      </div>
                    </div>
                    
                    <div>
                      <label
                        for="term"
                        class="font-weight-bold"
                      >
                        Mortgage Term
                      </label>
                      <div class="input-group mb-3">
                        <select
                          id="term"
                          v-model="form.term"
                          class="form-control"
                          @change="changeTerm"
                        >
                          <option
                            v-for="(year, y) in years"
                            :key="y"
                            :value="year"
                            class="my-2"
                          >
                            {{ year }}
                          </option>
                        </select>
                        <div class="input-group-append">
                          <span
                            id="term-addon"
                            class="input-group-text"
                          >Years</span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        for="interest"
                        class="font-weight-bold"
                      >
                        Interest Rate
                      </label>
                      <div class="input-group mb-3">
                        <input
                          id="interest"
                          v-model="form.interest"
                          type="text"
                          min="1"
                          step="any"
                          class="form-control"
                          placeholder="0%"
                          aria-label="interest"
                          aria-describedby="interest-addon"
                          required
                          @keydown="setPercentage"
                        >
                        <div class="input-group-append">
                          <span
                            id="interest-addon"
                            class="input-group-text"
                          >P.A.</span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        for="income"
                        class="font-weight-bold"
                      >
                        Net Monthly Income
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span
                            id="income-addon"
                            class="input-group-text"
                          >NGN</span>
                        </div>
                        <input
                          id="income"
                          v-model="form.income"
                          type="text"
                          min="1"
                          step="any"
                          class="form-control"
                          placeholder="10000.00"
                          aria-label="income"
                          aria-describedby="income-addon"
                          required
                          @keydown="formatIncome"
                        >
                      </div>
                    </div>
                    
                    <button
                      type="submit"
                      class="btn btn-lg btn-gradient-purple btn-glow float-right"
                      style="margin-top:10px"
                    >
                      Calculate
                    </button>
                  </form>
                </div>
              </div>
            </div> 
            <div
              class="col-md-12 col-lg-6 animated"
              data-animation="fadeInRightShorter"
              data-animation-delay="0.5s"
            >
              <h6 class="pt-2 pb-2">
                A house is the largest purchase most of us will ever make so it's important to calculate what your mortgage payment will be and how much you can afford.
              </h6>
              <p>Estimate your monthly payments and see the effect of adding extra payments.</p>
              <div
                class="col-12"
                style="width: 100%; position: relative; background: url('theme-assets/images/footer-image.png') no-repeat center center; -webkit-background-size: cover; background-size: cover; border-radius: 30px; text-align:center; padding:30px 0"
              >
                <p style="color:#FFF; margin:auto">
                  Amortization For Monthly Payment
                </p>
                <p style="color:#FFF; font-weight:900; margin:auto">
                  <span style="font-size:12px">NGN</span> <span style="font-size:18px">{{ test.result ? test.result : "000,000.00" }}</span>
                </p>
                <p style="float:right; padding-right:20px; font-size:11px">
                  <a
                    href="javascript:"
                    style="color:#0CF"
                  >View Detailed Report</a>
                </p>
              </div>
              <div
                
                class="d-flex w-100 py-5 px-2"
              >
                <div
                  v-if="test.result"
                  class="mr-3"
                >
                  <img
                    :src="require(`../../assets/icons/${ test.success ? 'check' : 'error'}.svg`)"
                    alt="check"
                    width="100"
                  >  
                </div>
                <div>
                  <h4 class="text-success">
                    Affordability Test
                  </h4>
                  <div
                    class="text-left"
                  >
                    With your current net monthly income, you
                    can afford to access this mortgage, or an amortized monthly payment of up to
                    <span class="font-weight-bold">NGN {{ test.result ? test.result : "000,000.00" }}</span> monthly
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div
      v-if="!iframe.loaded"
      class="m-5 p-5 text-center"
    >
      <b-spinner
        class="m-5 p-5"
        variant="primary"
        size="12rem"
      />
    </div>

    <iframe
      v-show="iframe.loaded" 
      id="fhf__mortgage__calculator"
      ref="fhf__mortgage__calculator"
      :src="iframe.src"
      scrolling="no"
      title="Housing Data"
      rameborder="0"
      class="px-3"
      allowFullScreen="true"
      style="width:100%; min-height:600px; border:0; overflow: hidden;"
      @load="load"
    /> -->
  </section>
</template>

<script>
import Form from 'vform';
import { debounce } from 'lodash';

export default {
    name: 'Calculator',

  data() {
    return {
      visible: true,
      iframe: {
          src: 'https://nmrc.com.ng/mortgage/mortgage/nmrc-fhf/',
          loaded: false
      },
      form: new Form({
        principal: '',
        term: 0,
        interest: '',
        income: '',
      }),
      years: [ "5", "10", "15", "20", "25", "30"],
      test: {
        success: false,
        result: '',
      },
      requiredIncome:0,
      actualIncome:0,
      principal:0,
      interest:0,
      annualPayment:0,
      qualifiedAmount:0,
      positiveResult: false,
      negativeResult:false
    };
  },

  methods: {
    formatAsCurrency(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    load() {
      this.iframe.loaded = true;
    },
    changeTerm(e){
      this.form.term = e.target.value;
    },
    formatPrincipal: debounce(function() {
      if(this.form.principal.length > 3) {
        const fm = this.form.principal.replace(/[^\d,]/g, '');
        this.form.principal = parseFloat(fm).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
    }, 500),
    formatIncome: debounce(function() {
      if(this.form.income.length > 3) {
        const fm = this.form.income.replace(/[^\d,]/g, '');
        this.form.income = parseFloat(fm).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
    }, 500),
    setPercentage: debounce(function() {
      if(this.form.interest.length > 1) {
        this.form.interest = this.form.interest.replace(/[^\d,]/g, '') + '%';
      }
    }, 500),
    calculateMortgage() {
      var principal = parseFloat(this.form.principal);
      this.principal = principal;
      var period = this.form.term;
      var interest = parseFloat(this.form.interest);
      this.actualIncome = this.form.income;

      var percentageRage = interest / 1200;
      var lengthOfMortgage = 12 * period;
      var monthlyPayment = (principal * percentageRage) / (1 - (Math.pow((1 + percentageRage), lengthOfMortgage * -1)));
     
      var result = monthlyPayment.toFixed(2);
      this.requiredIncome = result;
      this.interest = ((monthlyPayment * period * 12) - this.principal).toFixed(2)
      this.qualifiedAmount = +this.actualIncome * 0.33;
      this.annualPayment = monthlyPayment * 12;
      if(+this.requiredIncome > (+this.qualifiedAmount) ){
        this.negativeResult = true
        this.positiveResult = false;
      }else{
        this.positiveResult = true
        this.negativeResult = false;
      }


      if (result > 0.5) {
        this.test.success = true;
        this.test.result = result;
      } else {
        this.test.success = false;
        this.test.result = result;
      }
    }
  }
 
}
</script>
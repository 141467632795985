<template>
  <section
    id="contact"
    class="contact section-padding"
  >
    <div class="container-fluid">
      <div class="container">
        <div class="heading text-center">
          <div
            class="animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="sub-title">
              GET IN TOUCH
            </h6>
            <h2 class="title">
              Contact
            </h2>
          </div>
          <p
            class="content-desc animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.4s"
          >
            Have questions? We’re happy to help.
          </p>

          <p
            class="font-medium mt-5 animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.5s"
          >
            Contact us with any questions and we will get back to you within the shortest time possible.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-5 col-md-12 mx-auto">
            <ul class="list-unstyled list-group contact-info mb-3">
              <li
                class="pt-1 animated d-flex"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.5s"
              >
                <i class="ti-tag" />
                <span>
                  Nigeria Mortgage Refinance Company.
                </span>
              </li>
              <li
                class="animated d-flex"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.6s"
              >
                <i class="ti-location-pin" />
                <span>
                  17, Sanusi Fafunwa Street, Victoria Island, 
                  <br>
                  Lagos Nigeria
                </span>
              </li>
              <li
                class="animated d-flex"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.7s"
              >
                <i class="ti-email" />
                <span>info@nmrc.com.ng</span>
              </li>
              <li
                class="animated d-flex"
                data-animation="fadeInUpShorter"
                data-animation-delay="0.9s"
              >
                <i class="ti-headphone" />
                <span>+234 908 762 8273</span>
              </li>
            </ul>
          </div>
          <div class="col-lg-7 col-md-12 mx-auto">
            <contact-form />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';

export default {
  name: 'ContactUs',
  components: { ContactForm },

  data: () => ({
    //
  }),

  methods: {
   
  }
}
</script>

<template>
  <section class="survey__form my-5 section-padding col-md-10 mx-auto shadow-lg position-relative">
    
    <div
      v-if="!submitted"
    >
      <watermark :text="title" />
      <div
        class="survey__form-content"
      >
        <div class="section-pro">
          <slot name="logo"></slot>
          <slot name="meta"></slot>

          <div class="m-3  d-none d-lg-block">
            <step-indicator
              :steps-count="totalSteps"
              :current-step="currentStep"
              :submitted="submitted"
              :captions="captions"
            />
          </div>

          <form
            action=""
            class="p-5"
            v-if="fields"
          >
            <div
              v-for="(fieldKeys, step) in steps"
              :key="step"
            >
              <div
                v-if="currentStep === step"
                class="row d-flex flex-wrap justify-content-between align-items-center"
              >
                <template v-for="(field, index) in fieldKeys">
                  <div
                    v-if="other_labels.includes(field)"
                    :key="index" 
                    class="col-md-12 my-3 px-3 w-100 animated"
                    data-animation="fadeInUpShorter"
                    data-animation-delay="0.8s"
                  >
                    <div
                      class="bg-secondary text-white p-2 text-uppercase"
                    >
                      {{ formatField(field) }}
                    </div>
                  </div>
                  <form-fields
                    v-else
                    :key="`form_fields_${index}`" 
                    class="my-1 col-md-6 w-100 px-lg-3"
                    :field="fields[field]"
                    :is-invalid="invalids[field]"
                    :invalid-message="invalids[field]"
                    :check-mate="validateField[field]"
                    :type="type"
                    :current-step="currentStep"
                    @validate-input="validate"
                  />
                </template>
              </div>
            </div>
            <div class="col-md-3 ml-auto text-right">
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  v-if="!isFirstStep"
                  type="button"
                  class="btn btn-gradient-purple btn-glow"
                  @click="previousStep"
                >
                  Previous
                </button>
                <button
                  v-if="!isLastStep"
                  type="button"
                  class="btn btn-gradient-purple btn-glow"
                  @click.prevent="nextStep"
                >
                  Next
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-gradient-purple btn-glow"
                  :disabled="submitting"
                  @click.prevent="submitSurvey"
                >
                  <b-spinner
                    v-if="submitting"
                    small
                    label="Spinning"
                  />
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-5 col-md-6 mx-auto "
    >
      <success-message
        :message="successMessage"
        :action="successAction"
      />
      <!-- @close_survey_modal="handleCloseSurveyModal" -->
    </div>
  </section>
</template>

<script>
import FormFields from './partials/FormFields.vue';
import StepIndicator from './partials/StepIndicator.vue';
import form_fields from './partials/form_fields';
import Watermark from './partials/Watermark.vue';
import SuccessMessage from './partials/SuccessMessage.vue';
import stateData from './partials/state_data.js';
import { forEach } from 'lodash';

export default {
  name: 'SurveyForm',
  components: {
      FormFields,
      StepIndicator,
      Watermark,
      SuccessMessage
  },
  props: {
      title: {
        type: String,
        default: 'Survey Form',
      },
      successMessage: {
        type: String,
        default: '',
      },
      successAction: {
        type: Object,
        default: () => {},
      },
      submitted: {
        type: Boolean,
        default: false,
      },
      submitting: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'own_a_home',
      },
  },

  emits: ['submit-form'],
  data: function() {
    return {
      currentStep: 0,
      invalids: {},
      fields: '',
      captions: [
        {
          title: 'personal information',
        },
        {
          title: 'current address',
        },
        {
          title: 'employement information',
          sub_title: 'employers address'
        },
        {
          title: 'additional information',
        },
        {
          title: 'subject property',
          description: 'property of interest',
        },
      ],
      other_labels: ['other_income', 'asset', 'liabilities'], // used for sub-headings
      steps: [
        [
            'Salutation',
            'FirstName',
            'MiddleName',
            'LastName',
            'Email',
            'PhoneNumber',
            'DateOfBirth',
            'SexID',
            'BVN',
            'PencomPin',
            'Purchase',
            'Deposit',
            'TermMonths',
        ],
        [
            'ProvinceOrState',
            'LgaID',
            'Location',
            'StreetName',
            'StreetNumber',
            'Estate',
            'Unit',
            'HouseNumber',
        ],
        [
            'NameOfEmployer',
            'EmployeeID',
            'EmployeeEmail',
            'Designation',
            'EmploymentType',
            'EmploymentStatusID',
            'EmploymentDate',
            'IncomeAmount',
            'IncomeType',
            'IncomePeriod',
            'WhatIsYourPrimaryOccupation',
            'OfficePlot',
            'EmployerStreetName',
            'EmployerStreetNumber',
            'EmployerState',
            'EmployerLga',
        ],
        [
            'other_income', // sub-headings
            'OtherIncomeDescription',
            'OtherIncomeAmount',
            'OtherIncomePeriod',
            'OtherIncomeType',

            'asset', // sub-headings
            'AssetDescription',
            'AssetValue',
            'AssetType',

            'liabilities', // sub-headings
            'LiabilityAccountNumber',
            'LiabilityBalance',
            'LiabilityDescription',
            'LiabilityType',
            'LiabilityMonthlyPayment',
        ],
        [
            'PropertyLocationOrArea',
            'PropertyEstate',
            'PropertyStreetNumber',
            'PropertyStreetName',
            'PropertyPlot',
            'PropertyType',
            'PropertyProvinceOrState',
            'PropertyLGAID',
            'PropertyTenure',
            'PropertyAge',
            'PropertySpace',
            'PropertyLotSize',
            'PropertyNoOfRooms',
            'PropertyPlan',
            'PropertyAnnualTax',
            'PropertyEstateServiceCharges',
            'PropertyInsurance',
            'PropertyValuation',
            'PropertyValuationAsIs',
            'PropertyValuationComplete',
            'PropertyValuationDate',
            'PropertyValuationForSaleAsIs',
            'PropertyValuationForSaleComplete',
        ]
      ],
      state_list: [],
    }
  },

  computed: {
      totalSteps() {
          return this.steps.length -1;
      },
      currentFields() {
          return this.steps[this.currentStep];
      },
      isFirstStep() {
          return this.currentStep === 0;
      },
      isLastStep() {
          return this.currentStep === this.totalSteps;
      },
  },

  async mounted() {
    await this.updateOptions();
  },

  methods: {
    formatField(field) {
      var title = field.toLowerCase();
      return title.replace('_', ' ') ;
    },
    async updateOptions() {
      forEach(form_fields, field => {
        if(field.slug) {
          switch (field.slug) {
            case 'state':
              if(stateData) {
                field.options.length = [];
                forEach(stateData, (key, state) => {
                  field.options[field.options.length] = new Option(state, state);
                })
              }
              break;

            case 'employer_state':
              if(stateData) {
                field.options.length = [];
                forEach(stateData, (key, state) => {
                  field.options[field.options.length] = new Option(state, state);
                })
              }
              break;
              
            case 'property_state':
              if(stateData) {
                field.options.length = [];
                forEach(stateData, (key, state) => {
                  field.options[field.options.length] = new Option(state, state);
                })
              }
              break;
          }
        }
      });
      this.fields = form_fields;
    },
    
    isInvalid() {
      return Object.values(this.invalids).filter((key) => key).length;
    },
    
    nextStep() {
        if (this.isLastStep) return;
        this.validate();
        if(this.isInvalid()) return;
        this.currentStep++;
    },

    previousStep() {
        if (this.isFirstStep) return;
        this.invalids = {};
        this.currentStep--;
    },

    submitSurvey() {
      // if form not valid don't submit
      this.validate(); 
      if(this.isInvalid()) return;
      this.$emit('submit-form', this.fields);
    },

    validate() {
        this.invalids = {};
        // validates all the fields on the current page
        this.currentFields.forEach((key) => {
            this.validateField(key);
        });
    },

    validateField(fieldKey) {
        this.invalids[fieldKey] = false;
        const field = this.fields[fieldKey];
        if(field.validations) {
          field.validations.forEach((validation) => {
            if(!validation.test(field.value)) {
                this.invalids[fieldKey] = validation.message;
            }
          });
        }
    },

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
  }
}

</script>
<style scoped>
.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

</style>


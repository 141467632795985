<template>
  <b-form
    ref="update-data-form"
    @submit.prevent="handleUpdateData"
  >
    <b-form-group
      label="First Name:"
      label-for="first_name"
      invalid-feedback="First Name is required"
      class="mb-3"
    >
      <b-form-input
        id="first_name"
        v-model="$v.user_info.Firstname.$model"
        :state="validateState($v.user_info.Firstname)"
        type="text"
        placeholder="First Name"
        required
      />
    </b-form-group>

    <b-form-group
      label="Last Name:"
      label-for="last_name"
      invalid-feedback="Last Name is required"
      class="mb-3"
    >
      <b-form-input
        id="last_name"
        v-model="$v.user_info.Lastname.$model"
        :state="validateState($v.user_info.Lastname)"
        type="text"
        placeholder="Last Name"
        required
      />
    </b-form-group>

    <b-form-group
      label="Email:"
      label-for="email"
      invalid-feedback="Email is required"
      class="mb-3"
    >
      <b-form-input
        id="email"
        v-model="user_info.Email"
        type="email"
        placeholder="Email"
        required
        readonly
      />
    </b-form-group>

    <b-form-group
      label="Phone Number:"
      label-for="phone"
      invalid-feedback="Phone Number is required"
      class="mb-3"
    >
      <b-form-input
        id="phone"
        v-model="$v.user_info.PhoneNumber.$model"
        :state="validateState($v.user_info.PhoneNumber)"
        type="tel"
        placeholder="+234 812345678"
        required
      />
    </b-form-group>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          :disabled="submitting"
          @click="handleUpdateData"
        >
          Save
          <b-spinner small v-if="submitting" />
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import { validationMixin } from "vuelidate"
  import { required, numeric } from "vuelidate/lib/validators"
  import axios from 'axios';

  export default {
    name: 'AdminUpdateData',
    mixins: [validationMixin],

    props: {
      user: {
          type: Object,
          default: () => {},
          required: true
      },
    },

    data: function() {
      return {
        submitting: false,
        user_info: {
          UserID: '',
          Firstname: '',
          Lastname: '',
          Email: '',
          PhoneNumber: '',
        },
      }
    },

    mounted() {
      if(this.user) {
        const { UserID, Firstname, Lastname, Email, PhoneNumber } = this.user;
        this.user_info.UserID = UserID;
        this.user_info.Firstname = Firstname;
        this.user_info.Lastname = Lastname;
        this.user_info.Email = Email;
        this.user_info.PhoneNumber = PhoneNumber;
      }
    },

    validations: {
      user_info: {
        Firstname: {
          required
        },
        Lastname: {
          required
        },
        PhoneNumber: {
          required,
          numeric
        },
      },
    },

    methods: {
      validateState(info) {
        const { $dirty, $error } = info
        return $dirty ? !$error : null
      },

      async handleUpdateData() {
        this.$v.user_info.$touch()
        if (this.$v.user_info.$anyError) return false;

        this.submitting = true;

        await axios.post('User/updateUser', this.user_info)
              .then(response => {
                  this.$store.dispatch('auth/updateUser', {
                    user: response.data.details
                  })
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
      }
      
      // handleUpdateData() {
      //   this.$v.user_info.$touch()
      //   if (this.$v.user_info.$anyError) return false;
        
      //   // call endpoint and save data

      //   // return alert
      //   this.$swal({
      //       icon: 'success',
      //       title: 'Operation Successfully',
      //   });
      // },
    },
};
</script>

<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="m-0 font-weight-bold text-success">
          {{ title }}
        </h4>
        <button
          v-show="canCreate(titleSlug)"
          class="btn btn-outline-success"
          @click="toggleDataModal({}, 'create')"
        >
          <i class="fa fa-plus" />
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          id="datatable"
          class="table table-bordered"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th
                v-for="(th, h) in ths"
                :key="h"
                scope="col"
                class="text-capitalize"
              >
                {{ th }}
              </th>
              <th
                scope="col"
                class="text-center"
                v-show="canCreate(titleSlug)"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(td, d) in tds"
              :key="d"
              class="align-items-center"
            >
              <td
                class=""
                style="width: 60px !important;"
              >
                {{ d+1 }}
              </td>

              <!-- States -->
              <template v-if="titleSlug == 'states'">
                <td class="">
                  {{ td.StateName }}
                </td>
              </template>

              <!-- Locations -->
              <template v-else-if="titleSlug == 'locations'">
                <td class="text-capitalize">
                  {{ td.Latitude }}
                </td>
                <td class="text-capitalize">
                  {{ td.Longitude }}
                </td>
                <td class="text-capitalize">
                  {{ td.LocationName }}
                </td>
                <td class="text-capitalize">
                  <!-- select options -->
                  {{ td.NmmipState.StateName }}
                </td>
                <td class="text-capitalize">
                  <!-- select options -->
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <!-- LGA -->
              <template v-else-if="titleSlug == 'lga'">
                <td class="text-capitalize">
                  {{ td.LgaName }}
                </td>
                <td class="text-capitalize">
                  {{ td.NmmipStates.StateName }}
                </td>
              </template>

              <!-- Indicators -->
              <template v-else-if="titleSlug == 'indicators'">
                <td class="text-capitalize">
                  {{ td.IndicatorName }}
                </td>
                <td class="text-capitalize">
                  <!-- switch -->
                  <b-form-checkbox
                    v-model="td.IsEnabled"
                    switch
                    value="1"
                    unchecked-value="0"
                    disabled
                  >
                    {{ td.IsEnabled == 1 ? 'Enabled' : 'Disabled' }}
                  </b-form-checkbox>
                </td>
                <td class="text-capitalize">
                  {{ td.PageVisibility }} 
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <!-- Data Group -->
              <template v-else-if="titleSlug == 'data_group'">
                <td class="text-capitalize">
                  {{ td.DataGroupName }}
                </td>
                <td class="text-capitalize">
                  {{ td.DataGroupParentID }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

               <!-- Home Ownership Type -->
               <template v-else-if="titleSlug == 'ownership_type'">
                <td class="text-capitalize">
                  {{ td.OwnershipTypeName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <!-- Home Ownership Type -->
              <template v-else-if="titleSlug == 'enumerators'">
                <td class="text-capitalize">
                  {{ td.EnumeratorName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <!-- Employment Sector -->
              <template v-else-if="titleSlug == 'employment-sector'">
                <td class="text-capitalize">
                  {{ td.EmploymentSectorName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <!-- Employment Status -->
              <template v-else-if="titleSlug == 'employment-status'">
                <td class="text-capitalize">
                  {{ td.EmploymentStatusName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>
              
              <!-- Indicator View -->
              <template v-else-if="titleSlug == 'indicators-view'">
                <td class="text-capitalize">
                  {{ td.TableOrViewName }}
                </td>
                <td class="text-capitalize">
                  {{ td.SummaryTableOrViewName }}
                </td>
                <td class="text-capitalize">
                  {{ td.TotalNumberOfRespondents }}
                </td>
              </template>

                <!-- Buyer Type -->
                <template v-else-if="titleSlug == 'buyer-type'">
                <td class="text-capitalize">
                  {{ td.TypeOfBuyerName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

                <!-- Property Type -->
                <template v-else-if="titleSlug == 'property-type'">
                <td class="text-capitalize">
                  {{ td.PropertyTypeName }}
                </td>
                <td class="text-capitalize">
                  {{ getDate(td.created_at ?? td.updated_at) }}
                </td>
              </template>

              <template v-else-if="titleSlug == 'newsletter_subscriptions'">
                <td class="text-capitalize">
                  {{ td.first_name }}
                </td>
                <td class="text-capitalize">
                  {{ td.last_name }}
                </td>
                <td class="text-capitalize">
                  {{ td.email }}
                </td>
                <td class="text-capitalize">
                  {{ td.phone_number }}
                </td>
              </template>

              <template v-else-if="titleSlug == 'roles'">
                <td class="text-capitalize">
                  {{ td.name }}
                </td>
                <td class="text-capitalize">
                  {{ td.type.name }}
                </td>
                <td class="text-capitalize">
                  {{ td.description }}
                </td>
                <td class="text-capitalize">
                  <ul class="">
                    <li
                      v-for="(permission, p) in td.permissions"
                      :key="p"
                    >
                      {{ permission.name }}
                    </li>
                  </ul>
                </td>
              </template>

              <template v-else-if="titleSlug == 'permissions'">
                <td class="text-capitalize">
                  {{ td.name }}
                </td>
                <td class="text-capitalize">
                  {{ td.group.name }}
                </td>
                <td class="text-capitalize">
                  {{ td.description }}
                </td>
              </template>

              <template v-else-if="titleSlug == 'permissions_group'">
                <td class="text-capitalize">
                  {{ td.name }}
                </td>
              </template>

              <td
                class=""
                style="width: 100px !important;"
                v-show="canCreate(titleSlug)"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    class="btn btn-outline-dark border-0 m-1"
                    @click="toggleDataModal(td, 'edit')"
                  >
                    <i class="fas fa-pen" />
                  </button>
                  <button
                    class="btn btn-outline-dark border-0 m-1"
                    @click="toggleDataModal(td, 'delete')"
                  >
                    <i class="fa fa-trash" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <delete-data 
          :visible="showDataModal_delete"
          :title="title"
          :title-slug="titleSlug"
          :type="type"
          :data="activeData"
          @close-delete-modal="handleHideModal"
        />
        <update-data 
          :visible="showDataModal_update"
          :title="title"
          :title-slug="titleSlug"
          :type="type"
          :data="activeData"
          @close-update-modal="handleHideModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '../../assets/admin/js/datatables.js'
import DeleteData from './Modal/DeleteData.vue'
import UpdateData from './Modal/UpdateData.vue'

export default {
  name: 'AdminDatatable',
  components: {
    DeleteData,
    UpdateData,
  },

  props: {
    title: {
        type: String,
        default: 'Data',
    },
    titleSlug: {
        type: String,
        required: true,
    },
    ths: {
        type: Array,
        default: () => [],
    },
    tds: {
       type: Array,
        default: () => [],
    },
  },

  emits: ['refresh'],

  data: () => ({
    activeData: {},
    type: '',
    slug: '',
    showDataModal_update: false,
    showDataModal_delete: false,
  }),

  methods: {
    toggleDataModal(td, type) {
      this.handleToggleModal(type);
      this.type = type;
      this.activeData = td;
    },

    handleHideModal(type) {
      this.handleToggleModal(type);
      this.$emit('refresh')
    },

    handleToggleModal(type) {
      switch (type) {
        case 'edit':
          this.showDataModal_update = !this.showDataModal_update;
          break;

        case 'delete':
          this.showDataModal_delete = !this.showDataModal_delete;
          break;
      
        default:
          this.showDataModal_update = !this.showDataModal_update;
          break;
      }
    },

    canCreate(title) {
      // var hideButton = ['permissions_group', 'permissions', 'roles', 'newsletter_subscriptions', 'indicators_view', 'states', 'lga'];
      var hideButton = [];
      return !hideButton.includes(title);
    },

    getDate(data) {
      const date = new Date(data ?? '2021-11-10 19:38:26');
      return date.toLocaleDateString();
    }

  },
}
</script>
<template>
  <section
    id="token-distribution"
    class="token-distribution team section-padding bg-gradient"
    data-midnight="white"
  >
    <!-- housing pulse -->
    <div
      id="housingPulse"
      class="my-10 py-2 md:px-0 px-10 text-white text-lg md:pl-20"
    >
      <div class="flex flex-col md:flex-row">
        <div class="py-12 md:w-2/3 w-full ">
          <div class="w-full md:w-5/6 ">
            <div class="relative  py-4">
              <!-- <h5 class="text-5xl text-gray-100 indices">INDICES</h5> -->
              <h4 class="top-0 mt-6 text-2xl">
                HOUSING PULSE
              </h4>
            </div>
            <p class="w-full md:w-8/12 pb-6 text-white">
              Access core housing data from multiple sources, including the
              renowned NMRC Research team.
            </p>
            <div class="pb-4">
              <h4 class="text-2xl pb-4">
                Datasets
              </h4>
              <p class="pb-4 text-white">
                Explore key economic indicators, construction spending and
                real estate development indexes, settlement area data and many
                more indices and datasets.
              </p>
              <p class="pb-4 text-white">
                With the HMIP being your housing full-service broker, you can make more
                informed decisions as you improve your business forecasts and
                identify opportunities within the real estate and property
                development/investment space.
              </p>
              <p class="text-white">
                Power your ideas using data you can trust. Leverage our
                datasets below to gain insights, spur creativity, innovate,
                and grow your business in the Nigerian mortgage markets.
              </p>
            </div>
          </div>

          <div class="mt-4 flex flex-col md:flex-row  justify-between">
            <div 
              router-link
              :to="{ name: 'dashboard.pricing' }"
              class="flex gap-3 w-full md:w-1/3 items-center"
            >
              <img
                src="theme-assets/images/stats.png"
                alt=""
              >
              <div>
                <h2 class="pb-1 font-medium">
                  Housing Statistics
                </h2>
                <p class="font-base text-white">
                  HMIP Mortgage <br>Statistics Dashboard
                </p>
              </div>
            </div>
            <div class="flex gap-3  w-full md:w-1/3 items-center">
              <img
                src="theme-assets/images/pricing.png"
                alt=""
              >
              <div
                router-link
                :to="{ name: 'dashboard.pricing' }"
              >
                <h2 class="pb-1 font-medium">
                  Housing Pricing
                </h2>
                <p class="font-base text-white">
                  HMIP Housing Price <br>Index
                </p>
              </div>
            </div>
            <div class="flex gap-3  w-full md:w-1/3 items-center">
              <img
                src="theme-assets/images/NRE.png"
                alt=""
              >
              <div>
                <h2 class="pb-1 font-medium">
                  NRE-DCMP
                </h2>
                <p class="font-base text-white">
                  Nigeria Mortgage <br>Refinance
                </p>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <router-link
              :to="{ name: 'dashboard' }"
              class="rounded-2xl bg-green-600 text-white px-8 py-2"
            >
              View All
            </router-link>
            <!-- <button class="rounded-2xl bg-green-600 px-8 py-2">
              View All
            </button> -->
          </div>
        </div>
        <div class="w-1/3 md:block hidden">
          <img
            src="theme-assets/images/globe.png"
            alt=""
            class=""
          >
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid">
      <div class="container">
        <div class="dark-bg-heading text-center">
          <div>
            <h6 class="sub-title">
              Indices
            </h6>
            <h2 class="title">
              Housing Pulse
            </h2>
          </div>
                
          <p class="content-desc">
            Access core housing data from multiple sources, including the <br class="d-none d-xl-block">renowed NMRC Research team.
          </p>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-8 pr-5">
            <div class="content-area text-light">
              <h4>
                Datasets
              </h4>
              <p class="mt-1 text-light">
                Explore key economic indicators, construction spending and real estate development indexes, settlement area data and many more indices and datasets.
              </p>
              <p class="mt-1 text-light">
                With the HMIP being your housing full-service broker, you can make more informed decisions as you improve your business forecasts and identify opportunities within the real estate and property development/investment space.
              </p>
              <p class="mt-1 text-light">
                Power your ideas using data you can trust. Leverage our datasets below to gain insights, spur creativity, innovate, and grow your business in the Nigerian mortgage markets.
              </p>
            </div>
          </div>
          <div
            class="col-md-12 col-lg-4 move-first animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.8s"
          >
            <div class="token-img">
              <img
                class="img-fluid"
                src="theme-assets/images/pie-chart.png"
                alt="datasets"
              >
            </div>
          </div>
        </div>

        <div class="team-profile mt-5">
          <div class="row mb-5">
            <div
              v-for="(housing, index) in housings"
              :key="index"
              class="col-sm-12 col-md-6 col-lg-4 mb-5"
            >
              <router-link
                :to="{ name: housing.url }"
                class="no-underline"
              >
                <div class="d-flex">
                  <div class="team-img float-right mr-3">
                    <img
                      :src="require(`../../assets/theme-assets/images/${housing.icon}.png`)"
                      :alt="housing.name"
                      class="rounded-circle"
                      width="130"
                      height="130"
                    >
                  </div>
                  <div class="profile align-self-center">
                    <div class="name">
                      {{ housing.name }}
                    </div>
                    <div class="role text-light">
                      {{ housing.description }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
                
          <router-link
            :to="{ name: 'dashboard' }"
            class="btn btn-sm btn-gradient-purple btn-glow float-left"
          >
            View All
          </router-link>
        </div>
      </div>
    </div> -->
  </section>
<!--/ Team -->
</template>

<script>
export default {
    name: 'Housing',
    data: function() {
      return {
        housings: [
          {
            id: 1,
            name: "Housing Statistics",
            description: "HMIP Mortgage Statistics Dashboard",
            url: "dashboard.pricing",
            icon: "housing"
          },
          {
            id: 2,
            name: "Housing Price Index",
            description: "HMIP Housing Price Index",
            url: "dashboard.pricing",
            icon: "analytics"
          },
          {
            id: 3,
            name: "NRE-DCMP",
            description: "Nigeria Mortgage Refinance",
            url: "dashboard.nre",
            icon: "nmrc"
          },
        ]
      }
    }
}
</script>
<template>
  <b-form
    ref="indicators-form"
    @submit.prevent="handleUpdateData"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="input-indicators-name"
          label="Name:"
          label-for="indicators-name"
          invalid-feedback="Indicator Name is required"
          class="mb-3"
        >
          <b-form-input
            id="indicators-name"
            v-model="$v.indicator.IndicatorName.$model"
            :state="validateState($v.indicator.IndicatorName)"
            type="text"
            placeholder="Name"
            required
          />
        </b-form-group>

        <b-form-group
          id="input-indicator-group"
          label="Group Data:"
          label-for="indicator-group"
          class="mb-3"
        >
          <multiselect
            v-model="$v.indicator.NmmipDataGroup.$model"
            :options="NmmipDataGroups"
            :clear-on-select="false"
            placeholder="Select Type"
            label="DataGroupName"
            track-by="DataGroupName"
          />
          <b-form-invalid-feedback :state="validateState($v.indicator.NmmipDataGroup)">
            Indicator Group Data is required
          </b-form-invalid-feedback>
        </b-form-group>
        
        <b-form-group
          label="Status:"
          label-for="indicator-status"
          class="mb-3 text-capitalize"
        >
          <b-form-checkbox
            id="indicator-status"
            v-model="indicator.IsEnabled"
            name="check-button"
            value="1"
            unchecked-value="0"
            switch
          >
            {{ indicator.IsEnabled == 1 ? 'Enabled' : 'Disabled' }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          id="input-indicators-parent"
          label="Indicator Parent:"
          label-for="indicators-parent"
          invalid-feedback="Indicator Parent is required"
          class="mb-3"
        >
          <b-form-input
            id="indicators-parent"
            v-model="$v.indicator.ParentIndicatorID.$model"
            :state="validateState($v.indicator.ParentIndicatorID)"
            type="text"
            placeholder="Indicator Parent ID"
            required
          />
        </b-form-group>
        
        <b-form-group
          id="input-indicator-visibility"
          label="Page Visibility:"
          label-for="indicator-visibility"
          class="mb-3"
        >

        <multiselect
            v-model="$v.indicator.PageVisibility.$model"
            :options="PageVisibilities"
            :clear-on-select="false"
            placeholder="Indicator Page Visibility"
          />
          <b-form-invalid-feedback :state="validateState($v.indicator.PageVisibility)">
            Indicator Page Visibility is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="m-2">
      <div class="text-right">
        <b-button
          variant="success"
          :disabled="submitting"
          @click="handleUpdateData"
        >
          Save
        </b-button>
      </div>
    </div>
  </b-form>
</template>
<script>
  import axios from 'axios';
  import { validationMixin } from "vuelidate"
  import { required } from "vuelidate/lib/validators"
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'AdminDataEntryIndicators',
    components: { Multiselect  },
    mixins: [validationMixin],
    props: {
      data: {
          type: Object,
          default: () => {},
      },
      type: {
          type: String,
          default: 'edit',
      },
    },
    emits: ['handle-form-submit'],

    data: function() {
      return {
        submitting: false,
         indicator: {
            IndicatorName: '',
            ParentIndicatorID: '5',
            NmmipDataGroup: '',
            PageVisibility: '',
            IsEnabled: 0,
        },
        PageVisibilities: ['Home', 'Reports', 'Both'],
        NmmipDataGroups: [],
      }
    },

    validations: {
      indicator: {
        IndicatorName: {
          required
        },
        ParentIndicatorID: {
          required
        },
        NmmipDataGroup: {
          required
        },
        PageVisibility: {
          required
        },
      },
    },

    mounted() {
      const { IndicatorName, NmmipDataGroup, PageVisibility, IsEnabled } = this.data;
      this.indicator.IndicatorName = IndicatorName;
      this.indicator.NmmipDataGroup = NmmipDataGroup;
      this.indicator.PageVisibility = PageVisibility;
      this.indicator.IsEnabled = IsEnabled;
      this.fetchGroupData();
    },
  
    methods: {
      validateState(indicator) {
        const { $dirty, $error } = indicator
        return $dirty ? !$error : null
      },

      async fetchGroupData() {
        await axios.get('NmmipDataGroups/fetch')
          .then(
            response => (this.NmmipDataGroups = response.data.details)
          )
          .catch(
            error => (
              this.error = true
            )
          );
      },

      handleUpdateData() {
        this.$v.indicator.$touch()
        if (this.$v.indicator.$anyError) return false;
        
        switch (this.type) {
          case 'edit':
            this.updateExistingData();
            break;
        
          default:
            this.updateSaveNewData();
            break;
        }
      },

      async updateSaveNewData() {
        this.submitting = true;

        const createData = {
          IndicatorName: this.indicator.IndicatorName,
          ParentIndicatorID: this.indicator.ParentIndicatorID,
          DataGroupID: this.indicator.NmmipDataGroup.id,
          PageVisibility: this.indicator.PageVisibility,
          IsEnabled: this.indicator.IsEnabled,
        };

        await axios.post('NmmipIndicators/create', createData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Created Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      },

      async updateExistingData() {
        this.submitting = true;

        const updateData = {
          IndicatorName: this.indicator.IndicatorName,
          ParentIndicatorID: this.indicator.ParentIndicatorID,
          DataGroupID: this.indicator.NmmipDataGroup.id,
          PageVisibility: this.indicator.PageVisibility,
          IndicatorID: this.data.id,
          IsEnabled: this.indicator.IsEnabled,
        };

        await axios.post('NmmipIndicators/update', updateData)
              .then(response => {
                if(response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    title: 'Data Updated Successfully',
                  })
                }
              })
              .catch(error => (
                this.$swal({
                    icon: 'error',
                    title: 'OOPS! Something went wrong.',
                    text: 'HINT: Refresh and try again'
                })
            ));

        this.submitting = false;
        this.$emit('handle-form-submit')
      }
    },
};
</script>
